import React from 'react';

const Sk = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Podmienky vrátenia peňazí:</h1>
                <ul>
                    <li>1. Vrátenie peňazí je poskytované do 14 dní.</li>
                    <li>2. Vrátenie peňazí sa vzťahuje len na prvýkrát objednané služby. Napríklad: Ak si objednáte
                        predplatné, potom dostanete vrátenie peňazí a objednáte si u nás predplatné druhýkrát, nebudete môcť
                        získať peniaze za druhú objednávku späť.
                    </li>
                    <li>3. Vrátenie prostriedkov sa uskutočňuje s odpočítaním poplatkov platobných systémov.</li>
                    <li>4. Pri platbách kartou VISA a MasterCard sa peniaze vrátia na tú istú kartu, z ktorej bola uskutočnená
                        platba. Po vrátení platby sa peniaze objavia na karte do 3 bankových dní. Všimnite si, že táto platba
                        sa nezobrazuje v výpisoch z karty, ale jednoducho sa zvyšuje suma na karte. Tento typ vrátenia je
                        možný len do 30 dní od platby.
                    </li>
                    <li>5. Peniaze sa vrátia na tie isté údaje, z ktorých bola uskutočnená platba za služby.</li>
                    <li>6. Vrátenie platby sa uskutočňuje do 10 dní od podania žiadosti.</li>
                    <li>7. Pri vrátení sa odpočítavajú všetky bonusy a zľavy.</li>
                </ul>
            </div>
        </div>

    );
};

export default Sk;