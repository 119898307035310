import axios from "axios";
import {LS_CLIENT_TOKEN, LS_LANG, LS_USER_TOKEN} from "../consts/localStorageNaming";
import cookie from "js-cookie";
import i18n from "i18next";
import {DEFAULT_LANG} from "../languages/initLanguage";
import {rejectTransform} from "./utils";

export let instances_help_article = axios.create({
    baseURL: `${process.env.REACT_APP_API_HELP_ARTICLE_URL}/api/v1`,
    // baseURL: 'https://test-help.ifurn.pro/api/v1',
    withCredentials: true,
    headers: {
        'Language': localStorage.getItem(LS_LANG) || cookie.get('i18next') || i18n.language || DEFAULT_LANG,

    }
});

export const HelpArticleAPI={
    getById: (id)=>{
        let lang_val =  localStorage.getItem(LS_LANG) || cookie.get('i18next') || i18n.language || DEFAULT_LANG;
        let lang = lang_val === 'ua' ? 'uk' : lang_val;
        let url = `/article/${id}/${lang}`
        return instances_help_article.get(url).then(res=> {
        return {
            id: id,
            title: `(№${id}) ${res.data.title}`,
            description: res.data.description
        }}).catch(e=> rejectTransform(e))
    }
}