import React from 'react';

const Lv = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Naudu atmaksas nosacījumi:</h1>
                <ul>
                    <li>1. Naudas atmaksas piedāvātas 14 dienu laikā.</li>
                    <li>2. Naudas atmaksu veic tikai par pirmo pasūtīto pakalpojumu. Piemēram, ja jūs pasūtāt abonementa
                        pakalpojumu, pēc tam saņemat naudas atmaksu un vēlreiz pasūtat abonementu no mums, jūs nevarēsiet
                        saņemt naudu atpakaļ par otro pasūtījumu.
                    </li>
                    <li>3. Apmaksas sistēmu komisijas summa tiek atskaitīta no naudas atmaksas.</li>
                    <li>4. Maksājot ar VISA un MasterCard karti, nauda tiek atgriezta uz to pašu karti, ar kuru bija
                        veikts maksājums. Pēc tam, kad mēs atgriezīsim maksājumu, nauda parādās kartē 3 banku dienu laikā.
                        Lūdzu, ņemiet vērā, ka šis maksājums nerādīsies kartes izrakstos, tas vienkārši palielinās kartes
                        summu. Šāda veida atmaksas veids ir pieejams tikai 30 dienu laikā pēc apmaksas.
                    </li>
                    <li>5. Nauda tiek atgriezta uz tādām pašām rekvizītiem, no kurām tika veikta pakalpojumu apmaksa.</li>
                    <li>6. Maksājuma atmaksai ir nepieciešamas 10 dienas no pieteikuma iesniegšanas brīža.</li>
                    <li>7. Atgriežot naudu, tiek atskaitīti visi bonusi un atlaidēs.</li>
                </ul>
            </div>
        </div>

    );
};

export default Lv;