import React from 'react';
import Close from "../../picture/icon/Close";

const Modal = ({title=null, children, isModalSm=false, onClose}) => {
   const cName = `${isModalSm ? ' modal-content-sm' : ''} modal-content `
    return (
        <div className=" modal-container">
            <div className="container">
                <div className="row">
                    <div className={cName}>
                        <div className="modal-head">
                            <div className="h4">
                                {title}
                            </div>
                            <div className="close" onClick={onClose}>
                                <Close isBlack={false}/>
                            </div>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;