import React from 'react';

const Pl = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Polityka bezpieczeństwa transferu danych kart płatniczych</h1>
                <p>Płacąc za zamówienie kartą bankową, przetwarzanie płatności (w tym wprowadzenie numeru karty) odbywa
                    się na bezpiecznej stronie systemu przetwarzania. Oznacza to, że Twoje poufne dane (dane karty, dane
                    rejestracyjne itp.) nie trafiają do nas, ich przetwarzanie jest w pełni chronione, a nikt nie może
                    uzyskać danych osobowych i bankowych klienta.</p><p>Podczas pracy z danymi kart wykorzystywany jest
                    standard bezpieczeństwa informacji opracowany przez międzynarodowe systemy płatnicze Visa i
                    MasterCard - Payment Card Industry Data Security Standard (PCI DSS), który zapewnia bezpieczne
                    przetwarzanie danych Karty Bankowej Posiadacza. Zastosowana technologia przesyłania danych
                    gwarantuje bezpieczeństwo transakcji kartami bankowymi dzięki wykorzystaniu protokołów Secure
                    Sockets Layer (SSL).</p></div>
        </div>
    );
};

export default Pl;