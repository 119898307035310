import React from 'react';
import {Field} from 'formik'

const InputFiled = (props) => {
    let {label=null, ...reset} = props
    return (
        <div className={'field form-group'}>
            {!!label && <label>{label}</label>}
            <input {...reset}/>
        </div>
    );
};

export default InputFiled;