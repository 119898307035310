import React from 'react';

const Et = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list">
                <h4>Andmete kustutamise juhend:</h4>
                <p>Kui soovite oma andmed eemaldada lehelt <a href="https://ifurn.pro/" className="" target={'_blank'}> iFurn.pro</a>,
                    andke palun teada oma kontaktandmed kustutamiseks ja põhjus.</p>
            </div>
        </div>
    );
};

export default Et;