import React from 'react';

const Et = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list">
                <h4>Hinnakiri teenuste kohta:</h4>
                <p>Hind kujuneb kliendi vajaduste järgi.</p>
                <p>Arvestatakse töökohtade arvu, tellimuste arvu kuus ja kasutatava seadmete hulka.</p>
                <p>Teenus on üles ehitatud universaalse konstruktorina, millele on lihtne kohandada
                    Teie vajadused.
                </p>
                <p>Võtke meiega ühendust teile sobival viisil ja koostame kommertspakkumise.</p>
            </div>
        </div>
    );
};

export default Et;