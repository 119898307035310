import React from 'react';

const En = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Payment card details transfer security policy</h1><p>When
                paying for an order with a bank card, the payment processing (including entering the card number) takes
                place on a secure page of the processing system. This means that your confidential data (card details,
                registration data, etc.) do not come to us, their processing is completely protected, and no one can get
                the personal and banking data of the client.</p><p>When working with card data, the information security
                standard developed by the international payment systems Visa and MasterCard - Payment Card Industry Data
                Security Standard (PCI DSS) is used, which ensures the secure processing of the details of the Holder's
                Bank Card. The applied data transfer technology guarantees the security of transactions with Bank cards
                through the use of Secure Sockets Layer (SSL) protocols.</p></div>
        </div>
    );
};

export default En;