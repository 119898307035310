import React from 'react';

const Ro = () => {
    return (
        <div className="info-pages-main-content"><h1>Condiții de rambursare:</h1>
            <ul>
                <li>1. Rambursările sunt oferite în termen de 14 zile</li>
                <li>2. Rambursările se fac doar pentru primele servicii comandate. De exemplu: dacă ați comandat un
                    serviciu de abonament, după care ați primit o rambursare și ați comandat un abonament de la noi a
                    doua oară, atunci nu veți putea primi o rambursare pentru a doua comandă.
                </li>
                <li>3. Rambursările se fac minus comisionul sistemelor de plată.</li>
                <li>4. La plata prin VISA și MasterCard, banii sunt returnați pe același card cu care a fost efectuată
                    plata. După ce returnăm plata, banii apar pe card în termen de 3 zile bancare. Vă rugăm să rețineți
                    că această plată nu apare pe extrasele cardului, ci pur și simplu crește suma de pe card. Acest tip
                    de retur este disponibil doar în 30 de zile de la plată.
                </li>
                <li>5. Banii sunt returnați la aceleași detalii din care s-a făcut plata pentru servicii.</li>
                <li>6. Rambursările se fac în termen de 10 zile de la data depunerii cererii.</li>
                <li>7. La întoarcere, toate bonusurile și reducerile sunt deduse.</li>
            </ul>
        </div>
    );
};

export default Ro;