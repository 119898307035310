import React, {useState} from 'react';
import InputFiled from "../../ui/InputFiled";
import Button from "../../ui/Button";
import {useTranslation} from "react-i18next";
import IconArrow from "../../../assets/Icons/IconArrow";

const styleTextarea ={
    border: `1px solid #cdccd7`,
}

const Comment = ({handlerSubmit}) => {
    const {t} = useTranslation()
    const [comment, setComment] = useState('');
    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handlerSubmit(comment, ()=>setComment(''))
    }
    const [isOpen, setIsOpen] = useState(false)
    const handlerChange = (e) => setComment(e.target.value)
    return (
        <form onSubmit={onSubmit} className={'mt-2'}>
            <label onClick={()=>setIsOpen(!isOpen)} className={"comment-accordion-toggle"}> <label>{t('title_comment')} <IconArrow className={`${isOpen ? 'icon-arrow-open ' : ''}`}/></label></label>
            {isOpen && <> <div className="user-data" style={{marginTop:"6px"}}>
                <div className={'field form-group'}>
                    <textarea onChange={handlerChange} className={''} style={styleTextarea}>{comment}</textarea>
                </div>
            </div>
                <div className="d-flex justify-center ">
            <Button type={'submit'}>{t('feedback_form.form.submit')}</Button>
                </div>
            </>}
        </form>
    );
};

export default Comment;