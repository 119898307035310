import React from 'react';

const Et = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content">
                <h2 className="privacy-title">Avaliku pakkumise leping</h2>
                <p className="privacy-text">Üldised tingimused</p>
                <ul>
                    <li>1.1. Käesolev avalik pakkumine on ametlik pakkumine "ФОП Коток И.М.", edaspidi "Müüja", sõlmida kaugmüügi leping, st Interneti-kaubanduse kaudu, edaspidi "Leping", ja avaldab avaliku pakkumise (pakkumise) Müüja ametlikul veebisaidil <a href="https://ifurn.pro" target="_blank" title="ifurn.pro" className="privacy-link">"https://ifurn.pro", </a><a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro" className="privacy-link">"https://newgo.ifurn.pro", </a><a href="https://crm.ifurn.pro" target="_blank" title="crm.ifurn.pro" className="privacy-link">"https://crm.ifurn.pro"</a> (edaspidi "Veebisait").
                    </li>
                    <li>1.2. Müüja pakkumise (aktsepti) täielikuks ja tingimusteta aktsepteerimise hetkeks, et sõlmida elektrooniline kauplemisleping, loetakse aset leidnud siis, kui Ostja maksab Müüja veebisaidil märgitud tingimuste ja hindadega arve õigeaegselt.
                    </li>
                    <li>2.1. Käesolevas pakkumises, kui kontekst ei nõua teisiti, on allpool toodud terminitel järgmised tähendused:
                    </li>
                    <ul>
                        <li>* "kaup" - mööbli osad, pooltooted, tarvikud, komponendid ja saatetooted;</li>
                        <li>* "Interneti-kauplus" - vastavalt Ukraina seadusele "elektroonilise kaubanduse kohta" vahend kauba, töö või teenuse esitamiseks või realiseerimiseks elektroonilise tehingu sõlmimise kaudu.
                        </li>
                        <li>* "Müüja" - ettevõte, mis müüb Interneti-saidil esitatud kaupu.
                        </li>
                        <li>* "Ostja" - füüsiline isik, kes sõlmib Müüjaga allpool kirjeldatud tingimustel lepingu.
                        </li>
                        <li>* "Tellimus" - Ostja poolt tellimuse esitamisel ja makse tegemisel määratletud kaupade eraldi positsioonide valik.
                        </li>
                    </ul>
                    <p className="privacy-text">Lepingu ese</p>
                    <li>3.1. Müüja kohustub loovutama Ostjale vara ja Ostja kohustub tasuma ja vastu võtma vara käesoleva lepingu tingimustel.
                    </li>
                    <ul>
                        Käesolev Leping reguleerib kaupade ostmise-müümise tehinguid Interneti-kaupluses, sealhulgas:
                        <li> - Ostja vabatahtlik valik kaupu Interneti-kaupluses;</li>
                        <li> - Ostja iseseisev tellimuse esitamine Interneti-kaupluses;</li>
                        <li> - Ostja tellimuse tasumine Interneti-kaupluses esitatud tingimustel;</li>
                        <li> - tellimuse töötlemine ja selle Ostjale kohaletoimetamine käesoleva Lepingu tingimustel.
                        </li>
                    </ul>
                    <p className="privacy-text">Tellimuse esitamise kord</p>
                    <li>4.1. Ostjal on õigus esitada tellimus igale Interneti-kaupluses esitatud ja laos olevale tootele.
                    </li>
                    <li>4.2. Iga positsioon võib tellimusse lisada mis tahes koguses.
                    </li>
                    <li>4.3. Kui kaup puudub laos, on ettevõtte juht kohustatud teavitama Ostjat (telefoni või e-posti teel).
                    </li>
                    <li>4.4. Kauba puudumisel on Ostjal õigus asendada see samaväärse mudeliga kaubaga, loobuda sellest kaubast või tellimus tühistada.
                    </li>
                    <p className="privacy-text">Maksekorraldus</p>
                    <p className="privacy-text">Pangamakse.</p>
                    <li>5.1. Tasumine toimub Müüja arveldusarvele. Müüja tarnib kaupa Ostjale pärast arve tasumist viisil, mis on kokku lepitud kohaletoimetamise tingimustes punktides 6.1, 6.2.
                    </li>
                    <li>5.2. Rahaliste vahendite mitte laekumisel jätab Interneti-kauplus endale õiguse tellimus tühistada.
                    </li>
                    <p className="privacy-text">Tellimuse kohaletoimetamise tingimused</p>
                    <li>6.1. Interneti-kaupluses ostetud kaupade kohaletoimetamine toimub Ostja poolt valitud viisil: isiklikult sisseehitatud aadressilt, transpordiettevõttega või muul viisil, mis on Ostja ja Müüja vahel kokku lepitud.
                    </li>
                    <li>6.2. Tellimusega antakse Ostjale dokumendid vastavalt Ukraina seadusandlusele.
                    </li>
                    <p className="privacy-text">Poolte õigused ja kohustused:</p>
                    <li>7.1. Müüjal on õigus:</li>
                    <ul>
                        <li>- ühepoolselt peatada käesoleva lepingu tingimuste rikkumise korral Ostja poolt osutatavate teenuste osutamine.
                        </li>
                    </ul>
                    <li>7.2. Ostja on kohustatud:</li>
                    <ul>
                        <li>- õigeaegselt tasuma ja vastu võtma tellimuse käesoleva lepingu tingimustel.
                        </li>
                    </ul>
                    <li>7.3. Ostjal on õigus:</li>
                    <ul>
                        <li>- esitada tellimus Interneti-kaupluses;</li>
                        <li>- sõlmida elektrooniline leping;</li>
                        <li>- nõuda Müüjalt käesoleva Lepingu tingimuste täitmist.
                        </li>
                    </ul>
                    <p className="privacy-text">Poolte vastutus</p>
                    <li>8.1. Pooled vastutavad käesoleva lepingu tingimuste mittetäitmise või ebakohase täitmise eest käesolevas lepingus ja Ukraina kehtivas õiguses ettenähtud korras.
                    </li>
                    <li>8.2. Müüja ei vastuta:</li>
                    <ul>
                        <li>- Toote välimuse muutmise eest tootja poolt;</li>
                        <li>- Toodete värvi väikeste erinevuste eest, mis võivad olla originaalist tingitud ja tuleneda erinevast monitori värviedastusest isiklike arvutite konkreetsete mudelite korral;</li>
                        <li>- Tellimuse vormistamisel Ostja poolt esitatud teabe sisu ja tõesuse eest;</li>
                        <li>- Teenuste (tellimuse töötlemine ja toote kohaletoimetamine) viivituste ja katkestuste eest, mis tulenevad põhjustest, mis ei ole tema kontrolli all;</li>
                        <li>- Õigusvastaste tegevuste eest, mida Ostja teostab selle juurdepääsuga Interneti võrgule;</li>
                        <li>- Ostja võrguidentifikaatorite - IP, MAC-aadressi, kasutajanime ja parooli - edastamise eest kolmandatele isikutele;</li>
                    </ul>
                    <li>8.3. Ostja vastutab iseseisvalt Interneti võrgu kaudu antud juurdepääsu kasutamise eest kahju eest, mida tema tegevus (isegi kui tema logimise all on teine isik) põhjustab isikutele või nende varale, juriidilistele isikutele, riigile või moraalsetele põhimõtetele.
                    </li>
                    <li>8.4. Vääramatu jõu asjaolude tekkimisel vabanevad pooled käesoleva lepingu tingimuste täitmisest. Vääramatu jõu asjaoludeks käesoleva lepingu mõttes loetakse sündmusi, mis on äärmiselt ettenägematud, millel on väljapaistev iseloom ja mis välistavad või takistavad käesoleva lepingu täitmist, mille saabumist Pooled ei suutnud ette näha ega mõistlikult ära hoida.
                    </li>
                    <li>8.5. Pooled teevad maksimaalseid jõupingutusi igasuguste erimeelsuste lahendamiseks ainult läbirääkimiste teel.
                    </li>
                    <p className="privacy-text">Muud tingimused</p>
                    <li>9.1. Interneti-kauplus jätab endale õiguse ühepoolseks muutmiseks käesolevasse lepingusse eelneva avaldamise tingimusel veebisaidil <a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro" className="privacy-link">"https://newgo.ifurn.pro"</a>.</li>
                    <li>9.2. Interneti-kauplus on loodud kaupade kaugmüügi korraldamiseks Interneti kaudu.</li>
                    <li>9.3. Ostja vastutab tellimuse vormistamisel esitatud teabe täpsuse eest. Samal ajal, tellimuse aktsepteerimisel (tellimuse vormistamisel ja järgneval toote eest tasumisel) annab Ostja Müüjale oma tingimusteta nõusoleku koguda, töödelda, säilitada ja kasutada oma isikuandmeid seaduse "Isikuandmete kaitse seadus" mõistes.</li>
                    <li>9.4. Ostja tasumine Interneti-kaupluses vormistatud tellimuse eest tähendab Ostja täielikku nõusolekut ostu-müügi lepingu (avaliku pakkumise) tingimustega.</li>
                    <li>9.5. Elektroonilise kokkuleppe kuupäevaks poolte vahel on kuupäev, mil tingimused on aktsepteeritud vastavalt Ukraina seaduse "Elektroonilise kaubanduse seadus" § 11.</li>
                    <li>9.6. Interneti-kaupluse ressursi kasutamine toote eelvaateks ja Ostja tellimuse vormistamiseks on tasuta.</li>
                    <li>9.7. Ostja poolt esitatud teave on konfidentsiaalne. Interneti-kauplus kasutab Ostja teavet ainult tellimuse töötlemise, Ostjale teatiste saatmise, kauba kohaletoimetamise, vastastikuste arvelduste jne eesmärgil.</li>
                    <p className="privacy-text">Tagastatava kauba korra nõuded</p>
                    <li>10.1. Kauba tagastamine Interneti-kaupluses toimub vastavalt Ukraina kehtivatele õigusaktidele.
                    </li>
                    <p className="privacy-text">Lepingu kehtivusaeg</p>
                    <li>11.1. Elektroonilist lepingut loetakse sõlmituks selle isiku poolt, kes esitas selle lepingu sõlmimiseks pakkumise vastuvõtmise kohta vastuse vastavalt Ukraina seaduse "Elektroonilise kaubanduse seadus" artikli 11 lõike 6 sätetele.
                    </li>
                    <li>11.2. Käesolev leping võib ennetähtaegselt lõpetada vastastikusel kokkuleppel kuni kauba tegeliku kohaletoimetamiseni, raha tagastamise teel.
                    </li>
                    <p className="privacy-text">Märkus: Interneti-kaupluse „СПД Коток И.М.“ ametlikul veebisaidil <a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro" className="privacy-link">"https://newgo.ifurn.pro"</a> on õigus vastavalt Ukraina seadustele anda õigus kasutada Interneti platvormi FIE-dele ja juriidilistele isikutele kauba müümiseks.
                    </p>
                </ul>
            </div>
        </div>
    );
};

export default Et;