import React from 'react';
import Form from "./Form";

const Ua = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list">
                <h4>Інструкція для видалення даних:</h4>
                <p>
                    Якщо Ви бажаєте видалити свої дані з

                    <a href="https://ifurn.pro/" className="" target={'_blank'}> iFurn.pro</a>,
                    повідомте будь ласка свої контактні дані для видалення та причину.
                </p>
            </div>
            <Form/>

        </div>
    );
};

export default Ua;