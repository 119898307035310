import React from 'react';

const Ru = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list"><h4>Прайс-лист на услуги:</h4><p>Цена формируется под
                потребности Клиента.</p>
                <p>
                Учитывается количество рабочих мест, количество заказов в месяц, количество
                используемого оборудования.</p><p>
                    Сервис построен как универсальный конструктор, легко адаптируемый под
                Ваши нужды.
            </p><p>Свяжитесь с нами удобным Вам способом и мы сформируем коммерческое предложение.</p>
            </div>
        </div>
    );
};

export default Ru;