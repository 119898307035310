import React from 'react';
import IconQuestion from "../../../picture/icon/IconQuestion";

const NavTab = ({current, onSwitchTab, handlerHelpArticle, entry=[], classNameWrap='', t}) => {
    const onHandlerTab = (key)=> event=>{
        onSwitchTab(key)
    }
    return (
        <div className={'tab-section d-flex align-items-center ' + classNameWrap}>
            {entry.map((item) => {
                return <div className={`tab ${current === item.key ? 'active' : ''}`}
                            onClick={onHandlerTab(item.key)}>
                    {t(item.lable)}
                </div>
            })}
            <div className=""><IconQuestion onClick={handlerHelpArticle}/></div>
        </div>
    );
};

export default NavTab;