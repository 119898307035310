import React from 'react';
import {useTranslation} from "react-i18next";

import {useDispatch} from "react-redux";
import {sendFeedbackForm} from "../../../redux/app-reducer";
import {useFormik} from "formik";
import IconArrow from "../../../assets/Icons/IconArrow";
import FieldPhoneMask from "../../ui/FieldPhoneMask";

const RecordForm = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const onSubmitForm = (data) => {
        dispatch(sendFeedbackForm(data))
    }
    const formik = useFormik({
        initialValues: {
            activities: '',
            nameCompany: '',
            phone: '',
            email: '',
        },
        onSubmit: (values, {resetForm}) => {
            onSubmitForm({
                text: `Вид деятельности - ${values.activities},  Название предприятия - ${values.nameCompany} Номер телефона - ${values.phone} E-mail - ${values.email}`
            });
            resetForm();
        },
    });
    const onChangeTelHandler = (result) => {

        formik.setFieldValue('phone', result);
    }
    return (
        <form onSubmit={formik.handleSubmit} className={'form-sm'}>
            <div className="field  form-group">
                {/*<label>{t('feedback_form.form.select.0.name')}</label>*/}
                <div className="styled-select">

                    <select className={'filed-border'}
                            name="activities" id=""
                            onChange={formik.handleChange}
                            value={formik.values.activities}
                            required={true}
                    >
                        <option selected={true} disabled={true}
                                value="">{t('feedback_form.form.select.0.name')}</option>
                        <option
                            value={t('feedback_form.form.select.1.name')}>{t('feedback_form.form.select.1.name')}</option>
                        <option
                            value={t('feedback_form.form.select.2.name')}>{t('feedback_form.form.select.2.name')}</option>
                        <option
                            value={t('feedback_form.form.select.3.name')}>{t('feedback_form.form.select.3.name')}</option>

                    </select>
                    <IconArrow/>
                </div>

            </div>
            <div className="field form-group">
                <label>{t('feedback_form.form.firm_name')}</label>
                <input
                    className={'filed-border'}
                    id="nameCompany"
                    name="nameCompany"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.nameCompany}
                    required={'required'}
                />
            </div>

            <FieldPhoneMask onChange={onChangeTelHandler} label={t('feedback_form.form.phone')}/>
            <div className="d-flex justify-center form-group">
                <button type="submit">{t('feedback_form.form.submit')}</button>
            </div>
        </form>
    );
};

export default RecordForm;