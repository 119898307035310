import React, {useMemo} from 'react';
import TelBot from "../../../../TelBot";
import {useFormikContextProviderRegister} from "../Registration";
import Button from "../../../../ui/Button";
import {useDispatch, useSelector} from "react-redux";
import {settingsSelected} from "../../../../../redux/auth/auht-selector";
import IconViber from "../../../../../assets/Icons/IconViber";
import QRCode from "react-qr-code";
import IconTelegram from "../../../../../assets/Icons/IconTelegram";
import IconWA from "../../../../../assets/Icons/IconWA";
import BotUrl from "../../../../../assets/images/bot.jpeg";
import IconInfo from "../../../../../assets/Icons/IconInfo";
import {filterObjectByKeys, unFilterObjectByKeys} from "../../../../../utils/common";
import {authThunkAccountAccess} from "../../../../../redux/auth/auth-thunk";
import {useTranslation} from "react-i18next";

const ICONS_MESSAGE = {
    telegram: IconTelegram,
    viber: IconViber,
    whatsapp: IconWA,
}
const styleIcon = {
    marginRight: '8px'
}
const styleQr = {height: "auto", maxWidth: "55px", width: "55px"}
const StepRegisterBot = ({
                             handleNext,
                             available_message_channel_list,
                             full_message_channel_list,
                         }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {values, handleChange, setFieldValue} = useFormikContextProviderRegister();
    if (!available_message_channel_list && !full_message_channel_list) {
        handleNext();
        return null
    }
    const onReqAccountAccess = () => {
        let login = values.email || values.phone;
        dispatch(authThunkAccountAccess(
            {login: login, send_password: 0}, null, null));

    }
    const connected_channel = useMemo(() => {
        if (Object.keys(full_message_channel_list).length && Object.keys(available_message_channel_list)) {
            let keys = Object.keys(available_message_channel_list);
            return filterObjectByKeys(full_message_channel_list, keys)
        }
        return {}
    }, [available_message_channel_list, full_message_channel_list])
    const un_connected_channel = useMemo(() => {
        if (Object.keys(full_message_channel_list).length && Object.keys(available_message_channel_list)) {
            let keys = Object.keys(available_message_channel_list);
            return unFilterObjectByKeys(full_message_channel_list, keys)
        } else if (Object.keys(full_message_channel_list).length) {
            return full_message_channel_list
        }
        return {}
    }, [available_message_channel_list, full_message_channel_list]);
    return (
        <div className={'step-register-bot'}>
            <Button type={'button'} style={{margin: '0 auto 35px auto'}}
                    onClick={() => {
                        handleNext(null)
                    }}>{t("btn_next_title")}</Button>
            <div className="content form-group card-form">
                {!!Object.keys(un_connected_channel).length && <div className={'un-connected-channel-wrap form-group'}>
                    <label htmlFor="" className={'d-flex align-items-center bot-info'}>
                        <span>{t("connect_chat_bot_optionally_title")}:</span>
                        <a href={BotUrl} target={'_blank'} className="info d-flex align-items-center"><IconInfo
                            width={'25px'} height={'25px'}/></a>
                        <img src={BotUrl} alt=""/>
                    </label>
                    <div className={'un-connected-channel-entry d-flex message-channel-entry'}>
                        {Object.keys(un_connected_channel).map(key => {
                            const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                            const name = un_connected_channel[key].name;
                            const link = un_connected_channel[key].link;
                            return (
                                <div className={`item d-flex flex-column`}>
                                    <div className="d-flex align-items-center">
                                        {Icon ? <Icon style={styleIcon}/> : name ? name : ''}
                                        <a href={link} className="d-flex" target={'_blank'}>
                                            <QRCode
                                                size={55}
                                                style={styleQr}
                                                value={link}
                                                viewBox={`0 0 55 55 `}
                                            />
                                        </a>
                                    </div>
                                </div>)

                        })}
                    </div>
                    <spna className="resend-action cursor-pointer"
                          onClick={() => onReqAccountAccess({send_password: 0})}> {t("check_registration_title")}
                    </spna>
                </div>}
                {!!Object.keys(connected_channel).length && <div className={'connected-channel-wrap form-group'}>
                    <label htmlFor="">{t("choice_message_default_title")}:</label>
                    <div className={`connected-channel d-flex message-channel-entry`}>
                        {Object.keys(connected_channel).map(key => {
                            const isActive = key === values.message_channel;
                            const isDefault = connected_channel[key].default;
                            const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                            const name = connected_channel[key].name;
                            return (
                                <div className={`item   ${isActive ? 'active' : ' '}`}
                                     onClick={() => setFieldValue('message_channel', key)}
                                >
                                    {Icon ? <Icon/> : name ? name : ''}
                                </div>)

                        })}
                    </div>
                </div>}
            </div>


        </div>
    );
};

export default StepRegisterBot;