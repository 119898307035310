import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "../../ui/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getSignUpProviderServiceModalSelected} from "../../../redux/selector/app-selector";
import {signUpProviderServicesModal} from "../../../redux/app/app-actions";
import RecordForm from "./RecordForm";

const SignUpProviderServiceModal = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const modal_state = useSelector(getSignUpProviderServiceModalSelected);

    const onClose = useCallback( ()=>{
        dispatch(signUpProviderServicesModal({isOpen: false}))
    }, [dispatch])
    if(!modal_state.isOpen) return  null
    return (
            <Modal title={t('login_page.registration')} onClose={onClose} isModalSm={true}>
                <RecordForm/>
            </Modal>
            
    );
};

export default SignUpProviderServiceModal;