import React from 'react';

const IconClose = (props) => {
    const {className='', ...reset} = props;
    const cName = `icon-close cursor-pointer ${className}`
    return (
        <svg className={cName} width="28" height="28" viewBox="0 0 28 28" fill="none"
             xmlns="http://www.w3.org/2000/svg"  {...reset}>
            <path d="M21 7L7 21M7 7l14 14" stroke="#0F0F0F" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
        </svg>
    );
};

export default IconClose;