import React from "react";
import {useTranslation} from "react-i18next";
import order from '../../../picture/image/order_1.png'
import order_2 from '../../../picture/image/order_2.png'
import stl from "./main.module.css"

const WorkLess = ()=>{
    const {t} = useTranslation()
    return(
        <div className="container" id={"workLess"}>
            <div className="row">
                <div className={stl.section_calculate_instantly}>
                    <h2 className='text-center'>{t('work_less.title')}</h2>
                    <div className={stl.content}>
                        <h3>{t('work_less.sub_title')}</h3>
                        <p>{t('work_less.description.paragraph_one')}</p>
                        <p>{t('work_less.description.paragraph_two')}</p>
                        <p>{t('work_less.description.paragraph_three')}</p>
                        <img src={order} alt=""/>
                        <img src={order_2} alt=""/>

                    </div>
                </div>
            </div>
        </div>

    )
}
export default WorkLess;