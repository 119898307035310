import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import {authReducer} from "./auth/auth-reducer";
import {appReducer} from "./app-reducer";
import {helpArticleReducer} from "./helpl-aricle/help-article-reducer";

let rootReducer = combineReducers({
    appPage: appReducer,
    auth: authReducer,
    help_article: helpArticleReducer
})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

window._store = store;
export default store