import {
    AUTH_ACCOUNT_MESSAGE_CHANNEL, AUTH_ACCOUNT_MESSAGE_CHANNEL_CONFIRM, AUTH_ACCOUNT_NETWORK_SOCIAL,
    AUTH_CLIENTS_FIRMS,
    AUTH_INITIAL_VERIFICATION_USER,
    AUTH_LOGIN_USER,
    AUTH_LOOKUP, AUTH_NETWORK_SOCIAL_LOGIN,
    AUTH_REGISTER_USER
} from "./auth-action"

const SLICE_STEP = {
    isLoading: false,
    isError: false,
    error: null,
}
const FILED_COMM0M_EMAIL_PHONE = {
    isRadioTypeAuthEmail: true,
    email: '',
    password: ''
}

const initialState = {
    verification: {
        ...SLICE_STEP,
        isLoading: true,
        user: null,
        settings: null
    },

    login: {
        ...SLICE_STEP,
        ...FILED_COMM0M_EMAIL_PHONE,
    },
    auth_lookup: {
        ...SLICE_STEP,
        data: null
    },
    client_firms: {
        ...SLICE_STEP,
        entry_firm: null
    },
    registration: {
        ...SLICE_STEP,
        ...FILED_COMM0M_EMAIL_PHONE,
        isSuccessful: false
    },
    message_channel: {
        ...SLICE_STEP,
        data: null
    },
    confirmAccountChannelsModal: {
        isOpen: false,
        form_data: null
    },
    network_login: {
        ...SLICE_STEP,
        list: null
    },
    auth_network_social_login: {
        ...SLICE_STEP,
        form_data: null,
        response_data: null,
        modal: {
            isOpen: false,
            isShortPassword: false,
            isSendEmail: false
        }
    }

}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_INITIAL_VERIFICATION_USER: {
            return {
                ...state,
                verification: {
                    ...state.verification,
                    ...action.payload
                }
            }
        }
        case AUTH_LOGIN_USER: {
            return {
                ...state,
                login: {
                    ...state.login,
                    ...action.payload
                }
            }
        }
        case AUTH_CLIENTS_FIRMS: {
            return {
                ...state,
                client_firms: {
                    ...state.client_firms,
                    ...action.payload
                }
            }
        }
        case AUTH_LOOKUP: {
            return {
                ...state,
                auth_lookup: {
                    ...state.auth_lookup,
                    ...action.payload
                }
            }
        }
        case AUTH_REGISTER_USER: {
            return {
                ...state,
                registration: {
                    ...state.registration,
                    ...action.payload
                }
            }
        }
        case AUTH_ACCOUNT_MESSAGE_CHANNEL: {
            return {
                ...state,
                message_channel: {
                    ...state.message_channel,
                    ...action.payload
                }
            }
        }
        case AUTH_ACCOUNT_MESSAGE_CHANNEL_CONFIRM: {
            return {
                ...state,
                confirmAccountChannelsModal: {
                    ...state.confirmAccountChannelsModal,
                    ...action.payload
                }
            }
        }
        case AUTH_ACCOUNT_NETWORK_SOCIAL: {
            return {
                ...state,
                network_login: {
                    ...state.network_login,
                    ...action.payload
                }
            }
        }
        case AUTH_NETWORK_SOCIAL_LOGIN: {
            return {
                ...state,
                auth_network_social_login: {
                    ...state.auth_network_social_login,
                    ...action.payload
                }
            }
        }


        default: {
            return {
                ...state
            }
        }
    }

}

