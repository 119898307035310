import React from 'react';

const Pl = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list"><h4>Cennik usług:</h4><p>Cena jest kształtowana zgodnie
                z potrzebami klienta.</p><p>Uwzględnia się liczbę miejsc pracy, liczbę zamówień na miesiąc, liczbę
                używanego sprzętu.</p><p>Usługa jest zbudowana jak uniwersalna konstrukcja, łatwo dostosowywana do
                Twoich potrzeb.</p><p>Skontaktuj się z nami wygodnym dla Ciebie sposobem, a my przygotujemy ofertę
                handlową.</p></div>
        </div>
    );
};

export default Pl;