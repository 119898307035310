import React from 'react';
import InputFiled from "../../../../ui/InputFiled";
import FieldPhoneMask from "../../../../ui/FieldPhoneMask";
import {useTranslation} from "react-i18next";
import Button from "../../../../ui/Button";
import {useFormikContextProviderRegister} from "../Registration";
import {authThunkAccountAccess} from "../../../../../redux/auth/auth-thunk";
import {loginUserAC} from "../../../../../redux/auth/auth-action";
import {useDispatch} from "react-redux";

import {toast} from "react-toastify";
import {isEmail} from "../../../../../utils/common";

const StepLookup = ({onSwitchTab}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {values, handleChange, setFieldValue, validateForm} = useFormikContextProviderRegister();
    const handleValidate = () => {
        validateForm().then((errors) => {

            // Handle the validation errors
            if (!Object.keys(errors).includes('email') && !Object.keys(errors).includes('phone')) {
                onReqAccountAccess({send_password: 0})

            } else if (Object.keys(errors).includes('email') || Object.keys(errors).includes('phone')) {
                let error_msg = `${errors['phone'] || ''} ${errors['email'] || ''}`
                toast.error(`${error_msg}`)
            }
        });
    };
    const onChangeTelHandler = (result) => {
        handleChange({
            target: {name: 'phone', value: result},
        });
    }
    const onSwitchTabLogin = (login) => {
        onSwitchTab(true);
        const is_email = isEmail(login)
        dispatch(loginUserAC({
            email: is_email ? values.email : '',
            phone: !is_email ? values.phone : ''
        }));


    }
    const onReqAccountAccess = async ({send_password = 0}) => {
        // let login = values.email || values.phone;
        if (values.email) {
            await dispatch(authThunkAccountAccess(
                {login: values.email, send_password: send_password}, null, () => onSwitchTabLogin(values.email)));
        }
        if (values.phone) {
            await dispatch(authThunkAccountAccess(
                {login: values.phone, send_password: send_password}, null, () => onSwitchTabLogin(values.phone)));
        }
        setFieldValue('password', '');
        setFieldValue('master_password', '');
    }
    return (
        <div>
            <InputFiled label={t('login_page.email')}
                        className={'filed-border'} id="email"
                        name="email"
                        type="email" value={values.email} onChange={handleChange}/>
            <FieldPhoneMask onChange={onChangeTelHandler}
                            label={t('feedback_form.form.phone')}
                            value={values.phone}/>
            <Button type={'button'} style={{margin: '0 auto'}}
                    disabled={!values.email && !values.phone}
                    onClick={handleValidate}>{t("btn_next_title")}</Button>

        </div>
    );
};

export default StepLookup;