import React from "react";
import {useTranslation} from "react-i18next";
import IconIfurn from "../../../picture/icon/IconIfurn";
import stl from './main.module.css'

const ProcessControl = () => {
    const {t} = useTranslation()
    return (
        <div className="section_process_control">
            <div className="container">
                <div className="row">
                    <div className={stl.content}>
                        <div className={stl.info}>
                            <h3>{t('process_control.title')}</h3>
                            <p>{t('process_control.description.paragraph_one')}</p>
                            <p>{t('process_control.description.paragraph_two')}</p>
                            <p>{t('process_control.description.paragraph_three')}</p>
                        </div>
                        <IconIfurn/>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default ProcessControl;