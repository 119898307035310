import React, {useState} from 'react';
import InputRadio from "../../ui/InputRadio";
import Login from "../../../picture/icon/Login";
import {useDetectClickOutside} from "react-detect-click-outside/dist";
import IconTrash from "../../../assets/Icons/IconTrash";
import IconArrow from "../../../assets/Icons/IconArrow";
import {useTranslation} from "react-i18next";

const ItemConnectedFirm = ({firm_find, checkedFirm, firm, switchFirms, handlerDeleteFirm}) => {
    const {t} = useTranslation()
    const [isToggle, setIsToggle] = useState(false)
    const ref = useDetectClickOutside({onTriggered: () => setIsToggle(false)});
    const isDisabledDelete = firm_find && firm_find.hasOwnProperty('order_count') ? (!!Number(firm_find.order_count)) : false;
    return (<React.Fragment>
            <li ref={ref}
                className={`${checkedFirm ? 'active-firm cursor-pointer' : ' switch-firm '} connected-firm-item `}>
                <div>
                    <InputRadio onChange={switchFirms(firm.id)} checked={checkedFirm}
                                id={'firm' + firm.id}
                                label={<>   {firm.name} <span className="county">({firm.country})</span> </>}/>
                </div>

                <div className="d-flex align-items-center">
                        <span className="info" onClick={() => setIsToggle(!isToggle)}>
                        <IconArrow className={`${isToggle ? 'icon-arrow-open' : ''} cursor-pointer`}
                                   style={{marginRight: '5px'}}/>
                    </span>
                    {!!(checkedFirm && firm_find) && <a href={firm_find.url.newgo} className="d-flex align-items-center"
                                                        target={'_blank'}> <Login width={'25px'} height={'25px'}/></a>}
                </div>


            </li>
            {isToggle && <ul className={'connected-firm-submenu'}>
                {firm_find && <>
                    <li>{t('count_orders_title')} <span className="value">{firm_find.order_count}</span></li>
                    {firm_find.last_ordered && <li className={"cursor-pointer"}> {t('date_order_title')} <span
                        className="value">{firm_find.last_ordered}</span></li>}
                </>}
                {(!(checkedFirm) && !isDisabledDelete) && <li>
                    {t('order_delete_firm')} <span className="value"><IconTrash onClick={handlerDeleteFirm} className={isDisabledDelete ? 'disabled' : ''}/></span>
                </li>}
            </ul>}
        </React.Fragment>);
};

export default ItemConnectedFirm;