import React from "react";
import {useTranslation} from "react-i18next";
import order from '../../../picture/image/order.png'
import circle from '../../../picture/image/big-circle.png'
import stl from './main.module.css'

const CalculateInstantly = ()=>{
    const {t} = useTranslation()
    return(
        <div className={stl.section_calculate_instantly}>
            <img src={circle} alt="" className={stl.circle}/>

                <div className="row">
                    <div className={stl.content}>

                        <h2>{t('calculate_instantly.title')}</h2>
                        <p>{t('calculate_instantly.description.paragraph_one')}</p>
                        <p>{t('calculate_instantly.description.paragraph_two')}</p>
                        <p>{t('calculate_instantly.description.paragraph_three')}</p>
                        <img src={order} alt=""/>
                    </div>
                </div>

        </div>
    )
}
export default CalculateInstantly;