import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useFormikContextProviderRegister} from "../Registration";
import InputFiled from "../../../../ui/InputFiled";
import Button from "../../../../ui/Button";
import {toast} from "react-toastify";

const StepUserData = ({handleNext}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {values, handleChange, setFieldValue, validateForm} = useFormikContextProviderRegister();
    const handleValidate = () => {
        validateForm().then((errors) => {
            // Handle the validation errors
            if(!Object.keys(errors).includes('name') && !Object.keys(errors).includes('password') ){
                // let stepNext = values.phone && !values.email ? null : 5
                handleNext()
            }else if(Object.keys(errors).includes('name') || Object.keys(errors).includes('password')) {
                toast.error(`${errors['name'] || ''} ${errors['password'] || ''}`)
            }
        });
    };
    const isDisabled = useMemo(()=>{
        let isPhone = values.phone && !values.email;
        if(isPhone){
            return !values.name || !values.password
        }
        return values.name.length < 3
    }, [values])
    return (
        <div >
            <InputFiled label={t("filed_username")}
                        className={'filed-border'} id="name"
                        name="name"
                        type="text" value={values.name} onChange={handleChange}/>
            {values.phone && !values.email &&
                <InputFiled label={t('login_page.password')}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$"
                            className={'filed-border'} id="name"
                            name="password"
                            type="text" value={values.password} onChange={handleChange}/>
            }
            <Button type={'button'} style={{margin: '0 auto'}}
                    disabled={isDisabled}
                    onClick={handleValidate}>{t("btn_next_title")}</Button>

        </div>
    );
};

export default StepUserData;