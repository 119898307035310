import React from 'react';

const Lv = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content">
                <h2 className="privacy-title">Publiskā piedāvājuma līgums</h2><p className="privacy-text">Kopējie
                noteikumi</p>
                <ul>
                    <li>1.1. Šis piedāvājums ir oficiāla “ФОП Коток И.М.” piedāvājums, turpmāk tekstā saukts par
                        “Pārdevējs”, noslēgt attālinātu preču pirkšanas-pārdošanas līgumu, proti, tiešsaistē, turpmāk
                        tekstā - “Līgums”, un izvieto Publisko piedāvājumu (piedāvājumu) oficiālajā Pārdevēja interneta
                        vietnē<a href="https://ifurn.pro" target="_blank"
                                 title="ifurn.pro"
                                 className="privacy-link">“https://ifurn.pro”</a><a
                            href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                            className="privacy-link">“https://newgo.ifurn.pro”</a><a
                            href="https://crm.ifurn.pro" target="_blank" title="crm.ifurn.pro"
                            className="privacy-link">“https://crm.ifurn.pro”</a>(turpmāk - “Interneta vietne”).
                    </li>
                    <li> 1.2. Pilnīgā un bez nosacījumu pieņemšanas brīdī Pircēja puses Pārdevēja piedāvājumu
                        (akceptējot) uzskata par elektroniskā pirkuma-pārdošanas līguma noslēgšanu, par kuru tiek
                        samaksāts Pircēja pasūtījums saskaņā ar Līguma noteikumiem, norādītajiem Pārdevēja interneta
                        vietnē.
                    </li>
                    <li> 2.1. Šajā piedāvājumā, ja konteksts nepieprasa citādi, zemāk norādītie termini ir sekojošā
                        nozīmē:
                    </li>
                    <ul>
                        <li>* “prece” - mēbeļu sastāvdaļas, pusfabrikāti, aksesuāri, komplektējošie un pavadošie
                            priekšmeti;
                        </li>
                        <li>* “Interneta veikals” - saskaņā ar Ukrainas likumu “par elektronisko tirdzniecību” preces,
                            darba vai pakalpojuma piedāvāšanas vai realizēšanas līdzeklis, veicot elektronisku darījumu.
                        </li>
                        <li>* “Pārdevējs” - uzņēmums, kas realizē preces, kuras ir redzamas interneta vietnē.
                        </li>
                        <li>* “Pircējs” - fiziska persona, kas ir noslēgusi Līgumu ar Pārdevēju, saskaņā ar zemāk
                            norādītajiem nosacījumiem.
                        </li>
                        <li>* “Pasūtījums” - individuālo preču izvēle, ko Pircējs norāda pasūtījuma veikšanas un
                            apmaksas laikā.
                        </li>
                    </ul>
                    <p className="privacy-text">Līguma priekšmets</p>
                    <li>3.1. Pārdevējs saistās nodot īpašumā Pircējam Preces, bet Pircējs saistās samaksāt un pieņemt
                        Preces saskaņā ar šā Līguma noteikumiem.
                    </li>
                    <ul>Šis Līgums reglamentē preču pirkšanu un pārdošanu Interneta veikalā, ieskaitot:
                        <li> - Pircēja brīvprātīgo izvēli Interneta veikalā;</li>
                        <li> - Pircēja pašu pasūtījuma veidošanu Interneta veikalā;</li>
                        <li> - Pircēja pasūtījuma apmaksu, kas veikts Interneta veikalā;</li>
                        <li> - pasūtījuma apstrādi un piegādi Pircējam īpašumā saskaņā ar šā Līguma noteikumiem.
                        </li>
                    </ul>
                    <p className="privacy-text">Pasūtījuma veidošanas kārtība</p>
                    <li>4.1. Pircējam ir tiesības veikt pasūtījumu par jebkuru preču, kas ir redzama Interneta veikalā
                        un ir pieejama noliktavā.
                    </li>
                    <li>4.2. Katra pozīcija var tikt iekļauta pasūtījumā jebkurā daudzumā.</li>
                    <li>4.3. Ja prece nav noliktavā, uzņēmuma vadītājam ir pienākums informēt Pircēju (pa telefonu vai
                        ar elektronisko pasta starpniecību).
                    </li>
                    <li>4.4. Ja prece nav pieejama, Pircējam ir tiesības aizstāt to ar analogu, atteikties no šīs preces
                        vai atcelt pasūtījumu.
                    </li>
                    <p className="privacy-text">Apmaksas kārtība</p><p
                    className="privacy-text">Bankas maksājums.&nbsp;</p>
                    <li>5.1. Apmaksa tiek veikta uz Pārdevēja norēķinu kontu. Preču piegāde Pircējam notiek pēc rēķina
                        apmaksas ar nosacījumiem, kas noteikti piegādes apstākļos sadaļā 6.1, 6.2.
                    </li>
                    <li>5.2. Ja nauda nepieciešamajā laikā nav saņemta, Interneta veikals patur tiesības atcelt
                        pasūtījumu.
                    </li>
                    <p className="privacy-text">Pasūtījuma piegādes nosacījumi&nbsp;</p>
                    <li>6.1. Preces, kas iegādātas Interneta veikalā, tiek piegādātas pašizvešanās veidā no rēķina
                        norādītās adreses, ar pārvadātāju vai citu Pircēja ar Pārdevēju noteiktu veidu.
                    </li>
                    <li>6.2. Kopā ar pasūtījumu Pircējam tiek sniegti dokumenti saskaņā ar Ukrainas likumu.
                    </li>
                    <p className="privacy-text">Puses tiesības un pienākumi:&nbsp;</p>
                    <li>7.1. Pārdevējam ir tiesības:</li>
                    <ul>
                        <li>- pašpusēji apturēt pakalpojumu sniegšanu šādā līgumā gadījumā, ja Pircējs pārkāpj šā līguma
                            noteikumus.
                        </li>
                    </ul>
                    <li>7.2. Pircējam ir pienākums:</li>
                    <ul>
                        <li>- laikus samaksāt un saņemt pasūtījumu saskaņā ar šā līguma noteikumiem.
                        </li>
                    </ul>
                    <li>7.3. Pircējam ir tiesības:</li>
                    <ul>
                        <li>- veikt pasūtījumu Interneta veikalā;</li>
                        <li>- noslēgt elektronisko līgumu;</li>
                        <li>- pieprasīt no Pārdevēja šā līguma noteikumu izpildi.
                        </li>
                    </ul>
                    <p className="privacy-text">Pusēm atbildība&nbsp;</p>
                    <li>8.1. Puses atbild par šā līguma noteikumu nepildīšanu vai nekvalitatīvu izpildi saskaņā ar šā
                        līguma noteikumiem un Ukrainas spēkā esošajiem tiesību aktiem.
                    </li>
                    <li>8.2. Pārdevējs neuzņemas atbildību par:</li>
                    <ul>
                        <li>- ražotāja izskatu mainīšanu;</li>
                        <li>- nelielo preces krāsu nesakritību, kas var atšķirties no oriģināla krāsas dēļ dažādu
                            personālo datoru monitoru atšķirīgās krāsu atskaņošanas dēļ;
                        </li>
                        <li>- informācijas saturu un patiesumu, ko Pircējs sniedz pasūtījuma veidošanas laikā;
                        </li>
                        <li>- kavēšanos un traucējumiem pakalpojumu sniegšanā (pasūtījuma apstrāde un preces piegāde),
                            kas notiek iemeslu dēļ, kas nav viņa kontrolē;
                        </li>
                        <li>- nelegālām darbībām, ko Pircējs veic, izmantojot interneta pieeju;
                        </li>
                        <li>- Pircēja tīkla identifikatoru (IP), MAC adreses, lietotājvārda un parole pārraidi trešajām
                            personām;
                        </li>
                    </ul>
                    <li>8.3. Pircējs, izmantojot tam sniegto piekļuvi interneta tīklam, pašsuzdod atbildību par
                        kaitējumu, ko viņa darbības nodara (pat personīgi, ja citas personas bija pie viņa
                        pierakstījušās), fiziskām vai juridiskām personām, valstij vai morāles principiem.
                    </li>
                    <li>8.4. Nepārvarāmu spēku apstākļu gadījumā puses tiek atbrīvotas no šī līguma nosacījumu izpildes.
                        Nepārvarāma spēka šajā līgumā tiek saprasta kā ārkārtēji negaidīti notikumi, kas izslēdz vai
                        objektīvi traucē šī līguma izpildei, kuru puses nevarēja paredzēt un novērst ar saprātīgiem
                        līdzekļiem.
                    </li>
                    <li>8.5. Puses pieliek maksimālas pūles, lai atrisinātu visas nesaskaņas tikai sarunu ceļā.
                    </li>
                    <p className="privacy-text">Citi nosacījumi&nbsp;</p>
                    <li>9.1. Interneta veikals patur tiesības vienpusēji grozīt šo līgumu, iepriekš to izvietojot vietnē<a
                        href="https://newgo.ifurn.pro" target="_blank"
                        title="newgo.ifurn.pro"
                        className="privacy-link">“https://newgo.ifurn.pro” </a></li>
                    <li>9.2. Interneta veikals ir izveidots, lai organizētu preču pārdošanu tālattālināti caur
                        internetu.
                    </li>
                    <li>9.3. Pircējs ir atbildīgs par informācijas precizitāti, ko viņš norāda pasūtījuma veidošanas
                        laikā. Piekrišana (pasūtījuma veidošana un turpmāka preces apmaksa) nozīmē Pircēja pilnīgu
                        piekrišanu pārdevējam savu personisko datu savākšanai, apstrādei, glabāšanai un izmantošanai,
                        saskaņā ar Ukrainas personas datu aizsardzības likumu.
                    </li>
                    <li>9.4. Pasūtījuma apmaksa, kas veikta Pircēja veiktajā Interneta veikalā, nozīmē Pircēja pilnīgu
                        piekrišanu pirkuma-pārdošanas līguma (publiskā piedāvājuma) noteikumiem.
                    </li>
                    <li>9.5. Elektroniskais vienošanās starp pusēm uzskatāms par noslēgtu brīdī, kad personas, kuras ir
                        iesniegušas piedāvājumu noslēgt šādu līgumu, saņem atbildi par šī piedāvājuma pieņemšanu,
                        saskaņā ar 11. panta sesto daļu "Par elektronisko tirdzniecību" Ukrainas likumā.
                    </li>
                    <li>9.6. Interneta veikala resursu izmantošana preces priekšskatīšanai un pasūtījuma veidošanai
                        Pircējam ir bezmaksas.
                    </li>
                    <li>9.7. Pircēja sniegtā informācija ir konfidenciāla. Interneta veikals izmanto Pircēja informāciju
                        tikai pasūtījuma apstrādes, paziņojumu sūtīšanas Pircējam, preces piegādes, savstarpējo norēķinu
                        veikšanas un citiem mērķiem.
                    </li>
                    <p className="privacy-text">Preces ar atbilstošu kvalitāti atgriešanas kārtība</p>
                    <li>10.1. Preču atgriešana Interneta veikalā notiek saskaņā ar Ukrainas spēkā esošajiem likumiem.
                    </li>
                    <p className="privacy-text">Līguma termiņš</p>
                    <li>11.1. Elektroniskais līgums tiek uzskatīts par noslēgtu brīdī, kad persona, kas ir nosūtījusi
                        piedāvājumu slēgt šādu līgumu, saņem atbildi par šī piedāvājuma pieņemšanu, saskaņā ar 11. panta
                        sesto daļu "Par elektronisko tirdzniecību" Ukrainas likumā.
                    </li>
                    <li>11.2. Līguma termiņš var tikt pārtraukts ar abu pušu vienošanos līdz pat preces faktiskai
                        piegādei, atmaksājot naudu.
                    </li>
                    <p className="privacy-text">Lūdzu, ņemiet vērā, ka interneta veikals “СПД Коток И.М.” oficiālajā
                        vietnē<a href="https://newgo.ifurn.pro"
                                 target="_blank"
                                 title="newgo.ifurn.pro" className="privacy-link">“https://newgo.ifurn.pro” </a>atbilstoši
                        Ukrainas likumdošanai ir pilnvarots piedāvāt izmantot interneta platformas tiesības fiziskajām
                        un juridiskajām personām preču realizācijai.
                    </p></ul>

            </div>
        </div>
    );
};

export default Lv;