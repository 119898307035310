import React from 'react';

const IconTg = (props) => {
    let {className = '', ...reset} = props;
    const cName = `icon-tg ${className}`;
    return (
        <svg className={cName} {...reset} width="34" height="28" viewBox="0 0 34 28" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.58313 11.5594L31.7356 0.139765C33.1351 -0.356854 34.3573 0.475112 33.9039 2.55374L33.9065 2.55118L28.7725 26.3095C28.392 27.994 27.373 28.4035 25.9475 27.61L18.1292 21.95L14.3582 25.5185C13.9412 25.9281 13.5894 26.2737 12.7815 26.2737L13.3366 18.4584L27.8265 5.60002C28.4571 5.05476 27.6857 4.74757 26.8544 5.29027L8.94795 16.3644L1.2287 13.999C-0.447014 13.4768 -0.483499 12.353 1.58313 11.5594Z"
                fill="white"/>
        </svg>
    );
};

export default IconTg;