import {createSelector} from 'reselect';


export const confirmSelected = createSelector(
    [(state) => state.appPage.confirm],
    confirm => {
        return confirm
    }
)
export const payModalRequestInvoiceSelected = createSelector(
    [(state) => state.appPage.payModal.requestInvoice],
    requestInvoice => {
        return requestInvoice
    }
)

export const isCommonLoader = createSelector([(state) => state.appPage.isLoader],
    isLoader => {
        return isLoader || false
    }
)

export const appGetEntryFirms = createSelector([(state) => state.appPage.allFirms],
    data => {
        return data
    }
)

export const localApp = createSelector([(state)=> state.appPage.locale], locale=>locale)
export const getNewsSelected = createSelector([(state)=> state.appPage.news], news=>news)
export const getSignUpProviderServiceModalSelected = createSelector([(state)=> state.appPage.signup_provider_services_modal], signup_provider_services_modal=>signup_provider_services_modal)
