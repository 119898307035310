import React from 'react';

const Ua = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Умови повернення грошей:</h1>
                <ul>
                    <li>1. Повернення грошей надається протягом 14 днів</li>
                    <li>2. Повернення грошей здійснюється лише за вперше замовлені послуги. Наприклад: якщо ви
                        замовили послугу передплати, після чого отримали повернення грошей і замовили у нас
                        передплату другий раз, то ви не зможете отримати назад гроші за друге замовлення.
                    </li>
                    <li>3. Повернення коштів провадиться за вирахуванням комісії платіжних систем.</li>
                    <li>4. При платежах карткою VISA та MasterCard гроші повертаються на ту саму картку, з якою було
                        здійснено платіж. Після того, як ми повернемо платіж, гроші з'являються на карті протягом
                        3-х банківських днів. Зверніть увагу, що цей платіж не з'являється у виписках по карті, а
                        просто збільшується сума на карті. Такий тип повернення доступний лише протягом 30 днів
                        після оплати.
                    </li>
                    <li>5. Гроші повертаються за тими ж реквізитами, з яких було здійснено оплату послуг.</li>
                    <li>6. Повернення оплати провадиться протягом 10 днів з моменту подання заявки.</li>
                    <li>7. При поверненні віднімаються всі бонуси та знижки.</li>
                </ul>
            </div>
        </div>
    );
};

export default Ua;