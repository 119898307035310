import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    helpArticleModalSelected,
    helpArticleSendCommentMailSelected
} from "../../../redux/helpl-aricle/help-article-selector";
import Modal from "../../ui/Modal";
import {helpArticleGetByIdToggleModalAC} from "../../../redux/helpl-aricle/help-article-action";
import Loader from "../../Loader";
import ReactHtmlParser from "react-html-parser";
import Comment from "./Comment";
import {helpArticleSendCommentMailThunk} from "../../../redux/helpl-aricle/help-article-thunk";

const HelpArticleModal = () => {

    const help_article_modal = useSelector(helpArticleModalSelected);
    const send_comment_mail = useSelector(helpArticleSendCommentMailSelected)
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(helpArticleGetByIdToggleModalAC({
            is_open: false,
            content: null,
            isError: null,
            error: null,
            isLoading: false
        }))
    }
    const handlerSubmit = async (value, resetFieldCb) => {
        let text = `Комментрарий пользователя к статье №. ${help_article_modal.content.id}. 
        ${value}
        `
        let res = await dispatch(helpArticleSendCommentMailThunk(text))
        if(res && resetFieldCb) resetFieldCb()
        console.log(value);

    }
    if (help_article_modal.isLoading || send_comment_mail.isLoading) return <Loader/>
    if (!help_article_modal.is_open) return null
    return (
        <div>
            <Modal title={help_article_modal.content.title} onClose={onClose}>
                {ReactHtmlParser(`${help_article_modal.content.description}`)}
                <Comment handlerSubmit={handlerSubmit}/>
            </Modal>
        </div>
    );
};

export default HelpArticleModal;