import React from 'react';

const Lv = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list">
                <h4>Datu dzēšanas instrukcija:</h4>
                <p>Ja vēlaties dzēst savus datus no <a href="https://ifurn.pro/" className="" target={'_blank'}> iFurn.pro</a>,
                    lūdzu, informējiet mūs par saviem kontaktiem dzēšanai un iemeslu.</p>
            </div>
        </div>
    );
};

export default Lv;