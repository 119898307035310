import React from 'react';

const En = () => {
    return (
            <div className="info-pages-main-content">
                <div className="info-page-inner-content"><h2 className="privacy-title"> Public offer agreement</h2><p
                    className="privacy-text">General provisions</p>
                    <ul>
                        <li>1.1. This offer is an official offer of FOP Kotok I.M., hereinafter referred to as the
                            “Seller”, to conclude an Agreement for the sale of goods remotely, that is, through an
                            online store, hereinafter referred to as the “Agreement”, and places a Public offer
                            (proposal) on the official website of the Seller<a href="https://ifurn.pro" target="_blank"
                                                                               title="ifurn.pro"
                                                                               className="privacy-link">«https://ifurn.pro», </a><a
                                href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                                className="privacy-link">«https://newgo.ifurn.pro», </a><a href="https://crm.ifurn.pro"
                                                                                           target="_blank"
                                                                                           title="crm.ifurn.pro"
                                                                                           className="privacy-link">«https://crm.ifurn.pro»</a>(hereinafter
                            referred to as the 'Website').
                        </li>
                        <li> 1.2. The moment of full and unconditional acceptance by the Buyer of the Seller's offer
                            (acceptance) to conclude an electronic contract for the sale of goods is the fact that the
                            Buyer pays for the order on the terms of this Agreement, on time and at the prices indicated
                            on the Seller's website.
                        </li>
                        <li> 2.1. In this offer, unless the context otherwise requires, the following terms have the
                            following meanings:
                        </li>
                        <ul>
                            <li>* "goods" - furniture components, semi-finished products, accessories, components and
                                accompanying items;
                            </li>
                            <li>* "Online store" - in accordance with the Law of Ukraine "on electronic commerce", a
                                means for presenting or selling a product, work or service by making an electronic
                                transaction.
                            </li>
                            <li>* "Seller" - a company that sells goods presented on the Internet site.</li>
                            <li>* "Buyer" - an individual who has entered into an Agreement with the Seller on the terms
                                set forth below.
                            </li>
                            <li>* "Order" - selection of individual items from the list of goods specified by the Buyer
                                when placing an order and making payment.
                            </li>
                        </ul>
                        <p className="privacy-text">Subject of the Agreement</p>
                        <li>3.1. The Seller undertakes to transfer the Goods to the Buyer's ownership, and the Buyer
                            undertakes to pay for and accept the Goods under the terms of this Agreement.
                        </li>
                        <ul>This Agreement governs the purchase and sale of goods in the online store, including:
                            <li> - voluntary choice by the Buyer of goods in the online store;</li>
                            <li> - independent registration by the Buyer of the order in the online store;</li>
                            <li> - payment by the Buyer of the order placed in the online store;</li>
                            <li> - processing and delivery of the order to the Buyer in the property on the terms of
                                this Agreement.
                            </li>
                        </ul>
                        <p className="privacy-text">How to place an order</p>
                        <li>4.1. The buyer has the right to place an order for any product presented on the Website of
                            the online store and available.
                        </li>
                        <li>4.2. Each item can be presented in the order in any quantity.</li>
                        <li>4.3. In the absence of goods in stock, the Manager of the company is obliged to notify the
                            Buyer (by phone or via e-mail).
                        </li>
                        <li>4.4. In the absence of a product, the Buyer has the right to replace it with a product of a
                            similar model, to refuse this product, to cancel the order.
                        </li>
                        <p className="privacy-text">Payment order&nbsp;</p><p className="privacy-text">Bank
                        payment.&nbsp;</p>
                        <li>5.1. Payment is made to the account of the Seller. Delivery of goods by the Seller to the
                            Buyer is carried out after payment of the invoice by the method specified in the terms of
                            delivery, clauses 6.1, 6.2.
                        </li>
                        <li>5.2. If funds are not received, the online store reserves the right to cancel the order.
                        </li>
                        <p className="privacy-text">Order delivery terms&nbsp;</p>
                        <li>6.1. Delivery of goods purchased in the online store is carried out by self-delivery from
                            the address indicated on the invoice, by a transport company or in another way agreed by the
                            Buyer with the Seller.
                        </li>
                        <li>6.2. Along with the order, the Buyer is provided with documents in accordance with the
                            legislation of Ukraine.
                        </li>
                        <p className="privacy-text">Rights and obligations of the parties:&nbsp;</p>
                        <li>7.1. The seller has the right:</li>
                        <ul>
                            <li>- unilaterally suspend the provision of services under this agreement in case of
                                violation by the Buyer of the terms of this agreement.
                            </li>
                        </ul>
                        <li>7.2. The buyer is obliged:</li>
                        <ul>
                            <li>- timely pay and receive the order on the terms of this contract.</li>
                        </ul>
                        <li>7.3. The buyer has the right:</li>
                        <ul>
                            <li>- place an order in the online store;</li>
                            <li>- draw up an electronic contract;</li>
                            <li>- require the Seller to fulfill the terms of this Agreement.</li>
                        </ul>
                        <p className="privacy-text">Responsibility of the parties&nbsp;</p>
                        <li>8.1. The parties are responsible for non-fulfillment or improper fulfillment of the terms of
                            this agreement in the manner prescribed by this agreement and the current legislation of
                            Ukraine.
                        </li>
                        <li>8.2. The seller is not responsible for:</li>
                        <ul>
                            <li>- the appearance of the Goods changed by the manufacturer;</li>
                            <li>- for a slight discrepancy in the color gamut of the product, which may differ from the
                                original product solely due to the different color reproduction of personal computer
                                monitors of individual models;
                            </li>
                            <li>- for the content and truthfulness of the information provided by the Buyer when placing
                                an order;
                            </li>
                            <li>- for delays and interruptions in the provision of the Services (order processing and
                                delivery of goods) that occur due to reasons beyond its control;
                            </li>
                            <li>- for illegal illegal actions carried out by the Buyer using this access to the
                                Internet;
                            </li>
                            <li>- for the transfer by the Buyer of their network identifiers - IP, MAC address, login
                                and password to third parties;
                            </li>
                        </ul>
                        <li>8.3. The Buyer, using the access to the Internet provided to him, is solely responsible for
                            the harm caused by his actions (personally, even if another person was under his login) to
                            persons or their property, legal entities, the state or moral principles of morality.
                        </li>
                        <li>8.4. In the event of force majeure circumstances, the parties are released from the terms of
                            this agreement. For the purposes of this agreement, force majeure means events of an
                            extraordinary, unforeseen nature that exclude or objectively interfere with the execution of
                            this agreement, the occurrence of which the Parties could not foresee and prevent by
                            reasonable means.
                        </li>
                        <li>8.5. The parties shall make every effort to resolve any differences solely through
                            negotiations.
                        </li>
                        <p className="privacy-text">Other conditions&nbsp;</p>
                        <li>9.1. The online store reserves the right to unilaterally amend this agreement, subject to
                            its prior publication on the website.<a href="https://newgo.ifurn.pro" target="_blank"
                                                                    title="newgo.ifurn.pro"
                                                                    className="privacy-link">«https://newgo.ifurn.pro» </a>
                        </li>
                        <li>9.2. The online store was created to organize a remote method of selling goods via the
                            Internet.
                        </li>
                        <li>9.3. The buyer is responsible for the accuracy of the information specified when placing an
                            order. At the same time, when making an acceptance (placement of an order and subsequent
                            payment for the goods), the Buyer provides the Seller with his unconditional consent to the
                            collection, processing, storage, use of his personal data, in the understanding of the Law
                            of Ukraine "On the Protection of Personal Data".
                        </li>
                        <li>9.4. Payment by the Buyer of the order placed in the online store means the full consent of
                            the Buyer with the terms of the contract of sale (public offer)
                        </li>
                        <li>9.5. The actual date of the electronic agreement between the parties is the date of
                            acceptance of the conditions, in accordance with Art. 11 of the Law of Ukraine "On
                            Electronic Commerce"
                        </li>
                        <li>9.6. Using the resource of the online store to preview the goods, as well as to place an
                            order for the Buyer is free.
                        </li>
                        <li>9.7. The information provided by the Buyer is confidential. The online store uses
                            information about the Buyer solely for the purpose of processing the order, sending
                            notifications to the Buyer, delivering goods, making mutual settlements, etc.
                        </li>
                        <p className="privacy-text">The procedure for returning goods of good quality</p>
                        <li>10.1. Return of goods to the online store is carried out in accordance with the current
                            legislation of Ukraine.
                        </li>
                        <p className="privacy-text">Contract time</p>
                        <li>11.1. An electronic agreement is considered concluded from the moment the person who sent
                            the offer to conclude such an agreement receives a response on the acceptance of this offer
                            in the manner prescribed by part six of Article 11 of the Law of Ukraine "On Electronic
                            Commerce".
                        </li>
                        <li>11.2. Before the expiration date, this Agreement may be terminated by mutual agreement of
                            the parties until the actual delivery of the goods, by returning the funds.
                        </li>
                        <p className="privacy-text">Please note that the online store "SPD Kotok I.M." on the official
                            website<a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                                      className="privacy-link">«https://newgo.ifurn.pro», </a>has the right, in
                            accordance with the legislation of Ukraine, to grant the right to use the Internet platform
                            to FLP and legal entities for the sale of goods.</p></ul>
                </div>
        </div>
    );
};

export default En;