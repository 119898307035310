import {createSelector} from 'reselect';

// данные пользователя
export const verificationSelected = createSelector(
    [(state)=>state.auth.verification],
    verification=>{
        return verification || {
            isLoading: true
        }
    }
)
export const verificationUserFirmsSelected = createSelector(
    [(state)=>state.auth.verification.user],
    user=>{
        return user.hasOwnProperty('firms') ? user.firms : null
    }
)

export const authProtectedPageSelected = createSelector(
    [(state)=>state.auth.verification],
    verification=>{
        return {
            user: verification.user, isLoading: verification.isLoading
        }
    }
)
export const settingsSelected = createSelector(
    [(state)=>state.auth.verification.settings],
    settings=>{
        return settings
    }
)
// роль пользователя
export const roleUserSelected = createSelector(
    [(state)=>state.auth.verification.user],
    user=>{
        if(!user) return {
            isAdmin: false, isManager: false, isClient: false
        }
        let roles =  user.hasOwnProperty('roles') && Array.isArray(user.roles) ? user.roles : null
        let isAdmin = !!Number(user.admin_in) || (roles && !!roles.find(role=> role.id === 3))
        let isManager = !user.client;
        let isClient = !!user.client;
        let title = isAdmin ? 'admin' : isManager ? 'manager' : isClient ? 'client' : null;
        return  {
            isAdmin: isAdmin, isManager: isManager, isClient: isClient, title : title
        }
    }
)


// данные логина
export const loginSelected = createSelector(
    [(state)=>state.auth.login],
    login=>{
        return login
    }
)
export const registrationSelected = createSelector(
    [(state)=>state.auth.registration],
    registration=>{
        return registration
    }
)

// список фирм пользователя
export const clientsFirmsSelected = createSelector(
    [(state)=>state.auth.client_firms],
    client_firms=>{
        return client_firms
    }
)



export const authLookupSelected = createSelector(
    [(state)=>state.auth.auth_lookup],
    auth_lookup=>{
        return auth_lookup
    }
)
export const confirmAccountChannelsModalSelected = createSelector(
    [(state)=>state.auth.confirmAccountChannelsModal],
    confirmAccountChannelsModal=>{
        return confirmAccountChannelsModal
    }
)

export const messageChannelsSelected = createSelector([(state)=> state.auth.message_channel], message_channel=>message_channel)
export const messageChannelsDefaultSelected = createSelector([(state)=> state.auth.message_channel], message_channel=>{
    if(message_channel && message_channel.data && message_channel.data.hasOwnProperty('default_message_channel')){
        return message_channel.data.default_message_channel
    }
        return null

})

export const networkLoginSelected = createSelector([(state)=>state.auth.network_login], network_login => network_login)
export const authNetworkSocialLoginSelected = createSelector([(state)=>state.auth.auth_network_social_login], auth_network_social_login => auth_network_social_login)