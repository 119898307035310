import React from 'react';

const Ua = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list"><h4>Прайс-лист на послуги:</h4><p>Ціна формується під
                потреби Клієнта.</p><p>Враховується кількість робочих місць, кількість замовлень на місяць, кількість
                використовуваного обладнання.</p><p>Сервіс побудований як універсальний конструктор, легко адаптований
                під ваші потреби.</p><p>Зв'яжіться з нами зручним для вас способом і ми сформуємо комерційну
                пропозицію.</p></div>
        </div>
    );
};

export default Ua;