import React, {useState} from 'react';
import Card from "../../ui/Card";
import IconLogout from "../../../assets/Icons/IconLogout";
import {useFormik} from "formik";
import IconEdit from "../../../assets/Icons/IconEdit";
import IconSave from "../../../assets/Icons/IconSave";
import InputFiled from "../../ui/InputFiled";
import FieldPhoneMask from "../../ui/FieldPhoneMask";
import {useDispatch, useSelector} from "react-redux";
import {accountUpdateUserDataThunk, confirmMessageChannelEmailOrPhone} from "../../../redux/auth/auth-thunk";
import {useDetectClickOutside} from "react-detect-click-outside/dist";
import IconClose from "../../../assets/Icons/IconClose";
import ConfirmAuth from "../../Modal/Account/ConfirmAuth";
import IconConfirm from "../../../assets/Icons/IconConfirm";
import IconNotConfirm from "../../../assets/Icons/IconNotConfirm";
import {messageChannelsDefaultSelected} from "../../../redux/auth/auht-selector";
import {helpArticleGetByIdToggleModalThunk} from "../../../redux/helpl-aricle/help-article-thunk";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../../redux/helpl-aricle/help-article-constants";
import IconQuestion from "../../../picture/icon/IconQuestion";

const styleIconConfirm = {
    marginLeft: "12px",
}

const UserData = ({
                      email,
                      phone,
                      name,
                      onLogoutSystem,
                      t,
                      client = null,
                      isClient,
                      phone_confirmed = false,
                      email_confirmed = false
                  }) => {
    const {values, handleChange, submitForm} = useFormik({
        initialValues: {
            email: email, phone: phone, name: name, client: client
        }
    });
    const dispatch = useDispatch();
    const message_channel_default = useSelector(messageChannelsDefaultSelected)
    const onChangeTelHandler = (result) => {
        handleChange({
            target: {name: 'phone', value: result},
        });
    }
    const [isEdit, setIsEdit] = useState(false);
    const ref = useDetectClickOutside({onTriggered: () => setIsEdit(false)})
    const onHandlerSubmit = () => {
        // submitForm();
        let api_data = {
            ...values
        }
        dispatch(accountUpdateUserDataThunk({
            ...api_data
        }))
    }
    const handlerConfirm = ({value, send_password, message_channel = null, password = null}) => {
        dispatch(confirmMessageChannelEmailOrPhone({value, send_password, message_channel, password}))
    }
    const handlerHelpArticle = ()=>{
        dispatch(helpArticleGetByIdToggleModalThunk(HELP_ARTICLE_CONSTANTS_IDS.change_user_data))
    }
    return (
        <div>
            <Card className={'user-data'} ref={ref}>
                <div className="user-data-panel">
                    {isClient ?
                        isEdit ? <>
                                <IconSave onClick={() => onHandlerSubmit()}/>
                                <IconClose style={{marginLeft: '10px'}} onClick={() => setIsEdit(false)}/>
                            </>
                            :<><IconEdit onClick={() => setIsEdit(true)}/>
                            <IconQuestion onClick={handlerHelpArticle} style={{marginLeft: '8px'}}/>
                            </>
                        : null
                    }
                </div>
                <ul className={'list-none'}>
                    {name && <li><strong>{t("user_title")}</strong>:
                        {isEdit ? <InputFiled name="name" type="text" value={values.name} onChange={handleChange}/>
                            : name}
                    </li>}
                    <li>


                        {isEdit ?
                            <>
                                <strong>{t("login_page.email")}</strong>:
                                <InputFiled name="email" type="text" value={values.email} onChange={handleChange}/>

                            </>
                            : <div className={'d-flex align-items-center justify-between'}>
                                <span>
                                    <strong>{t("login_page.email")}</strong>:
                                    {email}
                                </span>
                                {email_confirmed ? <span title={t('confirmed_title')}> <IconConfirm/></span> :
                                    <span title={t('confirm_email_title')}>
                                    <IconNotConfirm
                                        onClick={() => {
                                            handlerConfirm({
                                                value: email,
                                                send_password: 1
                                            })
                                        }
                                        }/>
                                    </span>}
                            </div>}
                    </li>
                    <li className={''}>
                        {isEdit ? <>
                                <strong>{t("login_page.phone")}</strong>:
                                <FieldPhoneMask onChange={onChangeTelHandler} value={values.phone} autoComplete={"off"}/>
                            </>
                            :
                            <div className={'d-flex justify-between align-items-center'}>
                                <span>
                                    <strong>{t("login_page.phone")}</strong>:
                                    {phone}
                                </span>

                                {phone_confirmed ?
                                    <span title={t('confirmed_title')}>  <IconConfirm
                                        style={styleIconConfirm}/></span> :
                                    !message_channel_default ?
                                        <span className={'clr-red fs-12'}>{t('connect_chat_bot_title')}</span> :
                                        <span
                                            title={`${!message_channel_default ? t('connect_chat_bot_title') : t("confirm_tel_title")}`}>
                                    <IconNotConfirm className={`${!message_channel_default ? 'disabled' : ''} `}
                                                    onClick={() => {
                                                        handlerConfirm({
                                                            value: phone,
                                                            send_password: 1,
                                                            message_channel: message_channel_default
                                                        })
                                                    }
                                                    }/>
                                    </span>

                                }

                            </div>}
                    </li>
                    {onLogoutSystem && <li><span className="exit cursor-pointer d-flex align-items-center"
                                                 onClick={onLogoutSystem}><IconLogout
                        style={{marginRight: '8px'}}/> {t("exit_title")}</span></li>}
                </ul>
            </Card>
            <ConfirmAuth key={'confirm-auth-modal'}/>
        </div>
    );
};

export default UserData;