import React, {useCallback, useState} from 'react';
import {clientsFirmsSelected, verificationUserFirmsSelected} from "../../../redux/auth/auht-selector";
import {useDispatch, useSelector} from "react-redux";
import Card from "../../ui/Card";
import Loader from "../../Loader";
import Button from "../../ui/Button";
import {LS_FIRM} from "../../../consts/localStorageNaming";
import {useTranslation} from "react-i18next";
import {createFormDataArrayObj, utf8ToB64} from "../../../utils/common";
import {firmsThunkConnected, firmsThunkDeleteFirm, firmsThunkSwitchFirms} from "../../../redux/firms-thunk";
import ItemConnectedFirm from "./ItemConnectedFirm";
import IconQuestion from "../../../picture/icon/IconQuestion";
import {helpArticleGetByIdToggleModalThunk} from "../../../redux/helpl-aricle/help-article-thunk";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../../redux/helpl-aricle/help-article-constants";

const Firms = (callback, deps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const clientsFirms = useSelector(clientsFirmsSelected);
    const userFirms = useSelector(verificationUserFirmsSelected)
    const [add_connection_firm, setConnectionFirm] = useState([])

    const onChangeHandlerConnectionFirm = useCallback((firm_id) => () => {
        if (add_connection_firm.includes(firm_id)) {
            setConnectionFirm((prev) => prev.filter(id => id !== firm_id));
            return
        }
        setConnectionFirm((prev) => [...prev, firm_id]);
    });

    const handlerConnectedFirms = () => {
        let createData = add_connection_firm.map(id => {
            return {[`firms[]`]: id}
        })
        const fromData = createFormDataArrayObj(createData);
        let _uri = process.env.NODE_ENV === "development" ? 'https://ifurn.pro/' : window.location.origin;
        let from_static_uri = utf8ToB64(_uri);
        dispatch(firmsThunkConnected(fromData, from_static_uri))
    }
    const switchFirms = (id) => () => {
        dispatch(firmsThunkSwitchFirms(id))
    }
    const handlerDeleteFirm = (firm_id) => () => {
        let _uri = process.env.NODE_ENV === "development" ? 'https://ifurn.pro/' : window.location.origin;

        let from_static_uri = utf8ToB64(_uri);
        dispatch(firmsThunkDeleteFirm({
            [`firms[]`]: firm_id
        }, from_static_uri))
    }
    const handlerHelpArticle =(id)=> (e)=>{
        e.stopPropagation();
        e.preventDefault();
        dispatch(helpArticleGetByIdToggleModalThunk(id))
    }
    if (clientsFirms.isLoading) {
        return <Loader/>
    }
    return (
        <>
            {!!(clientsFirms.entry_firm && clientsFirms.entry_firm.hasOwnProperty('connected') && Array.isArray(clientsFirms.entry_firm.connected) && clientsFirms.entry_firm.connected.length) &&
                <Card label={ <>
                {t("connected_title")}
                    <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.change_personal_cabinet)} style={{marginLeft: '5px'}}/>
                </>
                 } className={'cabinet-firms'}>
                    <ul className={'list-none'}>
                        {clientsFirms.entry_firm.connected.map(firm => {
                            let checkedFirm = Number(firm.id) === Number(localStorage.getItem(LS_FIRM));

                            const firm_find = Array.isArray(userFirms) && userFirms.length ? userFirms.find((item) => +item.firm === +firm.id) : null
                            return <ItemConnectedFirm firm={firm} checkedFirm={checkedFirm} switchFirms={switchFirms}
                                                      handlerDeleteFirm={handlerDeleteFirm(firm.id)}
                                                      firm_find={firm_find} key={`firm_connected_${firm.id}`}/>
                        })}
                    </ul>
                </Card>

            }
            {!!(clientsFirms.entry_firm && clientsFirms.entry_firm.hasOwnProperty('not_connected') && Array.isArray(clientsFirms.entry_firm.not_connected) && clientsFirms.entry_firm.not_connected.length) &&
                <Card label={ <>
                    {t("no_connected_title")}
                    <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.register_personal_account)} style={{marginLeft: '5px'}}/>
                    </>
                } className={'cabinet-firms'} isCollapse={true}
                      footer={
                          <Button className={'btn-revert'} disabled={!add_connection_firm.length}
                                  onClick={handlerConnectedFirms}>{t("feedback_form.form.submit")}</Button>

                      }>
                    <ul className={'list-none'}>
                        {clientsFirms.entry_firm.not_connected.map(firm => {
                            return <li className={'filed-checkbox '}>
                                <input type="checkbox" id={`firm_${firm.id}`}
                                       checked={add_connection_firm.includes(firm.id)}
                                       onChange={onChangeHandlerConnectionFirm(firm.id)}
                                />
                                <label htmlFor={`firm_${firm.id}`}>
                                    {firm.name} <span className="county">({firm.country})</span>
                                </label>
                            </li>
                        })}
                    </ul>
                </Card>

            }
        </>

    );
};

export default Firms;