import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setISTermUse} from "../redux/app-reducer";
import Close from "../picture/icon/Close";

const TermsUse = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(setISTermUse(false))
    }
    return (
        <div className="term-use__container modal-container">
            <div className="container">
                <div className="row">
                    <div className="modal-content">
                        <div className="modal-head">
                            <div className="h4">
                                {t('terms_modal.main_title')}
                            </div>
                            <div className="close" onClick={onClose}>
                                <Close isBlack={false}/>
                            </div>
                        </div>
                        <div className="modal-body">
                            {/*<div className="term-use__body">*/}

                            <div className="content">
                                <p>   {t('terms_modal.description.one')} <a
                                    href="http://zakon2.rada.gov.ua/laws/show/2297-17">{t('terms_modal.description.link')}</a>
                                </p>

                                <p>
                                    <div className="h5">{t('terms_modal.section_1.title')}</div>
                                    <ul>
                                        <li>{t('terms_modal.section_1.list.0')}</li>
                                        <li>{t('terms_modal.section_1.list.1')}</li>
                                        <li>{t('terms_modal.section_1.list.2')}</li>
                                        <li>{t('terms_modal.section_1.list.3')}</li>
                                    </ul>
                                </p>
                                <p>
                                    <div className="h5">{t('terms_modal.section_2.title')}</div>
                                    <ul>
                                        <li>{t('terms_modal.section_2.list.0')}</li>
                                        <li>{t('terms_modal.section_2.list.1')}</li>
                                        <li>{t('terms_modal.section_2.list.2')}</li>
                                        <li>{t('terms_modal.section_2.list.3')}</li>
                                        <li>{t('terms_modal.section_2.list.4')}</li>
                                        <li>{t('terms_modal.section_2.list.5')}</li>
                                        <li>{t('terms_modal.section_2.list.6')}</li>
                                    </ul>
                                </p>
                                <p>
                                    <div className="h5">{t('terms_modal.section_3.title')}</div>
                                    <ul>
                                        <li>{t('terms_modal.section_3.list.0')}</li>
                                    </ul>
                                </p>
                                <p>
                                    <div className="h5">{t('terms_modal.section_4.title')}</div>
                                    <ul>
                                        <li>{t('terms_modal.section_4.list.0')}</li>
                                    </ul>
                                </p>
                                <p>
                                    <div className="h5">{t('terms_modal.section_5.title')}</div>
                                    <ul>
                                        <li>{t('terms_modal.section_5.list.0')}</li>
                                    </ul>
                                </p>

                            </div>
                            {/*</div>*/}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TermsUse