import React from "react";
import {useTranslation} from "react-i18next";
import Settings from "../../../picture/icon/Settings";
import stl from "./main.module.css"
const StepConnect = ()=>{
    const {t} = useTranslation()
    return(
        <div className={stl.section_step_connect} id={"stepConnect"}>
            <div className="container">
                <div className="row">
                    <div className="content">
                        <h3 className="h2 text-center">{t('step_connect.title')}</h3>
                        <div className={stl.steps}>
                            <div className={stl.item}>
                                <span>01</span>
                             <p>   {t('step_connect.steps.0.title')}</p>
                                <Settings/>
                            </div>
                            <div className={stl.item}>
                                <span>02</span>
                                <p>{t('step_connect.steps.1.title')}</p></div>
                            <div className={stl.item}>
                                <span>03</span>
                                <p>{t('step_connect.steps.2.title')}</p></div>
                            <div className={stl.item}>
                                <span>04</span>
                                <p>{t('step_connect.steps.3.title')}</p></div>
                            <div className={stl.item}>
                                <span>05</span>
                                <p>{t('step_connect.steps.4.title')}</p></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default StepConnect;