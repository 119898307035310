export const CRM_DATA = {
    label: process.env.REACT_APP_CRM_IFRURN_PRO_TITLE,
    uri: process.env.REACT_APP_CRM_IFRURN_PRO_URL
}
const Newgo = {
    label: process.env.REACT_APP_NEWGO_IFRURN_PRO_TITLE,
    uri: process.env.REACT_APP_NEWGO_IFRURN_PRO_URL
}

console.log(process.env.REACT_APP_NEWGO_IFRURN_PRO_URL)

const Admin = {
    label: process.env.REACT_APP_ADMIN_IFRURN_PRO_TITLE,
    uri: process.env.REACT_APP_ADMIN_IFRURN_PRO_URL
}
const QIfurn = {
    label: process.env.REACT_APP_Q_IFRURN_PRO_TITLE,
    uri: process.env.REACT_APP_Q_IFRURN_PRO_URL
}


// export const ROLE_ACCESS_SITE = {
//     admin: [
//         {...Admin}, {...QIfurn}, {...CRM_DATA}
//     ],
//     client: [Newgo],
//     manager: [CRM_DATA],
// }

export const ROLE_ACCESS_SITE = {
    admin: [
        {
            key: "admin",
            label: process.env.REACT_APP_ADMIN_IFRURN_PRO_TITLE
        },
        {
            key: "q",
            label: process.env.REACT_APP_Q_IFRURN_PRO_TITLE
        },
        {
            key: "crm",
            label: process.env.REACT_APP_CRM_IFRURN_PRO_TITLE
        }
    ],
    client: [{
        key: "newgo",
        label : process.env.REACT_APP_NEWGO_IFRURN_PRO_TITLE,
    }],
    manager: [{
        key: "crm",
        label : process.env.REACT_APP_CRM_IFRURN_PRO_TITLE,
    }],
}