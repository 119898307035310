import React, {useCallback} from 'react';
import Modal from "../../ui/Modal";
import {useDispatch, useSelector} from "react-redux";
import {confirmAccountChannelsModalSelected} from "../../../redux/auth/auht-selector";
import {accountMessageChannelsConfirmAC} from "../../../redux/auth/auth-action";
import {useFormik} from "formik";
import InputFiled from "../../ui/InputFiled";
import Button from "../../ui/Button";
import {useTranslation} from "react-i18next";
import {confirmMessageChannelEmailOrPhone} from "../../../redux/auth/auth-thunk";

const ConfirmAuth = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const confirm_modal_state = useSelector(confirmAccountChannelsModalSelected);
    const onClose = useCallback(() => {
        dispatch(accountMessageChannelsConfirmAC({
            isOpen: false
        }))
    }, [dispatch]);

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        onSubmit: (values) => {
            if (!confirm_modal_state.form_data) {
                alert('Error')
                return
            }
            let data_api = {
                password: values.password,
                value: confirm_modal_state.form_data.value,
                send_password: 0,
            }
            dispatch(confirmMessageChannelEmailOrPhone(data_api))
        }
    });

    if (!confirm_modal_state.isOpen) return null;

    return (
        <Modal title={t('confirm_title')} onClose={onClose} isModalSm={true}>
            <form onSubmit={formik.handleSubmit}>
                <InputFiled label= {t('login_page.password')} className={'filed-border'} name={'password'} type="text" value={formik.values.password} required={true}
                            onChange={formik.handleChange}/>
              <div className="d-flex justify-center">
                  <Button type={'submit'}>
                      <> {t('feedback_form.form.submit')}</>
                  </Button>
              </div>
            </form>
        </Modal>
    );
};

export default ConfirmAuth;