export const AUTH_INITIAL_VERIFICATION_USER = 'AUTH/INITIAL_VERIFICATION_USER';
export const AUTH_LOGIN_USER = 'AUTH/INITIAL_LOGIN_USER';
export const AUTH_REGISTER_USER = 'AUTH/REGISTER_USER';
export const AUTH_LOOKUP = 'AUTH/LOOKUP';
export const AUTH_CLIENTS_FIRMS = 'AUTH/CLIENTS_FIRMS';
export const AUTH_ACCOUNT_MESSAGE_CHANNEL = 'AUTH/ACCOUNT_MESSAGE_CHANNEL';
export const AUTH_ACCOUNT_MESSAGE_CHANNEL_CONFIRM = 'AUTH/ACCOUNT_MESSAGE_CHANNEL_CONFIRM';
export const AUTH_ACCOUNT_NETWORK_SOCIAL = 'AUTH/ACCOUNT_NETWORK_SOCIAL';
export const AUTH_NETWORK_SOCIAL_LOGIN = 'AUTH/NETWORK_SOCIAL_LOGIN';

export const initialVerificationUserAC = (payload) => {
    return {
        type: AUTH_INITIAL_VERIFICATION_USER,
        payload: payload
    }
}
export const clientsFirmsAC = (payload) => {
    return {
        type: AUTH_CLIENTS_FIRMS,
        payload: payload
    }
}
export const loginUserAC = (payload) => {
    return {
        type: AUTH_LOGIN_USER,
        payload: payload
    }
}
export const authLookupAC = (payload) => {
    return {
        type: AUTH_LOOKUP,
        payload: payload
    }
}
export const registerUserAC = (payload) => {
    return {
        type: AUTH_REGISTER_USER,
        payload: payload
    }
}


export const accountMessageChannelsAC = (payload) => {
    return {
        type: AUTH_ACCOUNT_MESSAGE_CHANNEL,
        payload: payload
    }
}
export const accountMessageChannelsConfirmAC = (payload) => {
    return {
        type: AUTH_ACCOUNT_MESSAGE_CHANNEL_CONFIRM,
        payload: payload
    }
}
export const accountNetworkLoginAC = (payload) => {
    return {
        type: AUTH_ACCOUNT_NETWORK_SOCIAL,
        payload: payload
    }
}
export const authNetworkSocialLoginAC = (payload) => {
    return {
        type: AUTH_NETWORK_SOCIAL_LOGIN,
        payload: payload
    }
}