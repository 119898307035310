import React from 'react';
import {PAGES_HASH_IDS} from "../../routing_hash";
import Offerta from "./entries/Offerta/index";
import SecurityPolicy from "./entries/SecurityPolicy/index";
import RefundTerms from "./entries/RefundTerms/index";
import Privacy from "./entries/Privacy";
import PriceList from "./entries/PriceList/index";
import DeleteUser from "./entries/DeleteUser";

const EntriesPage = {
    [PAGES_HASH_IDS.privacy] : Privacy,
    [PAGES_HASH_IDS.offerta] : Offerta,
    [PAGES_HASH_IDS.securityPolicy] : SecurityPolicy,
    [PAGES_HASH_IDS.refundTerms] : RefundTerms,
    [PAGES_HASH_IDS.priceList] : PriceList,
    [PAGES_HASH_IDS.deleteUser] : DeleteUser,
}

const PagesWrap = ({currentTab}) => {

    if(!EntriesPage[currentTab]) return null;
    const Page =  EntriesPage[currentTab];
    return <div className="container">
        <div className="row">
            <Page />

        </div>
    </div>
};

export default PagesWrap;