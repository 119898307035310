import HttpApi from 'i18next-http-backend'
import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import cookie from 'js-cookie';
import {LS_LANG} from "../consts/localStorageNaming";
import {currentLang} from "../utils/multilingualism";
import i18n from "i18next";

export const DEFAULT_LANG = 'en'
const initLanguage = () => {
    const location = window.location;
    let local = currentLang(location.pathname);
    return i18next
        .use(initReactI18next) // passes i18n down to react-i18next
        .use(I18nextBrowserLanguageDetector)
        .use(HttpApi)
        .init({
            lng:local || cookie.get('i18next')  || DEFAULT_LANG,
            supportedLngs: ['ru', 'en', 'ua', 'pl', 'ro', 'he','de', 'et', 'lt', 'lv', 'sk'],
            fallbackLng:DEFAULT_LANG,
            detection: {
                // order and from where user language should be detected
                // lookupQuerystring: "lng",
                order: ['cookie', 'path'],
                // order: ['querystring', 'htmlTag', 'path', 'cookie', 'localStorage', 'subdomain'],
                caches: ['cookie'],
                cookieOptions: {path: '/', sameSite: 'strict'}
            },
            debug: false,
            backend: {
                loadPath: '/locales/{{lng}}/translation.json',
            },
            react: {useSuspense: false}
        })
};


export {initLanguage}