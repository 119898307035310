import React from 'react';

const He = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h2 className="privacy-title"> הסכם הצעה לציבור</h2><p
                className="privacy-text">הוראות כלליות</p>
                <ul>
                    <li>1.1. הצעה זו הינה הצעה רשמית של FOP Kotok I.M., להלן "המוכר", לערוך הסכם למכירת סחורות
                        מרחוק, כלומר באמצעות חנות מקוונת, להלן "ההסכם", ומקומות הצעה פומבית (הצעה) באתר הרשמי של
                        המוכר<a href="https://ifurn.pro" target="_blank" title="ifurn.pro"
                                className="privacy-link">«https://ifurn.pro», </a><a href="https://newgo.ifurn.pro"
                                                                                     target="_blank"
                                                                                     title="newgo.ifurn.pro"
                                                                                     className="privacy-link">«https://newgo.ifurn.pro», </a><a
                            href="https://crm.ifurn.pro" target="_blank" title="crm.ifurn.pro"
                            className="privacy-link">«https://crm.ifurn.pro»</a>(להלן "האתר").
                    </li>
                    <li> 1.2. הרגע של הסכמה מלאה ובלתי מותנית על ידי הקונה להצעת המוכר (קיבול) לכריתת חוזה אלקטרוני
                        למכירת טובין הוא העובדה שהקונה משלם עבור ההזמנה בתנאי הסכם זה, בזמן ובמחירים. המצוין באתר
                        האינטרנט של המוכר.
                    </li>
                    <li> 2.1. בהצעה זו, אלא אם כן ההקשר מחייב אחרת, למונחים הבאים יש את המשמעויות הבאות:</li>
                    <ul>
                        <li>* "סחורה" - רכיבי ריהוט, מוצרים חצי גמורים, אביזרים, רכיבים ופריטים נלווים;</li>
                        <li>* "חנות מקוונת" - בהתאם לחוק אוקראינה "על מסחר אלקטרוני", אמצעי להצגת או מכירה של מוצר,
                            עבודה או שירות באמצעות ביצוע עסקה אלקטרונית.
                        </li>
                        <li>* "מוכר" - חברה המוכרת טובין המוצגים באתר האינטרנט.</li>
                        <li>* "קונה" - יחיד שהתקשר עם המוכר בהסכם בתנאים המפורטים להלן.</li>
                        <li>* "הזמנה" - בחירת פריטים בודדים מרשימת הסחורה שצוינה על ידי הקונה בעת ביצוע הזמנה
                            ותשלום.
                        </li>
                    </ul>
                    <p className="privacy-text">נושא ההסכם</p>
                    <li>3.1. המוכר מתחייב להעביר את הטובין לבעלות הקונה, והקונה מתחייב לשלם עבור הטובין ולקבל את
                        הסחורה על פי תנאי הסכם זה.
                    </li>
                    <ul>הסכם זה מסדיר את הרכישה והמכירה של מוצרים בחנות המקוונת, לרבות:
                        <li> - בחירה מרצון על ידי הקונה של סחורה בחנות המקוונת;</li>
                        <li> - רישום עצמאי על ידי הקונה של ההזמנה בחנות המקוונת;</li>
                        <li> - תשלום על ידי הקונה על ההזמנה שבוצעה בחנות המקוונת;</li>
                        <li> - עיבוד ומסירת ההזמנה לקונה בנכס על פי תנאי הסכם זה.</li>
                    </ul>
                    <p className="privacy-text">כיצד לבצע הזמנה</p>
                    <li>4.1. לרוכש הזכות לבצע הזמנה עבור כל מוצר המוצג באתר החנות המקוונת וזמין.</li>
                    <li>4.2. ניתן להציג כל פריט בהזמנה בכל כמות.</li>
                    <li>4.3. בהעדר סחורה במלאי, חלה חובה על מנהל החברה להודיע &ZeroWidthSpace;&ZeroWidthSpace;על כך
                        לקונה (בטלפון או באמצעות דואר אלקטרוני).
                    </li>
                    <li>4.4. בהעדר מוצר, לרוכש הזכות להחליפו במוצר מדגם דומה, לסרב למוצר זה, לבטל את ההזמנה.</li>
                    <p className="privacy-text">הוראת תשלום&nbsp;</p><p className="privacy-text">תשלום
                    בנקאי.&nbsp;</p>
                    <li>5.1. התשלום מתבצע לחשבון המוכר. אספקת הסחורה על ידי המוכר לקונה מתבצעת לאחר תשלום החשבונית
                        בדרך המפורטת בתנאי האספקה, סעיפים 6.1, 6.2.
                    </li>
                    <li>5.2. אם לא יתקבלו כספים, החנות המקוונת שומרת לעצמה את הזכות לבטל את ההזמנה.</li>
                    <p className="privacy-text">תנאי משלוח הזמנה&nbsp;</p>
                    <li>6.1. אספקת סחורה הנרכשת בחנות המקוונת מתבצעת במשלוח עצמי מהכתובת המצוינת בחשבונית, ע"י חברת
                        הובלה או בדרך אחרת המוסכמת על ידי הקונה עם המוכר.
                    </li>
                    <li>6.2. יחד עם ההזמנה, נמסרים לקונה מסמכים בהתאם לחקיקה של אוקראינה.</li>
                    <p className="privacy-text">זכויות וחובות של הצדדים:&nbsp;</p>
                    <li>7.1. למוכר הזכות:</li>
                    <ul>
                        <li>- להשעות באופן חד צדדי את מתן השירותים על פי הסכם זה במקרה של הפרה על ידי הקונה של תנאי
                            הסכם זה.
                        </li>
                    </ul>
                    <li>7.2. על הקונה חלה חובה:</li>
                    <ul>
                        <li>- תשלום בזמן וקבל את ההזמנה בתנאי חוזה זה.-</li>
                    </ul>
                    <li>7.3. לרוכש יש את הזכות:</li>
                    <ul>
                        <li>- בצע הזמנה בחנות המקוונת;</li>
                        <li>- עריכת חוזה אלקטרוני;</li>
                        <li>- לדרוש מהמוכר למלא את תנאי הסכם זה.</li>
                    </ul>
                    <p className="privacy-text">אחריות הצדדים&nbsp;</p>
                    <li>8.1. הצדדים אחראים לאי מילוי או מילוי לא נאות של תנאי הסכם זה באופן שנקבע בהסכם זה ובחקיקה
                        הנוכחית של אוקראינה.
                    </li>
                    <li>8.2. המוכר אינו אחראי ל:</li>
                    <ul>
                        <li>- מראה הטובין ששונה על ידי היצרן;</li>
                        <li>- עבור אי התאמה קלה בסולם הצבעים של המוצר, אשר עשוי להיות שונה מהמוצר המקורי אך ורק בשל
                            שכפול צבע שונה של מסכי מחשב אישיים מדגמים בודדים;
                        </li>
                        <li>- לתוכן ואמיתות המידע שמסר הקונה בעת ביצוע ההזמנה;</li>
                        <li>- בגין עיכובים והפרעות במתן השירותים (עיבוד הזמנות ואספקת סחורה) המתרחשים מסיבות שאינן
                            בשליטתה;
                        </li>
                        <li>- בגין פעולות בלתי חוקיות שלא כדין שבוצעו על ידי הקונה באמצעות גישה זו לאינטרנט;</li>
                        <li>- להעברת מזהי הרשת שלו על ידי הקונה - IP, כתובת MAC, כניסה וסיסמה לצדדים שלישיים;</li>
                    </ul>
                    <li>8.3. הקונה, המשתמש בגישה לאינטרנט הניתנת לו, הוא האחראי הבלעדי לנזק שנגרם ממעשיו (באופן
                        אישי, גם אם אדם אחר היה תחת כניסתו) לבני אדם או לרכושם, לגופים המשפטיים, למדינה או לעקרונות
                        המוסר של מוּסָרִיוּת.
                    </li>
                    <li>8.4. במקרה של נסיבות כוח עליון, הצדדים משוחררים מתנאי הסכם זה. למטרות הסכם זה, כוח עליון
                        משמעו אירועים בעלי אופי בלתי רגיל, בלתי צפוי, המוציאים או מפריעים באופן אובייקטיבי לביצוע
                        הסכם זה, שאת התרחשותם לא יכלו הצדדים לחזות ולמנוע באמצעים סבירים.
                    </li>
                    <li>8.5. הצדדים יעשו כל מאמץ לפתור כל מחלוקת אך ורק באמצעות משא ומתן.</li>
                    <p className="privacy-text">תנאים אחרים&nbsp;</p>
                    <li>9.1. החנות המקוונת שומרת לעצמה את הזכות לשנות הסכם זה באופן חד צדדי, בכפוף לפרסומו מראש
                        באתר.<a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                                className="privacy-link">«https://newgo.ifurn.pro» </a></li>
                    <li>9.2. החנות המקוונת נוצרה כדי לארגן שיטה מרוחקת למכירת סחורות דרך האינטרנט.</li>
                    <li>9.3. הקונה אחראי על דיוק המידע שצוין בעת &ZeroWidthSpace;&ZeroWidthSpace;ביצוע ההזמנה. יחד
                        עם זאת, בעת ביצוע הקבלה (ביצוע הזמנה ותשלום לאחר מכן עבור הסחורה), נותן הקונה למוכר את
                        הסכמתו ללא תנאי לאיסוף, עיבוד, אחסון, שימוש בנתוניו האישיים, בהבנת האמור ב. חוק אוקראינה "על
                        הגנת נתונים אישיים".
                    </li>
                    <li>9.4. תשלום על ידי הקונה על ההזמנה שבוצעה בחנות המקוונת פירושו הסכמה מלאה של הקונה לתנאי חוזה
                        המכר (הצעה פומבית)
                    </li>
                    <li>9.5. התאריך בפועל של ההסכם האלקטרוני בין הצדדים הוא מועד קבלת התנאים, בהתאם לאמנות. 11 לחוק
                        אוקראינה "על מסחר אלקטרוני"
                    </li>
                    <li>9.6. השימוש במשאב של החנות המקוונת לתצוגה מקדימה של הסחורה, כמו גם לביצוע הזמנה עבור הקונה
                        הוא בחינם.
                    </li>
                    <li>9.7. המידע שנמסר על ידי הקונה הינו חסוי. החנות המקוונת משתמשת במידע על הרוכש אך ורק לצורך
                        עיבוד ההזמנה, שליחת הודעות לקונה, אספקת סחורה, ביצוע סידורים הדדיים וכו'.
                    </li>
                    <p className="privacy-text">הליך החזרת סחורה באיכות טובה</p>
                    <li>10.1. החזרת הסחורה לחנות המקוונת מתבצעת בהתאם לחקיקה הנוכחית של אוקראינה.</li>
                    <p className="privacy-text">זמן חוזה</p>
                    <li>11.1. הסכם אלקטרוני נחשב כנחתם מרגע שהאדם ששלח את ההצעה לכריתת הסכם כזה מקבל תשובה על קבלת
                        הצעה זו באופן שנקבע בחלק השישי של סעיף 11 לחוק אוקראינה "על מסחר אלקטרוני".
                    </li>
                    <li>11.2. לפני תאריך התפוגה, ניתן לסיים הסכם זה בהסכמה הדדית של הצדדים עד לאספקת הסחורה בפועל,
                        באמצעות החזרת הכספים.
                    </li>
                    <p className="privacy-text">שימו לב שהחנות המקוונת "SPD Kotok I.M." באתר הרשמי<a
                        href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                        className="privacy-link">«https://newgo.ifurn.pro», </a>בעלת הזכות, בהתאם לחקיקה של
                        אוקראינה, להעניק את זכות השימוש בפלטפורמת האינטרנט ל-FLP ולישויות משפטיות למכירת סחורות.
                    </p></ul>
            </div>
        </div>
    );
};

export default He;