import React from 'react';

const Ru = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Политика безопасности передачи реквизитов платежной карты</h1>
                <p>При оплате заказа банковской картой обработка платежа (включая ввод номера карты) происходит на
                    защищенной странице процессинговой системы. Это означает, что Ваши конфиденциальные данные
                    (реквизиты карты, регистрационные данные и прочее) не поступают к нам, их обработка полностью
                    защищена, и никто не может получить персональные и банковские данные клиента.</p><p>При работе с
                    карточными данными применяется стандарт защиты информации, разработанный международными платёжными
                    системами Visa и MasterCard — Payment Card Industry Data Security Standard (PCI DSS), что
                    обеспечивает безопасную обработку реквизитов Банковской карты Держателя. Применяемая технология
                    передачи данных гарантирует безопасность по сделкам с Банковскими картами путем использования
                    протоколов Secure Sockets Layer (SSL).</p></div>
        </div>
    );
};

export default Ru;