import React from 'react';

const Lt = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Mokėjimo kortelės duomenų perdavimo saugumo politika</h1>
                <p>Atlikus užsakymo apmokėjimą banko kortele, mokėjimo apdorojimas (įskaitant kortelės numerio įvedimą) vyksta apsaugotoje apdorojimo sistemos puslapyje. Tai reiškia, kad Jūsų konfidencialūs duomenys (kortelės duomenys, registracijos informacija ir kt.) nepateką pas mus, jų apdorojimas yra visiškai saugus, ir niekas negali gauti asmens ar banko kliento duomenų.</p>
                <p>Darbe su kortelių duomenimis taikomas informacijos apsaugos standartas, kurį sukūrė tarptautinės mokėjimo sistemos Visa ir MasterCard - Mokėjimo kortelių pramonės duomenų saugumo standartas (PCI DSS), užtikrinantis saugų banko kortelės turėtojo duomenų tvarkymą. Taikoma duomenų perdavimo technologija garantuoja saugumą banko kortelėmis atliekamoms sandoriams naudojant Secure Sockets Layer (SSL) protokolus.</p>
            </div>
        </div>

    );
};

export default Lt;