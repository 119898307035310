import React from 'react';
import {useTranslation} from "react-i18next";


const Privacy = () => {
    const {t} = useTranslation();
    const path_key = ["pages_static", "privacy"]
    return (
        <div className={'info-pages-main-content'}>
            <h1>{t([...path_key, "h1"].join("."))}</h1>
            <p>{t([...path_key, "p"].join("."))}
                <a href="http://zakon2.rada.gov.ua/laws/show/2297-17"
                   target={'_blank'}>http://zakon2.rada.gov.ua/laws/show/2297-17</a>.
            </p>
            <h5>{t([...path_key, "list", "0", "title"].join("."))}</h5>
            <ul>
               <li> {t([...path_key, "list", "0", "items", "0"].join("."))}</li>
               <li> {t([...path_key, "list", "0", "items", "1"].join("."))}</li>
               <li> {t([...path_key, "list", "0", "items", "2"].join("."))}</li>
               <li> {t([...path_key, "list", "0", "items", "3"].join("."))}</li>
            </ul>

            <h5>{t([...path_key, "list", "1", "title"].join("."))}</h5>
            <ul>
                <li> {t([...path_key, "list", "1", "items", "0"].join("."))}</li>
                <li> {t([...path_key, "list", "1", "items", "1"].join("."))}</li>
                <li> {t([...path_key, "list", "1", "items", "2"].join("."))}</li>
                <li> {t([...path_key, "list", "1", "items", "3"].join("."))}</li>
                <li> {t([...path_key, "list", "1", "items", "4"].join("."))}</li>
                <li> {t([...path_key, "list", "1", "items", "5"].join("."))}</li>
                <li> {t([...path_key, "list", "1", "items", "6"].join("."))}</li>
            </ul>

            <h5>{t([...path_key, "list", "2", "title"].join("."))}</h5>
            <ul>
                <li> {t([...path_key, "list", "2", "items", "0"].join("."))}</li>
            </ul>
            <h5>{t([...path_key, "list", "3", "title"].join("."))}</h5>
            <ul>
                <li> {t([...path_key, "list", "3", "items", "0"].join("."))}</li>
            </ul>
            <h5>{t([...path_key, "list", "4", "title"].join("."))}</h5>
            <ul>
                <li> {t([...path_key, "list", "4", "items", "0"].join("."))}</li>
            </ul>
        </div>
    );
};

export default Privacy;