import React from 'react';
import OnlineService from "./OnlineService/OnlineService";
import CalculateInstantly from "./CalculateInstantly/CalculateInstantly";
import ProcessControl from "./ProcessControl/ProcessControl";
import WorkLess from "./WorkLess/WorkLess";
import StepConnect from "./StepConnect/StepConnect";
import FeedbackFormContainer from "./FeedbackForm/FeedbackFormContainer";

const HomeContainer = () => {
    // const isTermsUse = useSelector(state => state.appPage.isTermsUse);
    // const isConfirm = useSelector(state => state.appPage.confirm.isConfirm)
    return (
        <>
            <OnlineService/>
            <WorkLess/>
            <CalculateInstantly/>
            <ProcessControl/>
            <StepConnect/>
            {/*<Partners/>*/}
            {/*<ReviewsContainer/>*/}
            <FeedbackFormContainer/>
            {/*{isTermsUse && <TermsUse/>}*/}
            {/*{isConfirm && <Confirm/>}*/}
        </>
    )
}
export default HomeContainer;