import React from 'react';
import Form from "./Form";

const He = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list">
                <h4>הוראות למחיקת נתונים:</h4>
                <p>
                    אם אתה רוצה למחוק את הנתונים שלך מ
                   <a href="https://ifurn.pro/" className="" target={'_blank'}> ,iFurn.pro </a>
                     אנא ספק את פרטי הקשר שלך למחיקה ואת הסיבה.
                </p>
            </div>
            <Form/>

        </div>
    );
};

export default He;