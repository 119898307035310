import i18next from "i18next";
import {feedbackAPI, mainAPI, NewsApi} from "../api/api";
import {toast} from "react-toastify";
import {APP_PAGE_SIGN_UP_PROVIDER_SERVICES_MODAL, signUpProviderServicesModal} from "./app/app-actions";

const REWIEVS =  [
    {
        photos:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_LtWyOw-Dj_0lTVTUG4vsWxKCbmYvBK4I1A&usqp=CAU",
        userName: "Слава Комисаренко",
        description: "Подсадил всех партнеров с которыми работаю на iFurn и теперь довольствуюсь простотой" +
            " взаимодействия с компанией производителем. Работа стала намного проще и приносит в разы больше удовольствия.",
        activities: "Владелец сайта",
        company: "Mebel.ua",
        numberStars: 5

    },
    {
        photos: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7O4Mo3j_hsgRui4hZ1kX4k2JqMGREXqWk8Q&usqp=CAU",
        userName: "Антон Добрыш",
        description: "Я работаю семь дней в неделю, почти без выходных, поэтому для меня главное достоинство" +
            " iFurn — простота в привлечении клиентов. Подключил и сразу увидел, что заявок стало больше.",
        activities: "Директор",
        company: "Budmebil",
        numberStars: 3

    },
    {
        photos: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_LtWyOw-Dj_0lTVTUG4vsWxKCbmYvBK4I1A&usqp=CAU",
        userName: "Слава Комисаренко",
        description: "Меня привлекло то, что Рекламная подписка автоматически показывает объявления тем, кому" +
            " они интересны. Плюс не нужно платить отдельному человеку: настройки очень простые, всё в одном месте.",
        activities: "Владелец сайта",
        company: "Mebel.ua",
        numberStars: 5

    },
    {
        photos: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDrXjCYqH_tvyR5fKDJo5-OsVo01qLq9WHIg&usqp=CAU",
        userName: "Слава Комисаренко",
        description: "Подсадил всех партнеров с которыми работаю на iFurn и теперь довольствуюсь простотой" +
            " взаимодействия с компанией производителем. Работа стала намного проще и приносит в разы больше удовольствия.",
        activities: "Владелец сайта",
        company: "Mebel.ua",
        numberStars: 5

    }
]
const APP_SET_LOCATION = "APP/SET_LOCATION"
const APP_IS_TERMS_USE = "APP/IS_TERMS_USE"
const APP_CONFIRM = "APP/CONFIRM"
const APP_SUCCESSFUL_SEND_FEEDBACK_FROM = "APP/SUCCESSFUL_SEND_FEEDBACK_FROM";
const APP_SET_ALL_FIRMS = "APP/SET_ALL_FIRMS";
const APP_COMMERCIAL_OFFER_MODAL = 'APP/COMMERCIAL_OFFER_MODAL'
const APP_REQUEST_INVOICE_MODAL_DT = 'APP/REQUEST_INVOICE_MODAL_DT'
const APP_TOGGLE_LOADER = 'APP/TOGGLE_LOADER';
const APP_REQUEST_LOGIN = 'APP/REQUEST_LOGIN';
const NEWS_SET_LIST = "NEWS/NEWS_SET_LIST"

const NEWS_INITIAL_VALUES ={
    page: 0,
    pages: 0,
    news: []
}
let initialState = {
    locale: '',
    reviews:REWIEVS,
    isTermsUse: false,
    confirm: {
        status: "",
        isConfirm: false
    },
    allFirms:{
        count: '',
        data:[]
    },
    commercialOfferModal:{
        isOpen: false
    },
    payModal: {
        requestInvoice: {
            isOpen: false,
            isSuccessful: false,
            isProvider: true
        }
    },
    isLoader:false,
    news:NEWS_INITIAL_VALUES,
    signup_provider_services_modal:{
        isOpen: false
    }

};

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case APP_PAGE_SIGN_UP_PROVIDER_SERVICES_MODAL:{
            return {
                ...state,
                signup_provider_services_modal: {...action.payload}
            }
        }
        case APP_TOGGLE_LOADER:{
            return {
                ...state,
                isLoader: action.payload
            }
        }
        case APP_SET_LOCATION: {
            return {
                ...state,
                locale: action.locale
            }
        }
        case APP_SUCCESSFUL_SEND_FEEDBACK_FROM:{
            return{
                ...state
            }
        }
        case APP_IS_TERMS_USE:{
            return{
                ...state,
                isTermsUse: action.payload
            }
        }
        case APP_CONFIRM:{
            return{
                ...state,
                confirm: {...action.payload}
            }
        }
        case APP_SET_ALL_FIRMS:{
            return{
                ...state,
                allFirms: {...action.payload}
            }
        }
        case APP_COMMERCIAL_OFFER_MODAL:{
            return{
                ...state,
                commercialOfferModal: {...action.payload}
            }
        }
        case APP_REQUEST_INVOICE_MODAL_DT: {
            return {
                ...state,
                payModal: {
                    ...state.payModal,
                    [action.key]: action.dt
                }
            }
        }
        case NEWS_SET_LIST:{
            return {
                ...state,
                news:{
                    ...state.news,
                    ...action.payload
                }
            }
        }
        default:
            return state;
    }

}

export const onPayRequestInvoiceModal = (data) => {
    return {
        type: APP_REQUEST_INVOICE_MODAL_DT,
        key: data.key,
        dt: data.dt
    }
}
export const selLocal = (locale) => {
    return {
        type: APP_SET_LOCATION,
        locale
    }
}
export const setConfirm = (payload) => {
    return {
        type: APP_CONFIRM,
        payload

    }
}
export const setISTermUse = (payload) => {
    return {
        type: APP_IS_TERMS_USE,
        payload

    }
}

export  const setAllFirms = (payload)=>{
    return {
        type: APP_SET_ALL_FIRMS,
        payload
    }
}
export  const onCommercialOfferModalDT = (payload)=>{
    return {
        type: APP_COMMERCIAL_OFFER_MODAL,
        payload
    }
}

export const toggleLoader = (payload)=>{
    return{
        type: APP_TOGGLE_LOADER,
        payload: payload
    }
}
export const setNewsAC = (payload)=>{
    return{
        type: NEWS_SET_LIST,
        payload: payload
    }
}
export const getNewsThunk = (page=1)=> async dispatch=>{
    try{
        dispatch(toggleLoader(true))
        const response_news = await NewsApi.getNews(page);
        if(response_news.hasOwnProperty('error')){
            toast.error(response_news.error);
            // dispatch(setNewsAC(NEWS_INITIAL_VALUES));
            return
        }
        dispatch(setNewsAC(response_news));
        dispatch(toggleLoader(false))

    }catch (e) {
        dispatch(toggleLoader(false))
        dispatch(setNewsAC(NEWS_INITIAL_VALUES));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}

export const setAllFimsDT= ()=> async  dispatch=>{
    const response = await mainAPI.getAllFirms();
    try{
        dispatch(setAllFirms(response));
    }catch (e) {
        console.error(e.message)
    }
}
export const setGlobalLocation = (locale) => async  dispatch => {
    await i18next.changeLanguage(locale);
    dispatch(selLocal(locale))
}

export  const sendFeedbackForm = (data)=>async dispatch=>{
   let DT = await feedbackAPI.sendFeedbackForm(data);
   try{
       if(DT.hasOwnProperty('api_error')){
           dispatch(setConfirm({status: false, isConfirm: true}))
       }else {
           dispatch(setConfirm({status: true, isConfirm: true}));
           dispatch(signUpProviderServicesModal({isOpen: false}));
           toast.success(i18next.t("message.sign_up_provider_services"));
       }
   }catch (e) {
       dispatch(setConfirm({status: true, isConfirm: true}))
       console.error(e.messages)
   }
}

export const onSendPayForm = (text) => async dispatch =>{
    const response = await feedbackAPI.sendPay(text);
    try{
        if(response.hasOwnProperty('api_error')){
            dispatch(setConfirm({status: false, isConfirm: true}))
        }else {
            dispatch(onPayRequestInvoiceModal({key: 'requestInvoice', dt:{isOpen: true, isSuccessful: true}}))
        }
    }catch (e){

    }
}



// export const onLoginPlatform = (auth)=> async dispatch=>{
//     try{
//         dispatch(toggleLoader(true))
//         const response = await AuthAPI.login(auth);
//         console.log(response)
//         dispatch(toggleLoader(false))
//
//     }catch (e) {
//
//     }
// }