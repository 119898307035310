import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import stl from "./main.module.css";
import {useDispatch, useSelector} from "react-redux";
import CommercialOfferModal from "./CommercialOfferModal";
import {onCommercialOfferModalDT} from "../../../redux/app-reducer";
import {VideoWrap} from "../../VideoWrap";
import ifurnProImg from "../../../picture/image/ifurn_pro_home.jpg"
const OnlineService = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const local = useSelector(state => state.appPage.locale);
    const [isError, setIsError] = useState(false);
    const onOpenModalCommercialOffer = ()=>{
        dispatch(onCommercialOfferModalDT({isOpen: true}))
    }
    return (<>
            <div className="container" id={"scrollTop"}>

                <div className={stl.section_online_service}>
                    <div className="row">
                        <div className={stl.header}>
                          <div className="">
                              <h1 className=''>{t('online_service.title')}</h1>
                              <div className="commercial-offer-info">
                                  <p className=''>{t('online_service.description')}</p>
                              </div>
                          </div>
                           <div className="picture">
                            <img src={ifurnProImg}/>
                           </div>
                        </div>
                        <p className="text-center">  <a className={'clr-orange cursor-pointer'} onClick={onOpenModalCommercialOffer}> <strong>{t('orderService.title')}</strong> </a></p>

                        <div className={stl.content}>
                           <VideoWrap videoType={'provider'}/>
                        </div>

                    </div>
                </div>
            </div>
            <CommercialOfferModal/>
        </>
    )
}
export default OnlineService;