import React from 'react';

const He = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>מדיניות אבטחת העברת פרטי כרטיס תשלום</h1><p>בעת תשלום עבור
                הזמנה בכרטיס בנקאי, עיבוד התשלום (כולל הזנת מספר הכרטיס) מתבצע בדף מאובטח של מערכת העיבוד. המשמעות היא
                שהנתונים הסודיים שלך (פרטי כרטיס, נתוני רישום וכו') אינם מגיעים אלינו, העיבוד שלהם מוגן לחלוטין, ואף אחד
                לא יכול לקבל את הנתונים האישיים והבנקאיים של הלקוח.</p><p>בעבודה עם נתוני כרטיסים נעשה שימוש בתקן אבטחת
                המידע שפותח על ידי מערכות התשלומים הבינלאומיות ויזה ומסטרקארד - Payment Card Industry Data Security
                Standard (PCI DSS) המבטיח עיבוד מאובטח של פרטי הכרטיס הבנקאי של המחזיק. טכנולוגיית העברת הנתונים המיושמת
                מבטיחה את אבטחת העסקאות עם כרטיסי בנק באמצעות שימוש בפרוטוקולי Secure Sockets Layer (SSL).</p></div>
        </div>
    );
};

export default He;