import {findErrorResponse, rejectTransform, transformAuthData, transformAuthDataClient} from "./utils";
import {LS_CLIENT_TOKEN} from "../consts/localStorageNaming";
import {instances} from "./api";

const AuthAPI = {
    login({email = '', password = ''}) {
        return instances.get(`users/auth/?email=${email}&pass=${password}`).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }).catch(error => rejectTransform(error))
    },
    verification(firm = '') {
        return instances.get(`authn?firm=${firm}`).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }).catch(error => rejectTransform(error))
    },
    getUser(token) {
        return instances.post(`authn`, {
            token: token,
            user_token: localStorage.getItem(LS_CLIENT_TOKEN) || ''
        }, {
            headers: {'Point-Action': 'token'}
        }).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            let transform = response.data.hasOwnProperty('client') && response.data.client
                ? transformAuthDataClient(response.data) : response.data;
            return !error ? transform : {error: error}
        }).catch(error => rejectTransform(error))
    },
    logout() {
        return instances.delete('authn').then(res => res.data).catch(e => Promise.reject(e))
    },
    resetPassword({client_id = "", login}) {
        return instances.post(`registration/${client_id}`, {
            action: "forgot_password",
            login: login
        }).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }).catch(error => {
            return rejectTransform(error)
        })
    },
    registration({formData = null}) {
        return instances.post(`authi/registration`, formData).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }).catch(error => {
            return rejectTransform(error)
        })
    },
    getAuthDataForManager({user_id = null}) {
        if (!user_id) return Promise.reject('user id null');
        return instances.get(`users/${user_id}`)
            .then(res => {
                let response = res.data;
                let error = findErrorResponse(response);
                return !error ? transformAuthData({...response.user_data, firm_urls: response.firm_urls}) : {error: error}
            }).catch(error => rejectTransform(error))
    },
    authLookup(email_or_phone = null) {
        if (!email_or_phone) return Promise.reject('Login field empty');
        return instances.post(`authi/lookup`, email_or_phone)
            .then(res => {
                let response = res.data;
                let error = findErrorResponse(response);
                return !error ? response : {error: error}
            }).catch(error => rejectTransform(error))
    },
    getAuthFirms(from = null) {
        const formData = new FormData()
        formData.append("from", from)
        return instances.post('authi/get-firms', formData).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        })
            .catch(error => rejectTransform(error))
    },
    //** редактировать данные доступно только для клиента */
    updateUserData(api_data) {
        if (!Object.keys(api_data)) return Promise.reject('api_data empty');
        let id = api_data.hasOwnProperty('client') && api_data.client ? api_data.client : api_data.user_id;
        let uri = `clients/${id}`
        return instances.put(uri, api_data).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        })
            .catch(error => rejectTransform(error))
    },


}
export {AuthAPI}