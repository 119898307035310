import React from 'react';

const Lt = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Pinigų grąžinimo sąlygos:</h1>
                <ul>
                    <li>1. Pinigai grąžinami per 14 dienų.</li>
                    <li>2. Pinigai grąžinami tik už pirmą kartą užsakytas paslaugas. Pavyzdžiui: jeigu jūs užsakėte
                        prenumeratą, gavote pinigų grąžinimą, o tada iš naujo užsakėte paslaugą, jūs negausite pinigų
                        atgal už antrą užsakymą.
                    </li>
                    <li>3. Pinigų grąžinimas atliekamas atsižvelgiant į mokėjimo sistemų komisiją.</li>
                    <li>4. Mokant VISA ir MasterCard kortelėmis, pinigai grąžinami į tą pačią kortelę, kuriuo buvo
                        atliktas mokėjimas. Po to, kai mes grąžinsime mokėjimą, pinigai atsiras kortelėje per 3 banko
                        dienas. Atkreipkite dėmesį, kad šis mokėjimas neatsiranda kortelės išraše, o tiesiog padidėja suma
                        kortelėje. Tokio tipo grąžinimas galimas tik per 30 dienų nuo mokėjimo datos.
                    </li>
                    <li>5. Pinigai grąžinami taipomis sąskaitos duomenimis, kuriomis buvo atliktas paslaugų apmokėjimas.</li>
                    <li>6. Mokėjimo grąžinimas vykdomas per 10 dienų nuo paraiškos pateikimo momento.</li>
                    <li>7. Grąžinant, atimami visi bonusai ir nuolaidos.</li>
                </ul>
            </div>
        </div>

    );
};

export default Lt;