import React, {useCallback, useMemo} from 'react';
import Card from "../../ui/Card";
import {LoginSocialFacebook, LoginSocialGoogle} from "reactjs-social-login";
import {useDispatch, useSelector} from "react-redux";
import {
    accountConnectedFacebook,
    accountConnectedGoogle,
    deleteNetworkLoginSocial
} from "../../../redux/auth/auth-thunk";
import {toast} from "react-toastify";
import {networkLoginSelected} from "../../../redux/auth/auht-selector";
import Loader from "../../Loader";
import IconGoogle from "../../../assets/Icons/IconGoogle";
import IconFb from "../../../assets/Icons/IconFb";
import IconTrash from "../../../assets/Icons/IconTrash";
import {useTranslation} from "react-i18next";
import {helpArticleGetByIdToggleModalThunk} from "../../../redux/helpl-aricle/help-article-thunk";
import {HELP_ARTICLE_GET_BY_ID_TOGGLE_MODAL} from "../../../redux/helpl-aricle/help-article-action";
import IconQuestion from "../../../picture/icon/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../../redux/helpl-aricle/help-article-constants";

const CONNECTED_DEFAULT_ICONS = {
    google: IconGoogle,
    fb: IconFb
}

const SocialNetwork = () => {
    const {t} = useTranslation();
    const network_login = useSelector(networkLoginSelected)
    const onLoginStart = useCallback(() => {
    }, [])
    const dispatch = useDispatch();
    const isNotConnected = useMemo(() => {
        if (!network_login.list || (Array.isArray(network_login.list) && !network_login.list.length)) {
            return {
                google: true,
                fb: true
            }
        }
        let google = !network_login.list.find(item => item.name === 'google');
        let fb = !network_login.list.find(item => item.name === 'fb');
        return {
            google: google,
            fb: fb
        }
    }, [network_login.list]);
    const handlerDeleteConnected = (item) => {
        let api_data = {
            network: item.name,
            id: item.id
        }
        dispatch(deleteNetworkLoginSocial(api_data))
    }
    const handlerHelpArticle = (e)=>{
        e.stopPropagation();
        e.preventDefault();

        dispatch(helpArticleGetByIdToggleModalThunk(HELP_ARTICLE_CONSTANTS_IDS.login_social_networks))
    }

    return (
        <div className="" key={'social-network'}>

            <Card label={ <>
                {t('social_title')}
                <IconQuestion onClick={handlerHelpArticle} style={{marginLeft: '5px'}}/>

            </>
                } isCollapse={true} key={'social-network-card'}>
                <ul className={'list-none'}>
                    {!!(network_login.list && (Array.isArray(network_login.list) && network_login.list.length)) &&
                        <>
                            {
                                network_login.list.map(item => {
                                    let Icon = CONNECTED_DEFAULT_ICONS.hasOwnProperty(item.name) ? CONNECTED_DEFAULT_ICONS[item.name] : null;
                                    return <li className={'d-flex align-items-center justify-between'}>
                                    <span className="d-flex align-items-center">
                                    {Icon && <Icon width={'25px'} height={'25px'} style={{marginRight: '8px'}}/>}
                                        {item.name}
                                    </span>
                                        <span className="d-flex align-items-center">
                                        <span className="clr-green  fs-12 ">{t("connected_own_title")}</span>
                                          <IconTrash onClick={() => {
                                              handlerDeleteConnected(item)
                                          }}/>
                                    </span>

                                    </li>

                                })
                            }
                        </>
                    }
                    {isNotConnected.google && <li> <LoginSocialGoogle key={'login-social-google'}
                                                                 client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                                 isOnlyGetToken={true}
                                                                 isOnlyGetCode={true}
                                                                 auto_select={false}
                                                                 scope={'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'}
                                                                 cookie_policy={false}
                                                                 onLoginStart={onLoginStart}
                                                                 onResolve={async ({provider, data}) => {
                                                                     dispatch(accountConnectedGoogle(data.access_token))
                                                                 }}
                                                                 onReject={(err) => {
                                                                     console.log(err)
                                                                     toast.error(err);
                                                                 }}>
                        <div className="d-flex align-items-center d-flex cursor-pointer clr-blue text-underline fs-14">
                            <CONNECTED_DEFAULT_ICONS.google width={'25px'} height={'25px'}
                                                            style={{marginRight: '8px'}}/> {t("to_plug__title")}
                        </div>
                        {/*<GoogleLoginButton text={"Google"} key={'login-social-google-btn'}/>*/}
                    </LoginSocialGoogle> </li>}
                    {
                        isNotConnected.fb && <li> <LoginSocialFacebook
                            fieldsProfile={"id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"}
                            fields="first_name, last_name, email, picture, birthday"
                            scope="public_profile,email,user_birthday"
                            returnScopes={true}
                            appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                            onResolve={(response) => {
                                dispatch(accountConnectedFacebook({id: response.data.id, network: 'fb'}))
                            }}
                            onReject={(error) => {
                                console.log(error);
                            }}
                        >
                            <div className="d-flex align-items-center d-flex cursor-pointer clr-blue text-underline fs-14">
                                <CONNECTED_DEFAULT_ICONS.fb width={'25px'} height={'25px'}
                                                            style={{marginRight: '8px'}}/> {t("to_plug__title")}
                            </div>
                            {/*<FacebookLoginButton text={"Facebook"}/>*/}
                        </LoginSocialFacebook></li>
                    }
                </ul>

            </Card>
            {network_login.isLoading && <Loader key={'network_loader'}/>}
        </div>

    );
};

export default SocialNetwork;