import {useTranslation} from "react-i18next";
import React, {useCallback} from "react";
import {scroller} from "react-scroll/modules";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setISTermUse} from "../../redux/app-reducer";
import {PAGES_HASH_IDS, TAB_HASH_IDS} from "../../routing_hash";
import IconTg from "../../assets/Icons/IconTg";
import IconYoutube from "../../assets/Icons/IconYoutube";
import logoImg from "../../assets/images/ifurn_logo.svg"
import Logo from "../../picture/icon/Logo";

const FooterContainer = ({switchTabAndScrolledSection, switchTab, currentTab}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const scrollTarget = (target) => scroller.scrollTo(target, {smooth: true, duration: 700, offset: -100});
    const scrollToPage = async (target) => {
        if (history.location.pathname !== '/') {
            await history.push('/');
        }
        scrollTarget(target);
    };
    const onHandlerSwitchScrolledSection = useCallback((tab, id_section) => () => {
        switchTabAndScrolledSection(tab, id_section)
    }, [switchTabAndScrolledSection]);
    const onHandlerSwitchPage = useCallback((tab) => () => {
        switchTab(tab)
    }, [switchTab]);
    return (
        <footer className={""}>
            <div className="container" style={{padding: "0px 15px"}}>
                <div className="footerTop">
                    <div className="navigation ">
                        <div className="w-100 d-flex">
                            <div className="logo" onClick={onHandlerSwitchScrolledSection(TAB_HASH_IDS.home, 'body')}>
                                {/*<img src={logoImg} alt=""/>*/}
                                <Logo key={'icon-logo'} width={120}/>
                            </div>
                            <nav className={'nav-hash'}>
                                <li onClick={onHandlerSwitchScrolledSection(TAB_HASH_IDS.home, 'workLess')}>{t('footer.nav.useful')}</li>
                                <li onClick={onHandlerSwitchScrolledSection(TAB_HASH_IDS.home, 'stepConnect')}>{t('footer.nav.connect')}</li>
                                {/*<li onClick={() => scrollToPage('reviews')}>{t('footer.nav.reviews')}</li>*/}
                            </nav>
                        </div>

                        <div className="w-100 nav-pages">
                            <nav>
                                {Object.entries(PAGES_HASH_IDS).filter(([key, value])=>value !== PAGES_HASH_IDS.deleteUser).map(([key, value]) => {
                                    return <li className={`${currentTab === value ? 'active' :''}`}
                                        onClick={onHandlerSwitchPage(PAGES_HASH_IDS[key])}>{t(["footer", "pages", value].join('.'))}</li>
                                })}
                                {/*// return <li onClick={onHandlerSwitchScrolledSection(value, '')}>{t(["footer", "pages", value].join('.'))}</li>})}*/}

                                {/*<li onClick={() => scrollToPage('reviews')}>{t('footer.nav.reviews')}</li>*/}
                            </nav>
                        </div>

                    </div>
                    <ul className="contact">
                        <li className={"fs-16"}>{t('footer.contact_info.work_time')}</li>
                        {/*<li className={"fs-16"}>{t('pl_title')}</li>*/}
                        <li className={"fs-16"}><a
                            href={`tell:+380930888709`}>
                            +38 (093) 088-87-09
                            {/*{t('footer.contact_info.phone')}*/}
                            <small> (telegram) </small></a>
                        </li>
                        {/*<li className={"fs-16"}><a href={`tell:+380952705866`}>+38 (095) 270-58-66</a></li>*/}
                        <li className={"fs-16"}><a
                            href={`mailto:${t('footer.contact_info.email')}`}>{t('footer.contact_info.email')}</a>
                        </li>
                        <li className={"street"}>{t('footer.contact_info.address')}</li>
                    </ul>
                </div>
                <div className="footerBottom">
                    <div className="developers">
                        <span>  © iFurn 2022   </span>
                        {/*<span style={{cursor: "pointer"}}*/}
                        {/*      onClick={() => dispatch(setISTermUse(true))}>{t('footer.terms_use')}</span>*/}
                    </div>
                    <nav className="social-link">
                        <li>
                            <a href="https://t.me/ifurn_pro" target="_blank">
                                <IconTg key={'icon-tg'} style={{maxWidth: '25px'}}/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCTyj-f51ZBqMqrAc6Y7V7Lw" target="_blank">
                                <IconYoutube key={'icon-youtube'} style={{maxWidth: '25px'}}/>
                            </a>
                        </li>
                    </nav>
                </div>

            </div>
        </footer>
    )
}
export default FooterContainer;