import React from 'react';

const Sk = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list">
                <h4>Inštrukcie pre odstránenie údajov:</h4>
                <p>Ak chcete odstrániť svoje údaje z <a href="https://ifurn.pro/" className="" target={'_blank'}> iFurn.pro</a>,
                    prosím, uveďte svoje kontaktné údaje na odstránenie a dôvod.</p>
            </div>
        </div>

    );
};

export default Sk;