import React from 'react';

const Ua = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Політика безпеки передачі реквізитів платіжної картки</h1>
                <p>При оплаті замовлення банківською картою обробка платежу (включно з введенням номера картки)
                    відбувається на захищеній сторінці процесингової системи. Це означає, що Ваші конфіденційні дані
                    (реквізити картки, реєстраційні дані та інше) не надходять до нас, їхня обробка повністю захищена, і
                    ніхто не може отримати персональні та банківські дані клієнта.</p><p>Під час роботи з картковими
                    даними застосовується стандарт захисту інформації, розроблений міжнародними платіжними системами
                    Visa та MasterCard – Payment Card Industry Data Security Standard (PCI DSS), що забезпечує безпечну
                    обробку реквізитів Банківської картки Держателя. Технологія передачі даних, що застосовується,
                    гарантує безпеку за угодами з Банківськими картами шляхом використання протоколів Secure Sockets
                    Layer (SSL).</p></div>
        </div>
    );
};

export default Ua;