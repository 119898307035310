import {instances} from "./api";
import {findErrorResponse, rejectTransform} from "./utils";

export const AccountAPI = {
    getMessageChannels (){
        return instances.get('account/message-channels').then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }).catch(error => rejectTransform(error))
    },
    setDefaultMessageChannel(form_data){
        return instances.post('account/set-default-message-channel', form_data).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }).catch(error => rejectTransform(error))
    },
    toggleEnabledMessageChannel(form_data){
        return instances.post('account/set-enabled-message-channel', form_data).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }).catch(error => rejectTransform(error))
    },
    confirmationMessageChannel(form_data) {
        let uri = `account/confirm-message-channel`
        // if (message_channel) {
        //     uri += `&message_channel=${message_channel}`
        // }
        // if (password) {
        //     uri += `&password=${password}`
        // }
        return instances.post(uri, form_data).then(res => {
            let response = res.data;
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        })
            .catch(error => rejectTransform(error))
    }
}