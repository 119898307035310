import React from "react";
import {useTranslation} from "react-i18next";
import img from "../../../picture/image/circle_violet.png"
import FeedbackForm from "./FeedbackForm";
import {useDispatch} from "react-redux";
import {sendFeedbackForm, setISTermUse} from "../../../redux/app-reducer";
const FeedbackFormContainer = ()=>{
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const onSubmitForm = (data)=>{
        dispatch(sendFeedbackForm(data))
    }
    return(
        <div className="section_feedback" id={'callback'}>
            <img src={img} alt="" className={"img"}/>
            <div className="container">
                <div className="row">
                    <div className="content">
                    <h3 className={"h2 text-center"}>{t('feedback_form.title')}</h3>
                    <p className={"text-center"}>{t('feedback_form.description')}</p>
                        <FeedbackForm onSubmitForm={onSubmitForm}/>
                        <div className="form-feedback">
                            <div className="agree_terms" onClick={()=> dispatch(setISTermUse(true))}>
                                {t('feedback_form.agree_terms.text_one')}  <span className="clr-orange">{t('feedback_form.agree_terms.link_one')} </span>
                                {t('feedback_form.agree_terms.text_two')}  <span className="clr-orange">{t('feedback_form.agree_terms.link_two')} </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
export default FeedbackFormContainer;