import React from 'react';

const Et = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Pangakaardi andmete edastamise turvapoliitika</h1>
                <p>Tellimuse maksmisel pangakaardiga toimub makse töötlemine (sh kaardi numbri sisestamine) turvatud
                    töötlussüsteemi lehel. See tähendab, et teie konfidentsiaalsed andmed (kaardi andmed,
                    registreerimisandmed jne) ei jõua meieni, nende töötlemine on täielikult kaitstud ja keegi ei saa
                    kliendi isiklikke ja pangandusalaseid andmeid kätte.</p>
                <p>Kui töödeldakse kaardiandmeid, kohaldatakse rahvusvaheliste maksesüsteemide Visa ja MasterCard
                    väljatöötatud informatsiooni kaitse standardit – Payment Card Industry Data Security Standard (PCI
                    DSS), tagades Pangakaardi Omaniku andmete turvalise töötlemise. Andmete edastamise tehnoloogia tagab
                    tehingute turvalisuse pangakaartidega, kasutades turvaprotokolle Secure Sockets Layer (SSL).</p>
            </div>
        </div>

    );
};

export default Et;