import React from 'react';

const De = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Bedingungen für die Rückerstattung:</h1>
                <ul>
                    <li>1. Die Rückerstattung erfolgt innerhalb von 14 Tagen.</li>
                    <li>2. Die Rückerstattung erfolgt nur für erstmalig bestellte Dienstleistungen. Zum Beispiel: Wenn Sie ein
                        Abonnement bestellt haben, dann eine Rückerstattung erhalten und erneut ein Abonnement bei uns
                        bestellt haben, erhalten Sie kein Geld für die zweite Bestellung zurück.
                    </li>
                    <li>3. Die Rückerstattung erfolgt unter Abzug von Gebühren der Zahlungssysteme.</li>
                    <li>4. Bei Zahlungen mit VISA und MasterCard werden die Gelder auf dieselbe Karte zurückerstattet, von der
                        die Zahlung getätigt wurde. Nachdem wir die Zahlung zurückerstattet haben, erscheinen die Gelder
                        innerhalb von 3 Banktagen auf der Karte. Beachten Sie, dass diese Zahlung nicht auf den Kontoauszügen
                        erscheint, sondern einfach der Kartensumme hinzugefügt wird. Diese Art der Rückerstattung ist nur
                        innerhalb von 30 Tagen nach der Zahlung möglich.
                    </li>
                    <li>5. Das Geld wird auf die gleichen Kontodaten zurückerstattet, von denen aus die Zahlung für die
                        Dienstleistungen erfolgt ist.
                    </li>
                    <li>6. Die Rückerstattung der Zahlung erfolgt innerhalb von 10 Tagen nach Einreichung des Antrags.</li>
                    <li>7. Bei der Rückerstattung werden alle Boni und Rabatte abgezogen.</li>
                </ul>
            </div>
        </div>

    );
};

export default De;