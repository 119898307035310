import React from 'react';

const Pl = () => {
    return (
            <div className="info-pages-main-content">
                <div className="info-page-inner-content"><h2 className="privacy-title"> Umowa oferty publicznej</h2><p
                    className="privacy-text">Postanowienia ogólne</p>
                    <ul>
                        <li>1.1. Niniejsza oferta jest oficjalną ofertą FOP Kotok I.M., zwanego dalej „Sprzedawcą”,
                            zawarcia Umowy sprzedaży towarów na odległość, to jest za pośrednictwem sklepu
                            internetowego, zwanej dalej „Umową”, oraz miejsc oferta publiczna (propozycja) na oficjalnej
                            stronie internetowej Sprzedającego<a href="https://ifurn.pro" target="_blank"
                                                                 title="ifurn.pro"
                                                                 className="privacy-link">«https://ifurn.pro», </a><a
                                href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                                className="privacy-link">«https://newgo.ifurn.pro», </a><a href="https://crm.ifurn.pro"
                                                                                           target="_blank"
                                                                                           title="crm.ifurn.pro"
                                                                                           className="privacy-link">«https://crm.ifurn.pro»</a>(zwany
                            dalej „Serwisem”).
                        </li>
                        <li> 1.2. Momentem pełnego i bezwarunkowego przyjęcia przez Kupującego oferty Sprzedającego
                            (akceptacji) zawarcia elektronicznej umowy sprzedaży towaru jest fakt, że Kupujący opłaci
                            zamówienie na warunkach niniejszej Umowy, terminowo i po cenach wskazane na stronie
                            internetowej Sprzedającego.
                        </li>
                        <li> 2.1. W niniejszej ofercie, o ile z kontekstu nie wynika inaczej, poniższe terminy mają
                            następujące znaczenie:
                        </li>
                        <ul>
                            <li>* „towary” – elementy mebli, półprodukty, akcesoria, komponenty i przedmioty
                                towarzyszące;
                            </li>
                            <li>* "Sklep internetowy" - zgodnie z Ustawą Ukrainy "o handlu elektronicznym", środek do
                                prezentacji lub sprzedaży produktu, pracy lub usługi poprzez dokonanie transakcji
                                elektronicznej.
                            </li>
                            <li>* „Sprzedawca” – firma zajmująca się sprzedażą towarów prezentowanych w serwisie
                                internetowym.
                            </li>
                            <li>* „Kupujący” – osoba fizyczna, która zawarła Umowę ze Sprzedawcą na warunkach
                                określonych poniżej.
                            </li>
                            <li>* „Zamówienie” – wybór poszczególnych pozycji z listy towarów określonej przez
                                Kupującego podczas składania zamówienia i dokonywania płatności.
                            </li>
                        </ul>
                        <p className="privacy-text">Przedmiot Umowy</p>
                        <li>3.1. Sprzedawca zobowiązuje się do przeniesienia Towaru na własność Kupującego, a Kupujący
                            zobowiązuje się do zapłaty i odbioru Towaru na warunkach niniejszej Umowy.
                        </li>
                        <ul>Niniejsza Umowa reguluje zakup i sprzedaż towarów w sklepie internetowym, w tym:
                            <li> - dobrowolny wybór przez Kupującego towaru w sklepie internetowym;</li>
                            <li> - samodzielna rejestracja przez Kupującego zamówienia w sklepie internetowym;</li>
                            <li> - opłacenie przez Kupującego zamówienia złożonego w sklepie internetowym;</li>
                            <li> - przetwarzanie i dostawa zamówienia do Kupującego w nieruchomości na warunkach
                                niniejszej Umowy.
                            </li>
                        </ul>
                        <p className="privacy-text">Jak złożyć zamówienie</p>
                        <li>4.1. Kupujący ma prawo złożyć zamówienie na dowolny produkt prezentowany na Stronie Sklepu
                            internetowego i dostępny.
                        </li>
                        <li>4.2. Każda pozycja może być zaprezentowana w zamówieniu w dowolnej ilości.</li>
                        <li>4.3. W przypadku braku towaru na magazynie, Kierownik firmy zobowiązany jest do
                            powiadomienia Kupującego (telefonicznie lub za pośrednictwem poczty e-mail).
                        </li>
                        <li>4.4. W przypadku braku produktu Kupujący ma prawo wymienić go na produkt podobnego modelu,
                            odmówić przyjęcia tego produktu, anulować zamówienie.
                        </li>
                        <p className="privacy-text">Polecenie zapłaty&nbsp;</p><p className="privacy-text">Płatność
                        bankowa.&nbsp;</p>
                        <li>5.1. Płatność dokonywana jest na konto Sprzedającego. Dostawa towarów przez Sprzedawcę do
                            Kupującego jest realizowana po opłaceniu faktury w sposób określony w warunkach dostawy, pkt
                            6.1, 6.2.
                        </li>
                        <li>5.2. W przypadku nieotrzymania środków sklep internetowy zastrzega sobie prawo do anulowania
                            zamówienia.
                        </li>
                        <p className="privacy-text">Warunki dostawy zamówienia&nbsp;</p>
                        <li>6.1. Dostawa towarów zakupionych w sklepie internetowym realizowana jest poprzez samodzielną
                            dostawę z adresu wskazanego na fakturze, przez firmę transportową lub w inny sposób
                            uzgodniony przez Kupującego ze Sprzedającym.
                        </li>
                        <li>6.2. Wraz z zamówieniem Kupującemu dostarczane są dokumenty zgodne z ustawodawstwem
                            Ukrainy.
                        </li>
                        <p className="privacy-text">Prawa i obowiązki stron:&nbsp;</p>
                        <li>7.1. Sprzedawca ma prawo:</li>
                        <ul>
                            <li>- jednostronnie zawiesić świadczenie usług na podstawie niniejszej umowy w przypadku
                                naruszenia przez Kupującego warunków niniejszej umowy.
                            </li>
                        </ul>
                        <li>7.2. Kupujący zobowiązany jest:</li>
                        <ul>
                            <li>- terminowe opłacanie i odbieranie zamówienia na warunkach niniejszej umowy.</li>
                        </ul>
                        <li>7.3. Kupujący ma prawo:</li>
                        <ul>
                            <li>- złożyć zamówienie w sklepie internetowym;</li>
                            <li>- sporządzić umowę elektroniczną;</li>
                            <li>- wymagać od Sprzedającego spełnienia warunków niniejszej Umowy.</li>
                        </ul>
                        <p className="privacy-text">Odpowiedzialność stron&nbsp;</p>
                        <li>8.1. Strony ponoszą odpowiedzialność za niewykonanie lub nienależyte wykonanie warunków
                            niniejszej umowy w sposób określony w niniejszej umowie i obowiązującym ustawodawstwie
                            Ukrainy.
                        </li>
                        <li>8.2. Sprzedawca nie ponosi odpowiedzialności za:</li>
                        <ul>
                            <li>- wygląd Towaru zmieniony przez producenta;</li>
                            <li>- za niewielką rozbieżność w gamie kolorystycznej produktu, która może różnić się od
                                oryginalnego produktu wyłącznie ze względu na odmienne odwzorowanie kolorów monitorów
                                komputerów osobistych poszczególnych modeli;
                            </li>
                            <li>- za treść i prawdziwość informacji podanych przez Kupującego podczas składania
                                zamówienia;
                            </li>
                            <li>- za opóźnienia i przerwy w świadczeniu Usług (realizacja zamówienia i dostawa towarów)
                                powstałe z przyczyn od niego niezależnych;
                            </li>
                            <li>- za bezprawne działania niezgodne z prawem dokonane przez Kupującego z wykorzystaniem
                                tego dostępu do sieci Internet;
                            </li>
                            <li>- o przekazanie przez Kupującego swoich identyfikatorów sieciowych - IP, adresu MAC,
                                loginu i hasła osobom trzecim;
                            </li>
                        </ul>
                        <li>8.3. Kupujący, korzystając z udostępnionego mu dostępu do sieci Internet, ponosi wyłączną
                            odpowiedzialność za szkody wyrządzone swoim działaniem (osobiście, nawet jeśli pod jego
                            loginem była inna osoba) osobom lub ich mieniu, osobom prawnym, państwowym lub moralnym
                            zasadom moralność.
                        </li>
                        <li>8.4. W przypadku wystąpienia siły wyższej strony są zwolnione z warunków niniejszej umowy.
                            Siła wyższa w rozumieniu niniejszej umowy oznacza zdarzenia o nadzwyczajnym,
                            nieprzewidzianym charakterze, wykluczające lub obiektywnie zakłócające wykonanie niniejszej
                            umowy, których zaistnieniu Strony nie mogły przewidzieć i zapobiec rozsądnymi środkami.
                        </li>
                        <li>8.5. Strony dołożą wszelkich starań, aby wszelkie rozbieżności rozwiązywać wyłącznie w
                            drodze negocjacji.
                        </li>
                        <p className="privacy-text">Inne warunki&nbsp;</p>
                        <li>9.1. Sklep internetowy zastrzega sobie prawo do jednostronnej zmiany niniejszej umowy, pod
                            warunkiem jej uprzedniej publikacji w serwisie.<a href="https://newgo.ifurn.pro"
                                                                              target="_blank" title="newgo.ifurn.pro"
                                                                              className="privacy-link">«https://newgo.ifurn.pro» </a>
                        </li>
                        <li>9.2. Sklep internetowy powstał w celu zorganizowania zdalnej metody sprzedaży towarów przez
                            Internet.
                        </li>
                        <li>9.3. Kupujący odpowiada za prawdziwość informacji podanych podczas składania zamówienia.
                            Jednocześnie dokonując odbioru (złożenia zamówienia i późniejszej zapłaty za towar) Kupujący
                            udziela Sprzedającemu bezwarunkowej zgody na zbieranie, przetwarzanie, przechowywanie,
                            wykorzystywanie jego danych osobowych w rozumieniu art. Ustawa Ukrainy „O ochronie danych
                            osobowych”.
                        </li>
                        <li>9.4. Zapłata przez Kupującego zamówienia złożonego w sklepie internetowym oznacza pełną
                            zgodę Kupującego na warunki umowy sprzedaży (oferta publiczna)
                        </li>
                        <li>9.5. Faktyczną datą zawarcia umowy elektronicznej pomiędzy stronami jest data akceptacji
                            warunków, zgodnie z art. 11 Ustawy Ukrainy „O handlu elektronicznym”
                        </li>
                        <li>9.6. Korzystanie z zasobu sklepu internetowego w celu podglądu towaru, jak również złożenia
                            zamówienia dla Kupującego jest bezpłatne.
                        </li>
                        <li>9.7. Informacje podane przez Kupującego są poufne. Sklep internetowy wykorzystuje informacje
                            o Kupującym wyłącznie w celu realizacji zamówienia, wysłania powiadomienia do Kupującego,
                            dostarczenia towaru, dokonania wzajemnych rozliczeń itp.
                        </li>
                        <p className="privacy-text">Procedura zwrotu towaru dobrej jakości</p>
                        <li>10.1. Zwrot towaru do sklepu internetowego odbywa się zgodnie z obowiązującym ustawodawstwem
                            Ukrainy.
                        </li>
                        <p className="privacy-text">Czas kontraktu</p>
                        <li>11.1. Umowę elektroniczną uważa się za zawartą z chwilą, gdy osoba, która wysłała ofertę
                            zawarcia takiej umowy, otrzyma odpowiedź o przyjęciu tej oferty w sposób określony w części
                            szóstej art. 11 ustawy Ukrainy „O handlu elektronicznym”.
                        </li>
                        <li>11.2. Przed terminem wygaśnięcia niniejsza Umowa może zostać rozwiązana za obopólną zgodą
                            stron do czasu faktycznego dostarczenia towaru, poprzez zwrot środków.
                        </li>
                        <p className="privacy-text">Informujemy, że sklep internetowy „SPD Kotok I.M.” na oficjalnej
                            stronie internetowej<a href="https://newgo.ifurn.pro" target="_blank"
                                                   title="newgo.ifurn.pro"
                                                   className="privacy-link">«https://newgo.ifurn.pro», </a>ma prawo,
                            zgodnie z ustawodawstwem Ukrainy, przyznać prawo do korzystania z platformy internetowej FLP
                            i osobom prawnym w celu sprzedaży towarów.</p></ul>
                </div>
            </div>
    );
};

export default Pl;