import React, {useCallback, useEffect} from 'react';
import {utf8ToB64} from "../../../../../utils/common";
import {authThunkGetRegisterClientsFirms} from "../../../../../redux/firms-thunk";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {clientsFirmsSelected} from "../../../../../redux/auth/auht-selector";
import Loader from "../../../../Loader";
import Button from "../../../../ui/Button";
import {useFormikContextProviderRegister} from "../Registration";

const StepChoiceFirms = () => {
    const {t} = useTranslation()
    const clientsFirms = useSelector(clientsFirmsSelected);
    const {values, handleChange, setFieldValue, submitForm} = useFormikContextProviderRegister();

    const onChangeHandlerConnectionFirm = useCallback((firm_id) => () => {
        let update = [...values.firms];
        if (update.includes(firm_id)) {
            setFieldValue('firms', update.filter(id => id !== firm_id));
            return
        }
        update.push(firm_id)
        setFieldValue('firms', [...update])
    });
    if (clientsFirms.isLoading) {
        return <Loader/>
    }

    return (<div className={'choice-firm-register'}>
        <h5 className={'form-sub-title'}>{t("connect_firm_title")}</h5>
        {clientsFirms.entry_firm && clientsFirms.entry_firm.hasOwnProperty('not_connected') &&
        Array.isArray(clientsFirms.entry_firm.not_connected) && clientsFirms.entry_firm.not_connected.length ? <>
                <div className="form-group">
                    <ul className={'list-none '}>
                        {clientsFirms.entry_firm.not_connected.map(firm => {
                            return <li className={'filed-checkbox '}>
                                <input type="checkbox" id={`firm_${firm.id}`}
                                       checked={values.firms.includes(firm.id)}
                                       onChange={onChangeHandlerConnectionFirm(firm.id)}
                                />
                                <label htmlFor={`firm_${firm.id}`}>
                                    {firm.name} <span className="county">({firm.country})</span>
                                </label>
                            </li>
                        })}
                    </ul>
                </div>
                <div className="d-flex justify-center form-group">
                    <Button className={'btn-revert'}
                            onClick={() => submitForm()}
                            disabled={!values.firms.length}
                    >{t("feedback_form.form.submit")}</Button>
                </div>
            </>
            : <p>{t("errors.error_occurred")}</p>

        }

    </div>);
};

export default StepChoiceFirms;