import React from 'react';
import Form from "./Form";

const En = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list">
                <h4>Instructions for deleting data:</h4>
                <p>
                    If you want to delete your data from

                  <a href="https://ifurn.pro/" className="" target={'_blank'}> iFurn.pro </a>,

                    please provide your contact information for deletion and the reason.</p>
            </div>
            <Form/>
        </div>
    );
};

export default En;