import React from 'react';

const Login = (props) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="99.000000pt" height="99.000000pt" viewBox="0 0 99.000000 99.000000" preserveAspectRatio="xMidYMid meet" {...props}>

            <g transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M371 859 c-18 -19 -20 -38 -23 -203 -2 -129 1 -190 10 -213 l12 -33 -130 0 c-118 0 -130 -2 -140 -19 -5 -11 -10 -32 -10 -46 0 -70 47 -90 185 -80 l95 7 -14 -41 c-19 -51 -6 -61 35 -27 28 23 39 26 115 26 l84 0 0 -41 c0 -49 15 -79 38 -79 9 0 73 28 142 63 l125 62 3 316 2 317 -22 6 c-13 3 -127 6 -255 6 -224 0 -233 -1 -252 -21z m443 -18 c-5 -5 -40 -26 -79 -46 -38 -21 -85 -47 -102 -59 l-33 -21 0 -106 c0 -58 -3 -161 -7 -227 l-6 -122 -66 0 -65 0 47 48 c26 26 47 50 47 54 0 3 -31 29 -69 57 -81 60 -81 61 -81 287 l0 144 212 0 c127 0 208 -4 202 -9z m56 -300 l0 -288 -102 -48 c-57 -26 -109 -50 -115 -52 -14 -6 -16 213 -4 439 l6 118 105 60 c58 33 106 60 108 60 1 0 2 -130 2 -289z m-412 -146 c23 -16 42 -32 42 -35 0 -3 -23 -28 -51 -55 -46 -46 -50 -48 -44 -23 l7 27 -143 3 -144 3 0 30 0 30 135 3 c74 1 138 6 143 11 5 5 4 19 -3 31 -14 25 -9 24 58 -25z"/>
            </g>
        </svg>
    );
};

export default Login;