import React from 'react';

const He = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list"><h4>מחירון עבור שירותים::</h4><p>המחיר נקבע לפי צרכי
                הלקוח.</p><p>נלקחים בחשבון מספר המקומות עבודה, מספר ההזמנות לחודש, וכמות הציוד שבשימוש.</p><p>השירות
                בנוי כמו בונה אוניברסלי, ניתן להתאים אותו בקלות לצרכים שלך.</p><p>יצרו קשר בדרך הנוחה לכם, ונכין הצעה
                מסחרית.</p></div>
        </div>
    );
};

export default He;