import React from 'react';

const Sk = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content">
                <h2 className="privacy-title">Zmluva o verejnej ponuke</h2><p className="privacy-text">Všeobecné ustanovenia</p>
                <ul>
                    <li>1.1. Táto ponuka je oficiálnou ponukou „ФОП Коток И.М.“, ďalej len „Predajca“, uzatvoriť kúpnu zmluvu o tovare na diaľku, teda prostredníctvom internetového obchodu, ďalej len „Zmluva“, a zverejňuje verejnú ponuku na oficiálnom webovom sídle Predajcu<a href="https://ifurn.pro" target="_blank"
                                                                                                                                                                                                                                                                                     title="ifurn.pro"
                                                                                                                                                                                                                                                                                     className="privacy-link">„https://ifurn.pro“, </a><a
                        href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                        className="privacy-link">„https://newgo.ifurn.pro“, </a><a
                        href="https://crm.ifurn.pro" target="_blank" title="crm.ifurn.pro"
                        className="privacy-link">„https://crm.ifurn.pro“</a>(ďalej len -
                        „Webová stránka“).
                    </li>
                    <li> 1.2. Okamihom úplného a bezpodmienečného prijatia ponuky Predajcu Kupujúcim (akceptáciou) na uzavretie elektronickej zmluvy o kúpe a predaji tovaru sa považuje skutočnosť, že Kupujúci uhradí objednávku na podmienky tejto Zmluvy v lehote a za ceny uvedenej na Webovej stránke Predajcu.
                    </li>
                    <li> 2.1. V tejto ponuke, ak kontext nevyžaduje inak, nasledujúce pojmy majú nasledujúce významy:
                    </li>
                    <ul>
                        <li>* „tovar“ - súčasti nábytku, polotovary, príslušenstvo,
                            komponenty a doplnky;
                        </li>
                        <li>* „Internetový obchod“ - v súlade s ukrajinským zákonom „o elektronickej
                            obchodnej činnosti“, prostriedok na predstavenie alebo realizáciu tovaru,
                            práce alebo služby prostredníctvom elektronickej transakcie.
                        </li>
                        <li>* „Predajca“ - spoločnosť, ktorá predáva tovary, ktoré sú uvedené na
                            internetovej stránke.
                        </li>
                        <li>* „Kupujúci“ - fyzická osoba, ktorá uzatvorila so Predajcom zmluvu na
                            podmienkach uvedených nižšie.
                        </li>
                        <li>* „Objednávka“ - výber jednotlivých položiek z ponuky tovarov uvedených
                            Kupujúcim pri vyplňovaní objednávky a vykonávanie platby.
                        </li>
                    </ul>
                    <p className="privacy-text">Predmet zmluvy</p>
                    <li>3.1. Predajca sa zaväzuje odovzdať Kupujúcemu tovar, a Kupujúci sa zaväzuje uhradiť a prijať tovar na podmienkach tejto Zmluvy.
                    </li>
                    <ul>Táto Zmluva upravuje kúpu a predaj tovarov v internetovom obchode, vrátane:
                        <li> - dobrovoľný výber tovarov Kupujúcim v internetovom obchode;</li>
                        <li> - samostatné vyplnenie objednávky Kupujúcim v internetovom obchode;
                        </li>
                        <li> - úhrada objednávky Kupujúcim v internetovom obchode;</li>
                        <li> - spracovanie a doručenie objednávky Kupujúcemu na podmienkach
                            tejto Zmluvy.
                        </li>
                    </ul>
                    <p className="privacy-text">Postup pri vytváraní objednávky</p>
                    <li>4.1. Kupujúci má právo vytvoriť objednávku na akýkoľvek tovar, ktorý je uvedený na
                        webovej stránke internetového obchodu a je skladom.
                    </li>
                    <li>4.2. Každá položka môže byť zahrnutá do objednávky v ľubovoľnom množstve.</li>
                    <li>4.3. V prípade nedostatku tovaru na sklade je povinnosťou manažéra spoločnosti informovať Kupujúceho (telefonicky alebo e-mailom).
                    </li>
                    <li>4.4. V prípade nedostatku tovaru má Kupujúci právo nahradit ho tovarom rovnakej
                        značky, odmietnuť tento tovar alebo zrušiť objednávku.
                    </li>
                    <p className="privacy-text">Spôsob platby&nbsp;</p><p
                    className="privacy-text">Banková platba.&nbsp;</p>
                    <li>5.1. Platba sa vykonáva na bankový účet Predajcu. Dodanie tovaru
                        Kupujúcemu Predajcom sa uskutočňuje po úhrade faktúry spôsobom dohodnutým
                        v podmienkach dodávky bodu 6.1, 6.2.
                    </li>
                    <li>5.2. Ak sa finančné prostriedky nezaplatia, internetový obchod si vyhradzuje
                        právo zrušiť objednávku.
                    </li>
                    <p className="privacy-text">Podmienky dodávky objednávky&nbsp;</p>
                    <li>6.1. Dodávka tovarov zakúpených v internetovom obchode sa uskutočňuje
                        osobným odberom na uvedenej adrese v faktúre, dopravnou spoločnosťou alebo iným
                        spôsobom dohodnutým medzi Kupujúcim a Predajcom.
                    </li>
                    <li>6.2. S objednávkou Kupujúcemu súčasne poskytujú dokumenty v súlade
                        s legislatívou Ukrajiny.
                    </li>
                    <p className="privacy-text">Práva a povinnosti strán:&nbsp;</p>
                    <li>7.1. Predajca má právo:</li>
                    <ul>
                        <li>- jednostranne pozastaviť poskytovanie služieb podľa tejto
                            zmluvy v prípade porušenia Kupujúcim podmienok tejto
                            zmluvy.
                        </li>
                    </ul>
                    <li>7.2. Kupujúci je povinný:</li>
                    <ul>
                        <li>- včas uhradiť a prijať objednávku na podmienkach tejto
                            zmluvy.
                        </li>
                    </ul>
                    <li>7.3. Kupujúci má právo:</li>
                    <ul>
                        <li>- vytvoriť objednávku v internetovom obchode;</li>
                        <li>- uzavrieť elektronickú zmluvu;</li>
                        <li>- požadovať od Predajcu splnenie podmienok tejto Zmluvy.</li>
                    </ul>
                    <p className="privacy-text">Zodpovednosť strán&nbsp;</p>
                    <li>8.1. Strany nesú zodpovednosť za nesplnenie alebo nesprávne splnenie
                        podmienok tejto zmluvy v poriadku, ktorý stanovuje táto zmluva a platná
                        legislatíva Ukrajiny.
                    </li>
                    <li>8.2. Predajca nezodpovedá za:</li>
                    <ul>
                        <li>- zmenený výrobcom vzhľad tovaru;</li>
                        <li>- nepodstatné nesúlad farby tovaru, ktorý sa môže
                            líšiť od originálu tovaru len v dôsledku odlišného zobrazovania farieb
                            monitorov osobných počítačov určitých modelov;
                        </li>
                        <li>- obsah a pravdivosť informácií poskytnutých Kupujúcim pri
                            vytváraní objednávky;
                        </li>
                        <li>- oneskorenie a prerušenie poskytovania služieb (spracovanie objednávky a
                            doručovanie tovaru), ktoré sú spôsobené dôvodmi mimo jeho kontroly;
                        </li>
                        <li>- nezákonné činy, ktoré vykonal Kupujúci s použitím
                            tohto prístupu k internetu;
                        </li>
                        <li>- odovzdanie Kupujúceho svojich sieťových identifikátorov - IP,
                            MAC adresa, prihlasovacie meno a heslo tretím osobám;
                        </li>
                    </ul>
                    <li>8.3. Kupujúci, využívajúc dostup k internetu, nesie samostatnú zodpovednosť za škody spôsobené jeho konaním (aj keď pod jeho prihlasovacím menom bola iná osoba) fyzickým alebo právnickým osobám alebo ich majetku, štátu alebo morálnym zásadám morálky.
                    </li>
                    <li>8.4. V prípade vzniku nevyhnutelných okolností sily majejú strany oslobodiť sa od plnenia podmienok tejto zmluvy. Za okolnosti sily majejú strany na účely tejto zmluvy udalosti s mimoriadnym a nepredvídateľným charakterom, ktoré vylučujú alebo objektívne bránia plneniu tejto zmluvy, ktorých nastanie strany nemohli predvídať a rozumným spôsobom predchádzať.
                    </li>
                    <li>8.5. Strany vynakladajú maximálne úsilie na riešenie akýchkoľvek nesúhlasov výlučne prostredníctvom rokovaní.
                    </li>
                    <p className="privacy-text">Ďalšie podmienky&nbsp;</p>
                    <li>9.1. Internetový obchod si vyhradzuje právo jednostranne meniť túto zmluvu za predpokladu predchádzajúceho zverejnenia na stránke<a href="https://newgo.ifurn.pro" target="_blank"
                                                                                                                                                            title="newgo.ifurn.pro"
                                                                                                                                                            className="privacy-link">„https://newgo.ifurn.pro“ </a></li>
                    <li>9.2. Internetový obchod bol vytvorený pre organizáciu diaľkového predaja tovarov cez internet.
                    </li>
                    <li>9.3. Kupujúci nesie zodpovednosť za presnosť údajov uvedených pri vyplňovaní objednávky. Pri vykonávaní akceptácie (vyplnení objednávky a následnej platbe tovaru) Kupujúci poskytuje Predajcovi svoj súhlas so zbieraním, spracovaním, uchovávaním a používaním svojich osobných údajov v zmysle Zákona Ukrajiny „O ochrane osobných údajov“.
                    </li>
                    <li>9.4. Platba Kupujúceho za objednávku vytvorenú v internetovom obchode znamená úplný súhlas Kupujúceho s podmienkami kúpnej zmluvy (verejnej ponuky)
                    </li>
                    <li>9.5. Skutočným dátumom elektronického dohovoru medzi stranami je dátum prijatia podmienok podľa čl. 11 Zákona Ukrajiny „O elektronickej obchodnej činnosti“
                    </li>
                    <li>9.6. Používanie zdroja internetového obchodu na predbežné prezretie tovaru a na vytvorenie objednávky pre Kupujúceho je bezplatné.
                    </li>
                    <li>9.7. Informácie poskytnuté Kupujúcim sú dôverné. Internetový obchod využíva informácie o Kupujúcom výlučne na účely spracovania objednávky, odosielania oznámení Kupujúcemu, doručovania tovaru, vykonávania vzájomných platieb a pod.
                    </li>
                    <p className="privacy-text">Postup vrátenia tovaru vhodného kvality</p>
                    <li>10.1. Vrátenie tovaru do internetového obchodu sa vykonáva v súlade s platnou legislatívou Ukrajiny.
                    </li>
                    <p className="privacy-text">Doba platnosti zmluvy</p>
                    <li>11.1. Elektronická zmluva sa považuje za uzatvorenú v okamihu, keď ju osoba, ktorá predložila ponuku uzavrieť takúto zmluvu, prijme odpoveďou na prijatie tejto ponuky v poradí určenom časťou šiestou článku 11 Zákona Ukrajiny „O elektronickej obchodnej činnosti“.
                    </li>
                    <li>11.2. Pred ukončením platnosti tejto zmluvy ju možno vypovedať vzájomným súhlasom strán do chvíle skutočného doručenia tovaru prostredníctvom vrátenia finančných prostriedkov
                    </li>
                    <p className="privacy-text">Upozorňujeme, že internetový obchod „СПД Коток И.М.“ na oficiálnom webovom sídle<a href="https://newgo.ifurn.pro"
                                                                                                                                   target="_blank"
                                                                                                                                   title="newgo.ifurn.pro"
                                                                                                                                   className="privacy-link">„https://newgo.ifurn.pro“, </a>si vyhradzuje právo v súlade s legislatívou Ukrajiny poskytovať právo
                        na využívanie internetovej platformy FOH a právnickým osobám na predaj tovaru.
                    </p></ul>

            </div>
        </div>
    );
};

export default Sk;