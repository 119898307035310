import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import IconArrow from "../../../../assets/Icons/IconArrow";
import {useDispatch} from "react-redux";
import {sendFeedbackForm} from "../../../../redux/app-reducer";

const Form = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const onSubmitForm = (data) => {
        dispatch(sendFeedbackForm(data))
    }
    const formik = useFormik({
        initialValues: {
            text: '',
            email: '',
        },
        onSubmit: (values, {resetForm}) => {
            onSubmitForm({
                text: `Email - ${values.email},  причинa удаления данных- ${values.text} 
                `
            });
            // Номер телефона - ${values.phone} E-mail - ${values.email}

            resetForm();
            // alert(JSON.stringify(values, null, 2));
        },
    });
    return (
        <form className={" form-sm "} style={{margin:'25px 0px'}}
              onSubmit={formik.handleSubmit}>
                <div className={"field "}>
                    <label>{t('feedback_form.form.email')}</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder={t('feedback_form.form.email')}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        required={true}
                        className={'filed-border '}
                    /></div>
            <div className="filed form-group">
                <label>{t("filed_textarea_desc")}</label>
                <textarea name="" id="" cols="30" rows="5" className={'filed-border'} required={true} onChange={formik.handleChange}>{formik.values.text}</textarea>
            </div>
            {/*</div>*/}
<div className="d-flex justify-center">
    <button type="submit">{t('feedback_form.form.submit')}</button>
</div>

        </form>
    );
};

export default Form;