import React from 'react';

const Pl = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Warunki zwrotu pieniędzy:</h1>
                <ul>
                    <li>1. Zwroty są dostarczane w ciągu 14 dni</li>
                    <li>2. Zwroty dokonywane są tylko za pierwsze zamówione usługi. Na przykład: jeśli zamówiłeś
                        usługę subskrypcyjną, po której otrzymałeś zwrot pieniędzy i zamówiłeś u nas subskrypcję po
                        raz drugi, to nie będziesz mógł otrzymać zwrotu za drugie zamówienie.
                    </li>
                    <li>3. Zwroty są dokonywane pomniejszone o prowizję systemów płatności.</li>
                    <li>4. W przypadku płatności kartą VISA i MasterCard pieniądze są zwracane na tę samą kartę,
                        którą dokonano płatności. Po zwrocie wpłaty pieniądze pojawiają się na karcie w ciągu 3 dni
                        bankowych. Należy pamiętać, że ta płatność nie pojawia się na wyciągach z karty, ale po
                        prostu zwiększa kwotę na karcie. Ten rodzaj zwrotu jest możliwy tylko w ciągu 30 dni od
                        dokonania płatności.
                    </li>
                    <li>5. Pieniądze są zwracane na te same dane, z których została dokonana płatność za usługi.
                    </li>
                    <li>6. Zwroty są dokonywane w ciągu 10 dni od daty złożenia wniosku.</li>
                    <li>7. Przy zwrocie wszystkie bonusy i rabaty są odejmowane.</li>
                </ul>
            </div>
        </div>
    );
};

export default Pl;