import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {setGlobalLocation} from "../../redux/app-reducer";
import {languages} from "../../languages/langugase";
import Logo from "../../picture/icon/Logo";
import Globe from "../../picture/icon/Globe";
import {useDetectClickOutside} from "react-detect-click-outside/dist";
import {currentLang} from "../../utils/multilingualism";
import IconTel from "../../assets/Icons/IconTel";
import {TAB_HASH_IDS} from "../../routing_hash";
import {LS_LANG} from "../../consts/localStorageNaming";


let HeaderContainer = ({switchTabAndScrolledSection}) => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const [isSelect, setIsSelect] = useState(false);
    const refSelect = useDetectClickOutside({onTriggered: () => setIsSelect(false)})
    const history = useHistory();
    const location = useLocation();
    const local = useSelector(state => state.appPage.locale);

    const onChangeLanguagesLocal = (value) => {
        let code = !!currentLang(location.pathname)
        let pathName = location.pathname;
        let newPathName = '';
        if (code) {
            newPathName = pathName.replace(/\/((ua|en|ru|pl|ro|he|et|sk|de|lt|lv)\/?)?/, (str) => {
                let code = '/' + value;
                let end = str[str.length - 1].includes('/') ? "/" : "";
                return code + end + location.hash;
            });
        } else {
            newPathName = pathName + value +location.hash;
        }
        localStorage.setItem(LS_LANG, value)
        history.push(newPathName);
        dispatch(setGlobalLocation(value));
        window.location.reload();

    }


    const onChange = useCallback( (tab, id_section) => () => {
        setTimeout(function (){
            switchTabAndScrolledSection(tab, id_section)
        })

    }, [switchTabAndScrolledSection]);

    return (<header>
            <div className="header-container">
                <div className="container">
                    <div className="row">
                        <div className="logo" onClick={onChange(TAB_HASH_IDS.home, 'body')}><Logo key={'logo'}/></div>
                        <nav>
                            <li onClick={onChange(TAB_HASH_IDS.home, 'workLess')}>

                                {t('header.useful')}</li>
                            <li onClick={onChange(TAB_HASH_IDS.home, 'stepConnect')}>{t('header.connect')}</li>
                            {/*<li onClick={()=> scrollToPage('partners')}>{t('header.partner')}</li>*/}
                            {/*<li onClick={()=> scrollToPage('reviews')}>{t('header.reviews')}</li>*/}
                        </nav>
                        <div className="nav-controls">
                            <div className="select-custom" ref={refSelect}>
                                <div className="select__head"
                                     onClick={() => setIsSelect(!isSelect)}>
                                    <Globe/>
                                    {local}
                                </div>
                                {isSelect && <ul className="select__body">
                                    {languages.map(option => {
                                        return <li
                                            onClick={(e) => onChangeLanguagesLocal(option.code)}
                                            key={option.country_code} value={option.code}
                                            className={local === option.code ? 'disabled' : ''}>
                                            {option.code}
                                        </li>
                                    })}
                                </ul>}
                            </div>
                            <div className="btn"
                                 onClick={onChange(TAB_HASH_IDS.home, 'callback')}>{t('header.call_me')}
                                <IconTel/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderContainer;