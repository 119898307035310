import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ServiceItem} from "./ServiceItem";
import {useTranslation} from "react-i18next";
import {VideoWrap} from "../VideoWrap";
import {setAllFimsDT} from "../../redux/app-reducer";
import {appGetEntryFirms, localApp} from "../../redux/selector/app-selector";

const OrdersServiceContainer = () => {
    const dispatch = useDispatch();
    const firms = useSelector(appGetEntryFirms);
    const locale = useSelector(localApp);

    useEffect(()=>{
        if(!firms.data || (Array.isArray(firms.data) && !firms.data.length)) dispatch(setAllFimsDT());
    }, [firms])
    const {t} = useTranslation();
    return <>
        <div className="container">
            <div className="row">

                <div className={"info-block mb-2"}>
                    <VideoWrap videoType={'provider'}/>
                    <h3 className={""}>
                        {t('serviceProviderOld.title')}
                    </h3>
                    <ul>
                        <li>{t('serviceProviderOld.text.0')}</li>
                        <li>{t('serviceProviderOld.text.1')}</li>
                        <li>{t('serviceProviderOld.text.2')}</li>
                        <li>{t('serviceProviderOld.text.3')}</li>
                        <li>{t('serviceProviderOld.text.4')}</li>
                        <li>{t('serviceProviderOld.text.5')}</li>
                        <li>{t('serviceProviderOld.text.6')}</li>
                        <li>{t('serviceProviderOld.text.7')}</li>
                        <li>{t('serviceProviderOld.text.8')}</li>
                        <li>{t('serviceProviderOld.text.9')}</li>

                    </ul>
                </div>
            </div>
        </div>
        <div className="table-section">
            <div className="tablet-firms " style={{overflowY: "hidden"}}>
                <table>
                    <tr className="">
                        <th className="item">{t('tableFirm.0')}</th>
                        <th className="item">{t('tableFirm.1')}</th>
                        <th className="item">{t('tableFirm.2')}</th>
                        <th className="item">{t('tableFirm.3')}</th>
                        <th className="item">{t('tableFirm.4')}</th>
                        <th className="item">{t('tableFirm.5')}</th>
                        <th></th>
                    </tr>

                    {firms.data && Array.isArray(firms.data) && firms.data.map(dt => <ServiceItem {...dt} locale={locale}/>)}

                </table>
            </div>
        </div>
    </>
}
export default OrdersServiceContainer;



