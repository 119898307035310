import React from 'react';

const De = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list"><h4>Preisliste für Dienstleistungen:</h4><p>Der Preis wird nach den Bedürfnissen des Kunden festgelegt.</p><p>Es werden die Anzahl der Arbeitsplätze, die Anzahl der Bestellungen pro Monat und die Anzahl der verwendeten Geräte berücksichtigt.</p><p>Der Service ist als universeller Konstruktor aufgebaut und leicht an Ihre Bedürfnisse anpassbar.</p><p>Kontaktieren Sie uns auf bequeme Weise, und wir werden Ihnen ein kommerzielles Angebot erstellen.</p>
            </div>
        </div>

    );
};

export default De;