import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {onCommercialOfferModalDT, setISTermUse} from "../../../redux/app-reducer";
import Close from "../../../picture/icon/Close";
import React from "react";
import {InfoBlock} from "../../ServiceProviders/ServiceProvidersContainer";

const CommercialOfferModal = () => {
    const {t} = useTranslation();
    const locale = useSelector(state => state.appPage.locale);
    const dispatch = useDispatch();
    const {isOpen} = useSelector(state => state.appPage.commercialOfferModal)
    const onClose = () => {
        dispatch(onCommercialOfferModalDT({isOpen: false}))
    }
    return (isOpen &&
            <div className="term-use__container modal-container">
                <div className="container">
                    <div className="row">
                        <div className="modal-content">
                            <div className="modal-head">
                                <div className="h4">
                                    {t('orderService.title')}
                                </div>
                                <div className="close" onClick={onClose}>
                                    <Close isBlack={false}/>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="content commercial-modal-content">
                                    <InfoBlock t={t} locale={locale}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
export default CommercialOfferModal;