import React from 'react';

const Sk = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Politika bezpečnosti pri prenose údajov platobnej karty</h1>
                <p>Pri platbe objednávky bankovou kartou prebieha spracovanie platby (vrátane zadávania čísla karty) na zabezpečenej stránke spracovateľského systému. To znamená, že vaše dôverné údaje (údaje karty, registračné údaje a podobne) k nám neprichádzajú, ich spracovanie je plne chránené a nikto nemôže získať osobné a bankové údaje klienta.</p>
                <p>Pri práci s údajmi o kartách sa uplatňuje štandard ochrany informácií vyvinutý medzinárodnými platobnými systémami Visa a MasterCard - Payment Card Industry Data Security Standard (PCI DSS), čo zabezpečuje bezpečné spracovanie údajov o platobnej karte držiteľa. Používaná technológia prenosu údajov zaručuje bezpečnosť transakcií s bankovými kartami prostredníctvom protokolov Secure Sockets Layer (SSL).</p>
            </div>
        </div>

    );
};

export default Sk;