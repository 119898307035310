import React from 'react';

const Lt = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list"><h4>Kainoraštis paslaugoms:</h4><p>Kaina formuojama atsižvelgiant į Kliento poreikius.</p>
                <p>
                    Atsižvelgiama į darbo vietų skaičių, užsakymų skaičių per mėnesį, naudojamo įrangos kiekį.</p><p>
                    Paslauga sukurtas kaip universalus konstruktorius, lengvai prisitaikantis prie
                    Jūsų poreikių.
                </p><p>Susisiekite su mumis jums patogiu būdu, ir mes parengsime jums verslo pasiūlymą.</p>
            </div>
        </div>

    );
};

export default Lt;