import {createSelector} from 'reselect';

// данные пользователя
export const helpArticleModalSelected = createSelector(
    [(state)=>state.help_article.help_article_modal],
    help_article_modal=>{
        return help_article_modal
    }
)
export const helpArticleSendCommentMailSelected = createSelector(
    [(state)=>state.help_article.send_mail],
    send_mail=>{
        return send_mail
    }
)
