import {toast} from "react-toastify";
import {HelpArticleAPI} from "../../api/HelpArticleAPI";
import {helpArticleGetByIdToggleModalAC, helpArticleSendCommentMailAC} from "./help-article-action";
import {sendMailApi} from "../../api/api";
import i18next from "i18next";

export const helpArticleGetByIdToggleModalThunk = (id)=>async dispatch =>{
    try{
        dispatch(helpArticleGetByIdToggleModalAC({isLoading: true}));
        const content_modal = await HelpArticleAPI.getById(id);
        dispatch(helpArticleGetByIdToggleModalAC({isLoading: false, is_open: true, error: null, isError: null, content: content_modal}));
    }catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(helpArticleGetByIdToggleModalAC({isLoading: false, is_open: false, error: msg, isError: true, content: null}));
        toast.error(msg);
    }
}

export const helpArticleSendCommentMailThunk = (text)=>async dispatch =>{
    try{
        dispatch(helpArticleSendCommentMailAC({isLoading: true}));
        const response = await sendMailApi.send(text);
        dispatch(helpArticleSendCommentMailAC({isLoading: false,  error: null, isError: null}));
        toast.success(i18next.t('message.success'));
        return Promise.resolve(true)
    }catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(helpArticleSendCommentMailAC({isLoading: false, is_open: false, error: msg, isError: true, content: null}));
        toast.error(msg);
        return Promise.resolve(false)
    }
}
