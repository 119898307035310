import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {File} from "../../picture/icon/File";
import {VideoWrap} from "../VideoWrap";
import {onPayRequestInvoiceModal} from "../../redux/app-reducer";
import Visa from "../../picture/image/visa-logo.png";
import MasterCard from "../../picture/image/mastercard-logo.png";
import {localApp} from "../../redux/selector/app-selector";
import Button from "../ui/Button";
import {signUpProviderServicesModal} from "../../redux/app/app-actions";
import SignUpProviderServiceModal from "../Modal/SignUpProviderServiceModal";

const ServiceProvidersContainer = () => {
    const dispatch = useDispatch();
    const locale = useSelector(localApp);
    const {t} = useTranslation();
    const handlerOpenSignUpProviderServicesModal = useCallback(() => {
        dispatch(signUpProviderServicesModal({isOpen: true}))
    }, [dispatch])
    return <>
        <div className="container">
            <div className="row">
                <div className="info-block mb-2">
                    <VideoWrap videoType={'provider'}/>

                    <div>
                        <p><a className={'don-pdf'}
                              href={window.location.origin + `/pdf/Offerta_firm_${locale}-converted.pdf`}
                              download={true}> <File/> <span>{t('orderService.title')}pdf</span> </a></p>

                    </div>
                    <div className="d-flex justify-between align-items-center flex-wrap mb-2" style={{gap: '10px'}}>
                        {['ru', 'ua'].includes(locale) ?
                            <div className="">

                                <div className="btn-pay" onClick={() => {
                                    dispatch(onPayRequestInvoiceModal({
                                        key: 'requestInvoice',
                                        dt: {isOpen: true, isProvider: false}
                                    }))
                                }
                                }>
                                    <span>{t('payDT.title')}</span>
                                    <img src={Visa} alt="" width={45}/>
                                    <img src={MasterCard} alt="" width={45}/>
                                </div>
                            </div> : null}
                        <Button onClick={handlerOpenSignUpProviderServicesModal}
                                className={"btn"}>{t('login_page.registration')}</Button>

                    </div>
                    <h4 className={'mb-0'}>{t('orderService.title')}</h4>
                    <div className="font-italic mt-2 mb-2">{t('orderService.subtitle')}</div>
                    <strong>{t('orderService.section1.title')}</strong>
                    <ul>
                        <li>
                            <span>{t('orderService.section1.list.li1.title')}</span>
                            <ul>
                                <li>{t('orderService.section1.list.li1.items.0')}</li>
                                <li>{t('orderService.section1.list.li1.items.1')}</li>
                                <li>{t('orderService.section1.list.li1.items.2')}</li>
                                <li>{t('orderService.section1.list.li1.items.3')}</li>
                                <li>{t('orderService.section1.list.li1.items.4')}</li>
                            </ul>
                        </li>
                        <li>{t('orderService.section1.list.li2')}</li>
                        <li>{t('orderService.section1.list.li3')}</li>
                    </ul>
                    <p className={'font-italic mb-0'}>{t('orderService.section1.desc')}</p>

                    {/*section 2*/}
                    <br/>
                    <strong>{t('orderService.section2.title')}</strong>
                    <ul>
                        <li>
                            {t('orderService.section2.list.li1')}
                        </li>
                        <li>{t('orderService.section2.list.li2')}</li>
                        <li>{t('orderService.section2.list.li3')}</li>
                        <li>{t('orderService.section2.list.li4')}</li>
                        <li>{t('orderService.section2.list.li5')}</li>
                    </ul>

                    <br/>
                    <strong>{t('orderService.section3.title')}</strong>
                    <ul>
                        <li>
                            {t('orderService.section3.list.li1')}
                        </li>
                        <li>{t('orderService.section3.list.li2')}</li>
                        <li>
                            <span>{t('orderService.section3.list.li3.title')}</span>
                            <ul>
                                <li>{t('orderService.section3.list.li3.items.0')}</li>
                            </ul>
                        </li>
                    </ul>
                    <VideoWrap videoType={'box'} isBox={true}/>
                    <ul>
                        <li>{t('orderService.section3.list.li4')}</li>
                        <li>{t('orderService.section3.list.li5')}</li>
                        <li>{t('orderService.section3.list.li6')}</li>
                        <li>
                            <span>{t('orderService.section3.list.li7.title')}</span>
                            <ul>
                                <li>{t('orderService.section3.list.li7.items.0')}</li>
                                <li>{t('orderService.section3.list.li7.items.1')}</li>
                                <li>{t('orderService.section3.list.li7.items.2')}</li>
                                <li>{t('orderService.section3.list.li7.items.3')}</li>
                                <li>{t('orderService.section3.list.li7.items.4')}</li>
                                <li>{t('orderService.section3.list.li7.items.5')}</li>
                            </ul>
                        </li>
                        <li>{t('orderService.section3.list.li8')}</li>
                        <li>
                            <span>{t('orderService.section3.list.li9.title')}</span>
                            <ul>
                                <li>{t('orderService.section3.list.li9.items.0')}</li>
                                <li>{t('orderService.section3.list.li9.items.1')}</li>
                                <li>{t('orderService.section3.list.li9.items.2')}</li>
                                <li>{t('orderService.section3.list.li9.items.3')}</li>
                                <li>{t('orderService.section3.list.li9.items.4')}</li>
                                <li>{t('orderService.section3.list.li9.items.5')}</li>
                                <li>
                                    <span>{t('orderService.section3.list.li9.items.6.title')}</span>
                                    <ul>
                                        <li>{t('orderService.section3.list.li9.items.6.items.0')}</li>
                                        <li>{t('orderService.section3.list.li9.items.6.items.1')}</li>
                                        <li>{t('orderService.section3.list.li9.items.6.items.2')}</li>
                                        <li>{t('orderService.section3.list.li9.items.6.items.3')}</li>
                                    </ul>
                                </li>

                            </ul>
                        </li>
                        <li>{t('orderService.section3.list.li10')}</li>
                        <li>
                            <span>{t('orderService.section3.list.li11.title')}</span>
                            <ul>
                                <li>{t('orderService.section3.list.li11.items.0')}</li>
                            </ul>
                        </li>
                        <li>{t('orderService.section3.list.li12')}</li>
                        <li>{t('orderService.section3.list.li13')}</li>
                    </ul>
                    <strong className={'font-italic'}>{t('orderService.text')}</strong>

                </div>
            </div>
        </div>
        <SignUpProviderServiceModal/>
    </>
}
export default ServiceProvidersContainer;

export const InfoBlock = ({t, locale}) => {
    return <div>
        <h4 className={'mb-0'}>{t('orderService.title')}</h4>
        <div className="font-italic mt-2 mb-2">{t('orderService.subtitle')}</div>
        <strong>{t('orderService.section1.title')}</strong>
        <ul>
            <li>
                <span>{t('orderService.section1.list.li1.title')}</span>
                <ul>
                    <li>{t('orderService.section1.list.li1.items.0')}</li>
                    <li>{t('orderService.section1.list.li1.items.1')}</li>
                    <li>{t('orderService.section1.list.li1.items.2')}</li>
                    <li>{t('orderService.section1.list.li1.items.3')}</li>
                    <li>{t('orderService.section1.list.li1.items.4')}</li>
                </ul>
            </li>
            <li>{t('orderService.section1.list.li2')}</li>
            <li>{t('orderService.section1.list.li3')}</li>
        </ul>
        <p className={'font-italic mb-0'}>{t('orderService.section1.desc')}</p>

        {/*section 2*/}
        <br/>
        <strong>{t('orderService.section2.title')}</strong>
        <ul>
            <li>
                {t('orderService.section2.list.li1')}
            </li>
            <li>{t('orderService.section2.list.li2')}</li>
            <li>{t('orderService.section2.list.li3')}</li>
            <li>{t('orderService.section2.list.li4')}</li>
            <li>{t('orderService.section2.list.li5')}</li>
        </ul>

        <br/>
        <strong>{t('orderService.section3.title')}</strong>
        <ul>
            <li>
                {t('orderService.section3.list.li1')}
            </li>
            <li>{t('orderService.section3.list.li2')}</li>
            <li>
                <span>{t('orderService.section3.list.li3.title')}</span>
                <ul>
                    <li>{t('orderService.section3.list.li3.items.0')}</li>
                </ul>
            </li>
            <li>{t('orderService.section3.list.li4')}</li>
            <li>{t('orderService.section3.list.li5')}</li>
            <li>{t('orderService.section3.list.li6')}</li>
            <li>
                <span>{t('orderService.section3.list.li7.title')}</span>
                <ul>
                    <li>{t('orderService.section3.list.li7.items.0')}</li>
                    <li>{t('orderService.section3.list.li7.items.1')}</li>
                    <li>{t('orderService.section3.list.li7.items.2')}</li>
                    <li>{t('orderService.section3.list.li7.items.3')}</li>
                    <li>{t('orderService.section3.list.li7.items.4')}</li>
                    <li>{t('orderService.section3.list.li7.items.5')}</li>
                </ul>
            </li>
            <li>{t('orderService.section3.list.li8')}</li>
            <li>
                <span>{t('orderService.section3.list.li9.title')}</span>
                <ul>
                    <li>{t('orderService.section3.list.li9.items.0')}</li>
                    <li>{t('orderService.section3.list.li9.items.1')}</li>
                    <li>{t('orderService.section3.list.li9.items.2')}</li>
                    <li>{t('orderService.section3.list.li9.items.3')}</li>
                    <li>{t('orderService.section3.list.li9.items.4')}</li>
                    <li>{t('orderService.section3.list.li9.items.5')}</li>
                    <li>
                        <span>{t('orderService.section3.list.li9.items.6.title')}</span>
                        <ul>
                            <li>{t('orderService.section3.list.li9.items.6.items.0')}</li>
                            <li>{t('orderService.section3.list.li9.items.6.items.1')}</li>
                            <li>{t('orderService.section3.list.li9.items.6.items.2')}</li>
                            <li>{t('orderService.section3.list.li9.items.6.items.3')}</li>
                        </ul>
                    </li>

                </ul>
            </li>
            <li>{t('orderService.section3.list.li10')}</li>
            <li>
                <span>{t('orderService.section3.list.li11.title')}</span>
                <ul>
                    <li>{t('orderService.section3.list.li11.items.0')}</li>
                </ul>
            </li>
            <li>{t('orderService.section3.list.li12')}</li>
            <li>{t('orderService.section3.list.li13')}</li>
        </ul>
        <strong className={'font-italic'}>{t('orderService.text')}</strong>
        <p><a className={'don-pdf'}
              href={window.location.origin + `/pdf/Offerta_firm_${locale}-converted.pdf`}
              download={true}> <File/> <span>{t('orderService.title')}pdf</span> </a></p>

    </div>
}