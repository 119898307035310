import React from "react";
import {useFormik} from 'formik';
import {useTranslation} from "react-i18next";
import IconArrow from "../../../assets/Icons/IconArrow";

const FeedbackForm = ({onSubmitForm}) => {
    const {t} = useTranslation();

    const formik = useFormik({
        initialValues: {
            activities: '',
            nameCompany: '',
            phone: '',
            email: '',
        },
        onSubmit: (values, {resetForm}) => {
            onSubmitForm({
                text: `Вид деятельности - ${values.activities},  Название предприятия - ${values.nameCompany} Номер телефона - ${values.phone} E-mail - ${values.email}`
            });
            resetForm();
            // alert(JSON.stringify(values, null, 2));
        },
    });
    return (
        <form className={"form-feedback"}
              onSubmit={formik.handleSubmit}>
            <div className="field styled-select">
                <select name="activities" id=""
                        onChange={formik.handleChange}
                        value={formik.values.activities}
                        required={true}
                >
                    <option selected={true} disabled={true}
                            value="">{t('feedback_form.form.select.0.name')}</option>
                    <option
                        value={t('feedback_form.form.select.1.name')}>{t('feedback_form.form.select.1.name')}</option>
                    <option
                        value={t('feedback_form.form.select.2.name')}>{t('feedback_form.form.select.2.name')}</option>
                    <option
                        value={t('feedback_form.form.select.3.name')}>{t('feedback_form.form.select.3.name')}</option>

                </select>
                <IconArrow/>
            </div>
            <div className="field ">
                <input
                    id="nameCompany"
                    name="nameCompany"
                    type="text"
                    placeholder={t('feedback_form.form.firm_name')}
                    onChange={formik.handleChange}
                    value={formik.values.nameCompany}
                    required={'required'}
                />
            </div>
            <div className="field-row">
                <div className={"field "}>
                    <input
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder={t('feedback_form.form.phone')}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                /></div>
                <div className={"field "}>
                    <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder={t('feedback_form.form.email')}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    required={'required'}
                /></div>
            </div>

            <button type="submit">{t('feedback_form.form.submit')}</button>
        </form>
    );
}
export default FeedbackForm;