import {clientsFirmsAC} from "./auth/auth-action";
import {toast} from "react-toastify";
import {FirmsApi} from "../api/api";
import {AuthAPI} from "../api/AuthAPI";
import {authThunkInitialVerificationUser} from "./auth/auth-thunk";
import {toggleLoader} from "./app-reducer";

/**
 * firmsThunkConnected подключить фирмы.
 *
 * @param {formData} formData -
 * @param {string} from_url  -base64 code uri current site
 */
export const firmsThunkConnected = (formData, from_url) => async dispatch => {
    try {
        dispatch(clientsFirmsAC({isLoading: true}));
        const response = await FirmsApi.connectedFirms(formData);

        if(response.hasOwnProperty('errors') && Array.isArray(response.error) && !!response.error.length){
            let error_str = 'firmsThunkConnected errors '
            toast.error(error_str);
            dispatch(clientsFirmsAC({isLoading: false}));
            return
        }
        if (response.hasOwnProperty('error')) {
            toast.error(response.error);
            dispatch(clientsFirmsAC({isLoading: false}));
            return
        }
        dispatch(authThunkClientsFirms(from_url))
    } catch (e) {
        dispatch(clientsFirmsAC({isLoading: false, entry_firm: null}))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}

const dispatcherFirms = (response, dispatch) => {
    if (response.hasOwnProperty('error')) {
        dispatch(clientsFirmsAC({isLoading: false, isError: true, error: response.error, entry_firm: null}))
        toast.error(response.error);
        return
    }
    dispatch(clientsFirmsAC({isLoading: false, isError: false, error: null, entry_firm: response}));

};
/**
 * authThunkClientsFirms. запросить доступный список фирм. Доступно только клиенту
 * @param {string | null} from - base64 code uri current site
 * @returns  {object} connected , not_connected, query_firm-
 */
export const authThunkClientsFirms = (from = null) => async dispatch => {
    dispatch(clientsFirmsAC({isLoading: true}))
    try {
        const response = await FirmsApi.getFirms(from);
        dispatcherFirms(response, dispatch)

    } catch (e) {
        dispatch(clientsFirmsAC({isLoading: false, entry_firm: null}))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}


/**
 * authThunkGetRegisterClientsFirms получить фирмы при регистрации.
 *
 * @param {string|null} from - base64 code uri current site
 */
export const authThunkGetRegisterClientsFirms = (from = null) => async dispatch => {
    dispatch(clientsFirmsAC({isLoading: true}))
    try {
        const response = await AuthAPI.getAuthFirms(from);
        dispatcherFirms(response, dispatch)

    } catch (e) {
        dispatch(clientsFirmsAC({isLoading: false, entry_firm: null}))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}
/**
 * firmsThunkSwitchFirms переключение фирмы.
 * @param {number}  firm - номер фирмы
 */
export const firmsThunkSwitchFirms = (firm)=> async dispatch =>{
    try{
       dispatch(authThunkInitialVerificationUser(firm))
    }catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}
/**
 * firmsThunkDeleteFirm удаление подключенных фирм.
 * @param {formData}  form_data - список фирм
 * @param {string}  from - uri base 64
 */
export const firmsThunkDeleteFirm = (form_data, from)=> async dispatch =>{
    try{
      dispatch(toggleLoader(true))
        const response_delete = await FirmsApi.deleteFirm(form_data);
        if(response_delete.hasOwnProperty('error')){
            dispatch(toggleLoader(false))
            toast.error(response_delete.error);
            return
        }
        const response = await AuthAPI.getAuthFirms(from);
        dispatcherFirms(response, dispatch)
        dispatch(toggleLoader(false))


    }catch (e) {
        dispatch(toggleLoader(false))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}