import React from 'react';
import InputFiled from "../../../../ui/InputFiled";
import {useTranslation} from "react-i18next";
import {useFormikContextProviderLogin} from "../Login";
import SubmitPrivacy from "./SubmitPrivacy";
import MasterPassword from "./MasterPassword";

/**
 * .
 *
 * @param {boolean} isShortPassword - радио тип  отображения поля короткий/мастре (пароль)
 * @param {}  -
 * @returns  {}  -
 */
const AuthAccountPassword = ({resendChildrenEmail = null, handlerSendPasswordOneTime=null}) => {
    const {t} = useTranslation();
    const {values, handleChange, setFieldValue, validateForm} = useFormikContextProviderLogin();
    const handlerSend = ()=>{
       if(handlerSendPasswordOneTime) handlerSendPasswordOneTime();
        setFieldValue('isRadioShortPassword', true)
        // onReqAccountAccess({send_password: 1})
    }

    return (
        <>
            <div className="choice-type-login d-flex flex-row">
                <div className="item d-flex align-items-center">
                    <input type="radio" value="1" id={'password-short'}
                           checked={values.isRadioShortPassword} className={''}
                           onChange={handlerSend}/>
                    <label htmlFor="password-short">
                        {t('login_page.one_time_password')}
                    </label>
                </div>
                <div className="item d-flex align-items-center">
                    <input type="radio" value="0" id={'master-password'}
                           checked={!values.isRadioShortPassword} className={''}
                           onChange={() => {
                               setFieldValue('isRadioShortPassword', false)

                           }}/>
                    <label htmlFor="master-password">
                        {t('login_page.master-password')}
                    </label>
                </div>
            </div>

            {values.isRadioShortPassword ?
                <>
                    <InputFiled className={'filed-border'} id="password"
                                autoComplete={'new-password'}
                                name="password"
                                type="text" value={values.password} onChange={handleChange}/>
                    {resendChildrenEmail && resendChildrenEmail}
                </>
                : <>
                    <MasterPassword isDisplayLabel={false}/>


                </>}
            <SubmitPrivacy/>

        </>
    );
};

export default AuthAccountPassword;