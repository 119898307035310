import React, {createContext, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {registrationSelected} from "../../../../redux/auth/auht-selector";
import {authThunkRegisterCheckUser} from "../../../../redux/auth/auth-thunk";
import useAuthLookup from "../../../../Hook/useAuthLookup";
import StepLookup from "./Registraion/StepLookup";
import StepUserData from "./Registraion/StepUserData";
import StepRegisterBot from "./Registraion/StepRegisterBot";
import StepChoiceFirms from "./Registraion/StepChoiceFirms";
import {parsePhoneNumber, validatePhoneNumber} from "../../../../utils/phone_country";
import Loader from "../../../Loader";
import {createFormDataArrayObj, utf8ToB64} from "../../../../utils/common";
import IconArrow from "../../../../assets/Icons/IconArrow";
import {authThunkGetRegisterClientsFirms} from "../../../../redux/firms-thunk";

// Create a context for Formik
const FormikContext = createContext();

// Custom hook to use Formik context
export const useFormikContextProviderRegister = () => {
    return useContext(FormikContext);
};
const STEPS_COUNT = {
    email: 4,
    phone: 4,
}
const INITIAL_FORMS_VALUES = {
    name: '',
    password: '',
    email: '',
    phone: '',
    firms: [],
    message_channel: '',
}
const Registration = ({children = null, onSwitchTab}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {
        auth_lookup, isAuthFind, isAuthPhoneIsNotSendBot, isSendShortPassword,
        available_message_channel_list, full_message_channel_list, message_channel,
        isNewUser
    } = useAuthLookup()
    const registration_state = useSelector(registrationSelected);

    const [activeStep, setActiveStep] = useState(1);
    const [stepCount, setStepCount] = useState(1);


    const formik = useFormik({
        initialValues: {
            ...INITIAL_FORMS_VALUES,
            email: registration_state.email || '',
            phone: registration_state.phone || '',
        }, validate: (values) => {
            const errors = {};
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (values.email && !emailRegex.test(values.email)) {
                errors['email'] = t("errors.email");
            }
            const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_+=])[A-Za-z\d!@#$%^&*()-_+=]{8,30}$/;
            if (values.password && !regexPass.test(values.password)) {
                errors['password'] = t("errors.password");

            }
            const {countryCode, phoneNumberWithoutCode, mask} = parsePhoneNumber(values['phone']);
            const isValidPhone = validatePhoneNumber(countryCode, phoneNumberWithoutCode);
            if (!isValidPhone) {
                errors['phone'] =  t("errors.phone");
            }

            // Add more validations for other fields

            return errors;
        },
        onSubmit: (values) => {
            let createData = values.firms.map((firm_id) => {
                return {[`firms[]`]: firm_id}
            })
            createData.push({name: values.name})
            createData.push({phone: values.phone})
            createData.push({email: values.email})
            createData.push({password: values.password})
            createData.push({message_channel: values.message_channel})
            let api_data = createFormDataArrayObj(createData);
            dispatch(authThunkRegisterCheckUser(api_data));
        }

    });
    useEffect(() => {
        let _uri = process.env.NODE_ENV === "development" ? 'https://ifurn.pro/' : window.location.origin;

        let from_static_uri =  utf8ToB64(_uri);
        // let from_static_uri = utf8ToB64('https://ifurn.pro/')
        dispatch(authThunkGetRegisterClientsFirms(from_static_uri))
    }, []);
    useEffect(() => {

        if (isNewUser && activeStep === 1) {
            setActiveStep((prevStep) => 2);
            setStepCount((prevStep) => 2);
        }
    }, [isNewUser, auth_lookup]);

    const stepRegister = useMemo(() => {
        let lookup = formik.values.phone && !formik.values.email ? 'phone' : 'email';
        let steps_switch = {
            email: {
                'step_1': 1,
                'step_2': 2,
                'step_3': 3,
                'step_4': 4,
            },
            phone: {
                'step_1': 1,
                'step_2': 2,
                'step_3': 3,
                'step_4': 4,
            }
        }
        return {
            step_amount: STEPS_COUNT[lookup],
            steps: steps_switch[lookup]

        }
    }, [formik.values]);

    const handleNext = (step = null) => {
        let steps = stepRegister.steps;
        let next_count = stepCount + 1
        let next_value_step = steps[`step_${next_count}`];
        if (next_value_step >= 6) return
        setActiveStep((prevStep) => next_value_step);
        setStepCount((prevStep) => next_count);

    };
    const handlerPrev = () => {
        let steps = stepRegister.steps;
        let current = steps[`step_${stepCount}`];
        let prev = steps[`step_${stepCount - 1}`];
        if (current === 1 || prev < 0) return
        setActiveStep((prevStep) => prev);
        setStepCount(prev);

    }
    // useImperativeHandle(ref, () => ({
    //
    //     resetFormRegister() {
    //         formik.resetForm()
    //     }
    //
    // }));
    const getStepComponent = () => {
        switch (activeStep) {
            case 1:
                return <StepLookup onSwitchTab={onSwitchTab}/>;
            case 2:
                return <StepUserData handleNext={handleNext}/>;
            case 3:
                return <StepRegisterBot handleNext={handleNext} full_message_channel_list={full_message_channel_list}
                                        available_message_channel_list={available_message_channel_list}/>;
            case 4:
                return <StepChoiceFirms/>;
            // case 5:
            //     return <StepChoiceFirms/>
            default:
                return null;
        }
    };


    return (
        <div className="">
            {registration_state.isSuccessful ? <div className={
                    'min-H'
                }>
                    <div className="card-form form-sm">
                        <h5 className={'text-center form-sub-title'}>{t('success_register_title')}</h5>
                        <div className="d-flex justify-center">
                            {children}
                        </div>
                    </div>
                </div>
                :
                <>
                    <div className="d-flex register-page-header justify-center">
                        {stepCount !== 1 &&
                            <span className="custom-btn-prev" onClick={handlerPrev}> <IconArrow/> </span>}
                        <h5 className={'form-title mb-0'}>{t("registration_title")} {t("step_title")} {stepCount} / {stepRegister.step_amount} </h5>

                    </div>
                    <div className={'form-sm card-form'} style={{borderWidth: `${activeStep == 3 ? '0': '1px'}`}}>
                        <FormikContext.Provider value={formik}>
                            {getStepComponent()}
                            {auth_lookup.isLoading && <Loader/>}
                        </FormikContext.Provider>
                        {auth_lookup.isLoader && <Loader/>}

                        {children ? <div className="switch-form-tab">
                            {children}
                        </div> : null}

                    </div>
                </>
            }
        </div>

    );
};

export default Registration;