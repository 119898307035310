import React from 'react';
import {useFormikContextProviderLogin} from "../Login";
import {useTranslation} from "react-i18next";
import InputFiled from "../../../../ui/InputFiled";
import IconEdit from "../../../../../assets/Icons/IconEdit";
import AuthAccountPassword from "./AuthAccountPassword";
import Button from "../../../../ui/Button";
import {toast} from "react-toastify";

const LoginEmail = ({onReqAccountAccess, onHandlerEdit, isButtonDisabled, seconds}) => {
    const {t} = useTranslation();
    const {values, handleChange, setFieldValue, validateForm} = useFormikContextProviderLogin();
    if (!values.isRadioLoginEmail) return null
    const handleValidate = () => {
        validateForm().then((errors) => {
            console.log(errors)
            // Handle the validation errors
            if (!Object.keys(errors).includes('email')) {
                onReqAccountAccess({send_password: 0})


            } else if (Object.keys(errors).includes('email')) {
                let error_msg = `${errors['email'] || ''}`
                toast.error(`${error_msg}`)
            }
        });
    };
    const handlerSendPasswordOneTime = ()=>{
        onReqAccountAccess({send_password: 1})
    }

    return (
        <div>
            <div className={'d-flex align-items-center'}>
                <div className="flex-auto">
                    <InputFiled label={t('feedback_form.form.email')}
                                className={'filed-border'} id="email"
                                disabled={!values.isEditEmailOrPhone}
                                readonly={!values.isEditEmailOrPhone}
                                name="email"
                                type="email" value={values.email} onChange={handleChange}/>
                </div>
                {!values.isEditEmailOrPhone &&
                    <IconEdit style={{marginLeft: '8px', cursor: "pointer"}} onClick={onHandlerEdit}/>}
            </div>
            {values.isEditEmailOrPhone &&
                <Button type={'button'} style={{margin: '0 auto'}}
                        onClick={handleValidate}>{t("feedback_form.form.submit")}
                </Button>}
            {!values.isEditEmailOrPhone && <>
                <AuthAccountPassword handlerSendPasswordOneTime={handlerSendPasswordOneTime}
                    resendChildrenEmail={
                    <span onClick={() => onReqAccountAccess({send_password: 1})}
                          className={`resend-password ${isButtonDisabled ? 'disabled' : ''}`}>
                        {t("resend_title")} {isButtonDisabled && <span className="seconds">{seconds}</span>}</span>

                }/>

            </>}

        </div>
    );
};

export default LoginEmail;