export const LS_FIRM = 'ifrurn.pro_firm';
export const LS_LANG = 'ifrurn.pro_lang';
export const LS_CLIENT_TOKEN = 'ifrurn.pro_client_token';
export const LS_USER_TOKEN = 'ifrurn.pro_user_token';
export const LS_TECHNICAL_WORKS = "ifrurn.pro_technical_works"
let NOT_REMOVE_ATTR_LS = ["length", "imgApiUrl", "setItem", "getItem", "removeItem", "clear", "key",LS_TECHNICAL_WORKS,  LS_LANG]
export const clearAllStorage = () => {
    let copyLS = localStorage;
    for (var attr in copyLS) {
        if(!NOT_REMOVE_ATTR_LS.includes(attr)){
        // if (attr !== LS_TECHNICAL_WORKS && attr !== LS_LANG && attr !== "imgApiUrl" && attr !== "setItem" && attr !== "getItem" && attr !== "removeItem" && attr !== "clear" && attr !== "key") {
            window.localStorage.removeItem(`${attr}`);
        }
    }
};
