import {useCallback, useEffect, useState} from 'react';
import {scroller} from "react-scroll";
import {useSelector} from "react-redux";
import {authProtectedPageSelected} from "../redux/auth/auht-selector";
import {TAB_HASH_IDS} from "../routing_hash";

const useHashTabs = (defaultTab, tabIds) => {
    const [currentTab, setCurrentTab] = useState(defaultTab);
    const verification = useSelector(authProtectedPageSelected);
    useEffect(() => {

        const handleHashChange = () => {

            const hash = window.location.hash.slice(1);
            let isAuthed = hash === TAB_HASH_IDS.login && verification.user && !verification.isLoading;
            let isNotAuthed = hash === TAB_HASH_IDS.cabinet && !verification.user && !verification.isLoading;
            let checkAccessHash = isAuthed || isNotAuthed;
            if (tabIds.includes(hash) && !checkAccessHash && currentTab !== hash) {
                setCurrentTab(hash);

            } else if(currentTab !== hash) {
                // Если хеш не совпадает с доступными табами, переключаемся на таб по умолчанию
                setCurrentTab(defaultTab);
                window.location.hash = defaultTab;
            }
        };

        // Добавляем слушатель события изменения хеша
        window.addEventListener('hashchange', handleHashChange);

        // Вызываем обработчик для текущего хеша
        handleHashChange();

        // Удаляем слушатель события при размонтировании компонента
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [defaultTab, tabIds]);

    const switchTab = useCallback(tab => {
        // Обновляем хеш и вызываем обработчик изменения хеша
        if (!tab) {
            // var uri = window.location.toString();
            // if (uri.indexOf("#") > 0) {
            //     var clean_uri = uri.substring(0, uri.indexOf("#"));
            //     window.history.replaceState({}, document.title, clean_uri);
            // }
        }
        window.location.hash = tab;
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    const switchTabAndScrolledSection = useCallback((tab, section_id) => {
        window.location.hash = tab;
        if (section_id) {
            setTimeout(() => {
                scroller.scrollTo(section_id, {smooth: true, duration: 700, offset: -240})
            }, 4)
        }
    }, [])

    return [
        currentTab,
        switchTab,
        switchTabAndScrolledSection
    ]
};

export default useHashTabs;
