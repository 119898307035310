import ReactPlayer from "react-player";
import video from "../picture/image/video.png";
import React, {useState} from "react";
import {useSelector} from "react-redux";

const videoList = {
    provider: {
        'en': 'https://youtu.be/z84vimh7UzA',
        'ru': 'https://youtu.be/bv-IGFgxrxo',
        'ua': 'https://youtu.be/AbYg83H1NRc',
        'ro': 'https://youtu.be/NQmFryDMW2U',
        'pl': 'https://youtu.be/zwKhfiPa578',
        'he': 'https://youtu.be/J7WJBb_azJ8'

    },
    furniture: {
        'en': 'https://youtu.be/3Qe2pIuG15Y',
        'ru': 'https://youtu.be/ia38Rid0fO0',
        'ua': 'https://youtu.be/GtuHPXVYoQU',
        'ro': 'https://youtu.be/GdxmKkL9FX4',
        'pl': 'https://youtu.be/sedFP9Bc6-E',
        'he': 'https://youtu.be/suPYppp6gJE'
    },
    box: 'https://youtu.be/-RVq2_cnOs8'
}
export const VideoWrap = ({videoType, isBox = false}) => {
    const locale = useSelector(state => state.appPage.locale);
    return <div className="videoPlayer player-substrate">
        {isBox ? <ReactPlayer url={videoList[videoType]}
                              width={'100%'}
                              controls={true}
                              volume={1}
                              height={100}
                              playing
                              loop={true}
                              muted={true}
            /> :
            videoList[videoType][locale] !== '' ?
                <ReactPlayer url={videoList[videoType][locale]}
                             width={'100%'}
                             controls={true}
                             volume={1}
                             height={100}
                             playing
                             loop={true}
                             muted={true}
                    // onError={()=> }
                    // onReady={()=> setIsError(false)}
                /> : <img src={video} className={'play-error'} alt=""/>

        }
    </div>
}