import React, {useCallback, useEffect, useMemo} from 'react';
import "./main.css";
import {
    authThunkLogoutSystem,
    authThunkUserAuthenticatedAndRedirect,
    networkLoginUserThunk
} from "../../redux/auth/auth-thunk";
import {authThunkClientsFirms} from "../../redux/firms-thunk";
import {useDispatch, useSelector} from "react-redux";
import {roleUserSelected, verificationSelected} from "../../redux/auth/auht-selector";
import {utf8ToB64} from "../../utils/common";
import Firms from "./component/Firms";
import {CRM_DATA, ROLE_ACCESS_SITE} from "../../consts/common_consts";
import AccessSite from "./component/AccessSite";
import UserData from "./component/UserData";
import {useTranslation} from "react-i18next";
import {LS_FIRM} from "../../consts/localStorageNaming";
import {TAB_HASH_IDS} from "../../routing_hash";
import {messageChannelsThunk} from "../../redux/message-channels-thunk";
import MessageChannels from "./component/MessageChannels";
import SocialNetwork from "./component/SocialNetwork";

const Cabinet = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const verification_state = useSelector(verificationSelected);
    const roleUser = useSelector(roleUserSelected);
    useEffect(() => {
        dispatch(authThunkUserAuthenticatedAndRedirect(localStorage.getItem(LS_FIRM), null, TAB_HASH_IDS.login))
    }, []);
    useEffect(() => {
        let _uri = process.env.NODE_ENV === "development" ? 'https://ifurn.pro/' : window.location.origin;
        let from_static_uri =  utf8ToB64(_uri);
        dispatch(authThunkClientsFirms(from_static_uri));
        dispatch(messageChannelsThunk());
        dispatch(networkLoginUserThunk())
    }, [])


    const onLogoutSystem = useCallback(() => {
        dispatch(authThunkLogoutSystem())
    }, [dispatch]);
    const assessSite = useMemo(() => {
        if (!roleUser.title || !roleUser.isAdmin) return null;
        return ROLE_ACCESS_SITE[roleUser.title]
    }, [roleUser]);

    useEffect(()=>{
        if(roleUser.isManager && !roleUser.isAdmin) {
            window.location = CRM_DATA.uri;
        }
    }, [roleUser])
    return (
        <section className="cabinet-section min-H">
            <div className={'container'}>
                <div className="row">
                    <h5 className="">{t('cabinet_title')}</h5>
                    <div className="cabinet-user-data">
                        {verification_state && verification_state.user &&
                            <UserData onLogoutSystem={onLogoutSystem} t={t} isClient={roleUser.isClient} {...verification_state.user} />}
                        {!!assessSite && verification_state.user.firm_urls && <AccessSite entry={assessSite} t={t} firm_urls={verification_state.user.firm_urls} />}
                        {roleUser.isClient && <Firms/>}

                        <MessageChannels/>
                        <SocialNetwork/>
                    </div>
                    {/*{roleUser.isClient && <Firms />}*/}
                </div>
            </div>
        </section>

    );
};

export default Cabinet;