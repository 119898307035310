import React from 'react';

const De = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list">
                <h4>Anleitung zur Datenlöschung:</h4>
                <p>Wenn Sie Ihre Daten von <a href="https://ifurn.pro/" className="" target={'_blank'}> iFurn.pro</a> löschen
                    möchten, teilen Sie uns bitte Ihre Kontaktdaten für die Löschung und den Grund mit.</p>
            </div>
        </div>

    );
};

export default De;