import React, {createContext, useContext, useEffect, useState} from 'react';
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {authThunkAccountAccess, authThunkLogin} from "../../../../redux/auth/auth-thunk";
import {loginSelected} from "../../../../redux/auth/auht-selector";
import Loader from "../../../Loader";
import {authLookupAC, registerUserAC} from "../../../../redux/auth/auth-action";
import useAuthLookup from "../../../../Hook/useAuthLookup";
import LoginEmail from "./Login/LoginEmail";
import LoginPhone from "./Login/LoginPhone";
import {parsePhoneNumber, validatePhoneNumber} from "../../../../utils/phone_country";

// Create a context for Formik
const FormikContext = createContext();

// Custom hook to use Formik context
export const useFormikContextProviderLogin = () => {
    return useContext(FormikContext);
};

const Login =({children = null, onSwitchTab}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {auth_lookup, isAuthFind, isSendShortPassword} = useAuthLookup();

    const login_state = useSelector(loginSelected);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [seconds, setSeconds] = useState(60);

    const formik = useFormik({
        initialValues: {
            password: '',
            master_password: '',
            message_channel: '',
            email: auth_lookup.data && auth_lookup.data.lookup === 'email' ? login_state.email : '',
            phone: auth_lookup.data && auth_lookup.data.lookup === 'phone' ? login_state.phone : '',

            //**авторизация по email = true or  phone=false */
            isRadioLoginEmail: true,
            //** тип пароля короткий,мастер */
            isRadioShortPassword: false,
            //** редактировать поле email or phone */
            isEditEmailOrPhone: true,
        },
        validate: (values) => {
            const errors = {};
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if ((!values.email || !emailRegex.test(values.email)) && values.isRadioLoginEmail) {
                errors['email'] = t("errors.email");
            }
            const {countryCode, phoneNumberWithoutCode, mask} = parsePhoneNumber(values['phone']);
            const isValidPhone = validatePhoneNumber(countryCode, phoneNumberWithoutCode);
            if (!isValidPhone && !values.isRadioLoginEmail) {
                errors['phone'] = t("errors.phone");
            }
            return errors;
        },
        onSubmit: (values) => {
            let auth = {
                email: values.isRadioLoginEmail ? values.email : values.phone,
                password: isAuthFind && !isSendShortPassword ? values.master_password : values.isRadioShortPassword ? values.password : values.master_password
            }
            dispatch(authThunkLogin(auth))
        }

    });
    //** если пользователь найден по мылу или телефону*/
    useEffect(() => {
        if (isAuthFind) {
            formik.setFieldValue('isEditEmailOrPhone', false)
        }
    }, [isAuthFind, formik.setFieldValue, auth_lookup])

    //** если прользователя перекинуло с формы реги*/
    useEffect(() => {
        let email_field_data = login_state.email || ''
        let phone_field_data = login_state.phone || ''
        if (!!email_field_data || !!phone_field_data) {
            let type_login_radio = !!email_field_data || !phone_field_data;
            formik.setFieldValue('isRadioLoginEmail', type_login_radio)
            formik.setFieldValue('email', email_field_data);
            formik.setFieldValue('phone', phone_field_data);

            formik.setFieldValue('isEditEmailOrPhone', true);
        }
    }, [login_state, formik.setFieldValue])


    const onSwitchTabRegister = () => {
        onSwitchTab(false);
        dispatch(registerUserAC({
            email: formik.values.isRadioLoginEmail ? formik.values.email : '',
            phone: !formik.values.isRadioLoginEmail ? formik.values.phone : '',
        }));
    }
    const onReqAccountAccess = ({send_password = 0, message_channel = null}) => {
        if (send_password) {
            setIsButtonDisabled(true);
        } else if (isButtonDisabled) {
            setIsButtonDisabled(false)
        }
        let login = formik.values.isRadioLoginEmail ? formik.values.email : formik.values.phone;
        //** если пользователя нету переключаем таб на регестрацию*/
        dispatch(authThunkAccountAccess({
            login: login,
            send_password: send_password, message_channel: message_channel
        }, onSwitchTabRegister));
        if (send_password) {
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 60000);
        }
    }

    useEffect(() => {
        if (isAuthFind && !isSendShortPassword) {
            setIsButtonDisabled(false);
        }
    }, [isSendShortPassword, isAuthFind])
    useEffect(() => {
        let timerInterval = 0;
        if (isButtonDisabled) {
            timerInterval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);

        } else {
            clearInterval(timerInterval);
            setSeconds(60)
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [isButtonDisabled]);

    const onHandlerEdit = () => {
        dispatch(authLookupAC({isLoading: false, data: null}))
        formik.setFieldValue('isEditEmailOrPhone', true);
        let is_type_login = formik.values.isRadioLoginEmail;
        formik.setFieldValue('isRadioShortPassword', !is_type_login)
        setSeconds(60);
    }
    const handlerSwitchLogin = (isLoginType) => {
        dispatch(authLookupAC({isLoading: false, data: null}));
        setIsButtonDisabled(false);
        formik.resetForm();
        formik.setFieldValue('isRadioLoginEmail', isLoginType)
        formik.setFieldValue('isRadioShortPassword', !isLoginType)
    }

    return (
        <>
            {login_state.isLoader || (auth_lookup && auth_lookup.isLoader) && <Loader/>}
            <h5 className={'form-title'}> {t('login_page.login')}</h5>
            <div className={'form-sm card-form'} role="presentation">
                <FormikContext.Provider value={formik} autoComplete={"off"}>
                    <div className="choice-type-login d-flex flex-row">
                        <div className="item d-flex align-items-center">
                            <input type="radio" value="1" id={'email'} checked={formik.values.isRadioLoginEmail}
                                   className={'flex-auto'}
                                   onChange={() => handlerSwitchLogin(true)}
                            />
                            <label htmlFor="email">
                                {t('login_page.email')}
                            </label>
                        </div>
                        <div className="item d-flex align-items-center">
                            <input type="radio" value="0" id={'tel'} checked={!formik.values.isRadioLoginEmail}
                                   className={'flex-auto'}
                                   onChange={() =>
                                       handlerSwitchLogin(false)}
                            />
                            <label htmlFor="tel">
                                {t('login_page.phone')}
                            </label>
                        </div>

                    </div>
                    {/*<form onSubmit={(e)=>e.preventDefault()} autoComplete={'off'}>*/}
                    <LoginEmail onReqAccountAccess={onReqAccountAccess} onHandlerEdit={onHandlerEdit} seconds={seconds}
                                isButtonDisabled={isButtonDisabled}/>
                    <LoginPhone onReqAccountAccess={onReqAccountAccess} onHandlerEdit={onHandlerEdit} seconds={seconds}
                                isButtonDisabled={isButtonDisabled}/>
                    {/*</form>*/}
                </FormikContext.Provider>


                {children ? <div className="switch-form-tab">
                    {children}
                </div> : null}
            </div>
            {/*<TelBot/>*/}
            {/*{isAuthPhoneIsNotSendBot && <TelBot/>}*/}
        </>

    );
};

export default Login;