import React, {useCallback, useEffect, useState} from 'react';
import InputMask from "react-input-mask";
import "./fieldPhoneMask.css"
import {parsePhoneNumber, SELECT_OPTIONS} from "../../utils/phone_country";


const FieldPhoneMask = ({onChange, label = null, value = '', ...resetInput}) => {
    const [inputMask, setInputMask] = useState()
    const [codeCountry, setCodeCountry] = useState('ua');
    const onChangeTel = useCallback((event) => {
        setInputMask(event.target.value);
        onChange(`${SELECT_OPTIONS[codeCountry].code}${event.target.value}`)
    }, [onChange, codeCountry, setInputMask, inputMask]);
    const onChangeCodeSelect = (event) => {
        let key = event.target.value;
        setCodeCountry(key)
    }
    useEffect(()=>{
        if(value){
            let phone = parsePhoneNumber(value);
            setCodeCountry(phone.countryCode);
            setInputMask(phone.phoneNumberWithoutCode);

        }
    }, [value])
    return (
        <div className={'filed form-group '}>
            {label && <label>{label}</label>}
            <div className=" filed-phone-mask">
                <select name="" id="" value={codeCountry} className={''} onChange={onChangeCodeSelect}>
                    {Object.entries(SELECT_OPTIONS).map(([key, value]) => {
                        return <option value={key} key={key}
                                       selected={codeCountry === key}>({value.label}) {value.code}</option>
                    })}
                </select>
                <InputMask
                    className={''}
                    value={inputMask}
                    mask={SELECT_OPTIONS[codeCountry].mask}
                    placholder={SELECT_OPTIONS[codeCountry].mask}
                    onChange={onChangeTel}
                    type={'tel'}
                    required={true}
                    {...resetInput}
                />
            </div>
        </div>
    );
};

export default FieldPhoneMask;