import React from 'react';

const Lt = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list">
                <h4>Duomenų trynimo instrukcija:</h4>
                <p>Jei norite ištrinti savo duomenis iš <a href="https://ifurn.pro/" className="" target={'_blank'}> iFurn.pro</a>,
                    prašome pranešti savo kontaktinius duomenis trynimui ir pateikti priežastį.</p>
            </div>
        </div>
    );
};

export default Lt;