import {LS_TECHNICAL_WORKS} from "../consts/localStorageNaming";

export const HASH_PAUSE_SITE = "technical-works"

function watcherApiPauseSite(response) {
    let pause_site = response.hasOwnProperty('pause_site') ?  Number(response.pause_site) : null;
    if (!pause_site) {
        localStorage.removeItem(LS_TECHNICAL_WORKS);
        return
    }
    localStorage.setItem(LS_TECHNICAL_WORKS, response.date_start);
    window.location.hash = HASH_PAUSE_SITE;


}

export {watcherApiPauseSite}