import React from 'react';

const Lv = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list"><h4>Pakalpojumu cenrādis:</h4><p>Cena tiek veidota atbilstoši klienta vajadzībām.</p><p>Tiek ņemts vērā darba vietu skaits, pasūtījumu skaits mēnesī, izmantotās iekārtas skaits.</p><p>Pakalpojums ir izstrādāts kā universāls konstruktors, viegli pielāgojams jūsu vajadzībām.</p><p>Sazinieties ar mums jums ērtā veidā, un mēs sagatavosim komerciālu piedāvājumu.</p>
            </div>
        </div>

    );
};

export default Lv;