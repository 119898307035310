import React from "react";
import HomeContainer from "../Home/HomeContainer";
import ServiceProvidersContainer from "../ServiceProviders/ServiceProvidersContainer";
import {useTranslation} from "react-i18next";
import OrdersServiceContainer from "../ServiceProviders/OrdersServiceContainer";
import {FurnitureProviders} from "../ServiceProviders/FurnitureProviders";
import {useDispatch, useSelector} from "react-redux";
import TermsUse from "../TermsUse";
import {RequestInvoicePayModal} from "../RequestInvoicePayModal";
import {TAB_HASH_IDS} from "../../routing_hash";
import LoginPlatform from "../LoginPlatform/LoginPlatform";
import {verificationSelected} from "../../redux/auth/auht-selector";
import Cabinet from "../Cabinet";
import IconUser from "../../assets/Icons/IconUser";
import NewsContainer from "../News/NewsContainer";
import {getNewsSelected} from "../../redux/selector/app-selector";
import TechnicalWorksPage from "../TechnicalWorksPage";


const SectionTabsContainer = ({currentTab, switchTab}) => {
    const isTermsUse = useSelector(state => state.appPage.isTermsUse);
    const verification = useSelector(verificationSelected);
    const {t} = useTranslation();
    const news_state = useSelector(getNewsSelected)
    const styleMargin = {
        margin: '0'
    }

    return <>
        <div className="section-tabs" style={currentTab === TAB_HASH_IDS.login ? styleMargin : {}}>
            <div className="container">
                <div className="row section-wrap">
                    <div key={'tab-home'} className={currentTab === TAB_HASH_IDS.home ? 'active item-tab' : ' item-tab'}
                         onClick={() => switchTab(TAB_HASH_IDS.home)}>{t('tabs.tab_4')}
                    </div>

                    <div key={'tab-furniture'}
                         className={currentTab === TAB_HASH_IDS.furniture ? 'active item-tab' : ' item-tab'}
                         onClick={() => switchTab(TAB_HASH_IDS.furniture)}>{t('tabs.tab_1')}
                    </div>
                    <div key={'tab-provider'}
                         className={currentTab === TAB_HASH_IDS.provider ? 'active item-tab' : ' item-tab'}
                         onClick={() => switchTab(TAB_HASH_IDS.provider)}>{t('tabs.tab_2')}
                    </div>
                    <div key={'tab-orderService'}
                         className={currentTab === TAB_HASH_IDS.orderService ? 'active item-tab' : ' item-tab'}
                         onClick={() => switchTab(TAB_HASH_IDS.orderService)}>{t('tabs.tab_3')}
                    </div>
                    {news_state.pages &&
                        <div key={'tab-news'}
                             className={currentTab === TAB_HASH_IDS.news ? 'active item-tab' : ' item-tab'}
                             onClick={() => switchTab(TAB_HASH_IDS.news)}> {t('news_title')}
                        </div>}
                    {  verification.user ?
                        <div key={'tab-cabinet'}
                             className={currentTab === TAB_HASH_IDS.cabinet ? 'active item-tab' : ' item-tab'}
                             onClick={() => {
                                 switchTab(TAB_HASH_IDS.cabinet)
                             }}>
                            <IconUser/>
                        </div> :

                        <div key={'tab-login'}
                             className={currentTab === TAB_HASH_IDS.login ? 'active item-tab' : ' item-tab'}
                             onClick={() => {
                                 switchTab(TAB_HASH_IDS.login)
                             }}> {t("come_in_title")}
                        </div>}

                </div>
            </div>

        </div>
        {currentTab === TAB_HASH_IDS.home && <HomeContainer key={'page-home'}/>}
        {currentTab === TAB_HASH_IDS.furniture && <FurnitureProviders key={'page-furniture'}/>}
        {currentTab === TAB_HASH_IDS.orderService && <OrdersServiceContainer key={'page-orderService'}/>}
        {currentTab === TAB_HASH_IDS.provider && <ServiceProvidersContainer key={'page-provider'}/>}
        {currentTab === TAB_HASH_IDS.login && <LoginPlatform key={'page-login'}/>}
        {currentTab === TAB_HASH_IDS.cabinet && <Cabinet key={'page-cabinet'}/>}
        {currentTab === TAB_HASH_IDS.technical_works && <TechnicalWorksPage key={'technical-works'}/>}
        {news_state.pages && currentTab === TAB_HASH_IDS.news && <NewsContainer key={'page-news'}/>}
        {isTermsUse && <TermsUse key={'terms-use-modal'}/>}

        <RequestInvoicePayModal key={'request-invoice-modal'}/>
    </>
}

export default SectionTabsContainer;