import React from 'react';

const Ua = () => {
    return (
            <div className="info-pages-main-content">
                <div className="info-page-inner-content"><h1 className="privacy-title"> Договір публічної оферти</h1><p
                    className="privacy-text">Загальні положення</p>
                    <ul>
                        <li>1.1. Справжня оферта є офіційною пропозицією “ФОП Коток І.М.”, далі за текстом —
                            «Продавець», укласти Договір купівлі-продажу товарів дистанційним способом, тобто через
                            Інтернет-магазин, далі за текстом — «Договір», та розміщує Публічну оферту (пропозиція) на
                            офіційному інтернет-сайті Продавця<a href="https://ifurn.pro" target="_blank"
                                                                 title="ifurn.pro"
                                                                 className="privacy-link">«https://ifurn.pro», </a><a
                                href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                                className="privacy-link">«https://newgo.ifurn.pro», </a><a href="https://crm.ifurn.pro"
                                                                                           target="_blank"
                                                                                           title="crm.ifurn.pro"
                                                                                           className="privacy-link">«https://crm.ifurn.pro»</a>(далі
                            - «Інтернет-сайт»).
                        </li>
                        <li> 1.2. Моментом повного та безумовного прийняття Покупцем пропозиції Продавця (акцептом)
                            укласти електронний договір купівлі-продажу товарів, вважається факт оплати Покупцем
                            замовлення на умовах цього Договору, у строки та за цінами, зазначеними на Інтернет-сайті
                            Продавця.
                        </li>
                        <li> 2.1. У цій оферті, якщо контекст не вимагає іншого, нижченаведені терміни мають такі
                            значення:
                        </li>
                        <ul>
                            <li>* «товар» - складові меблів, напівфабрикати, аксесуари, комплектуючі та супровідні
                                предмети;
                            </li>
                            <li>* «Інтернет-магазин» - відповідно до Закону України «Про електронну комерцію», засіб для
                                подання або реалізації товару, роботи чи послуги шляхом вчинення електронної угоди.
                            </li>
                            <li>* «Продавець» - компанія, що реалізує товари, які представлені на Інтернет-сайті.</li>
                            <li>* «Покупець» - фізична особа, яка уклала з Продавцем Договір на умовах, викладених
                                нижче.
                            </li>
                            <li>* «Замовлення» - вибір окремих позицій із переліку товарів, зазначених Покупцем під час
                                розміщення замовлення та проведення оплати.
                            </li>
                        </ul>
                        <p className="privacy-text">Предмет договору</p>
                        <li>3.1. Продавець зобов'язується передати у власність Покупця Товар, а Покупець зобов'язується
                            сплатити та прийняти Товар на умовах цього Договору.
                        </li>
                        <ul>Цей Договір регулює купівлю-продаж товарів в Інтернет-магазині, зокрема:
                            <li> - Добровільний вибір Покупцем товарів в Інтернет-магазині;</li>
                            <li> - Самостійне оформлення Покупцем замовлення в Інтернет-магазині;</li>
                            <li> - оплата Покупцем замовлення, оформленого в Інтернет-магазині;</li>
                            <li> - обробка та доставлення замовлення Покупцеві у власність на умовах цього Договору.
                            </li>
                        </ul>
                        <p className="privacy-text">Порядок оформлення замовлення</p>
                        <li>4.1. Покупець має право оформити замовлення на будь-який товар, представлений на Сайті
                            Інтернет-магазину та наявний.
                        </li>
                        <li>4.2. Кожна позиція може бути представлена в замовлення у будь-якій кількості.</li>
                        <li>4.3. За відсутності товару на складі, Менеджер компанії зобов'язаний повідомити Покупця
                            (телефоном або через електронну пошту).
                        </li>
                        <li>4.4. За відсутності товару Покупець має право замінити його товаром аналогічної моделі,
                            відмовитися від цього товару, анулювати замовлення.
                        </li>
                        <p className="privacy-text">Черга сплати&nbsp;</p><p className="privacy-text">Банківський
                        платіж.&nbsp;</p>
                        <li>5.1. Оплата складає розрахунковий рахунок Продавця. Постачання товару Продавцем Покупцю
                            здійснюється після оплати рахунку способом, обумовленим в умовах доставлення п. 6.1, 6.2.
                        </li>
                        <li>5.2. При не надходженні коштів Інтернет-магазин залишає за собою право анулювати
                            замовлення.
                        </li>
                        <p className="privacy-text">Умови доставлення замовлення&nbsp;</p>
                        <li>6.1. Доставлення товарів, придбаних в Інтернет-магазині, здійснюється самовивозом із
                            зазначеної в рахунку адреси, транспортною компанією або іншим способом, обумовленим Покупцем
                            з Продавцем.
                        </li>
                        <li>6.2. Разом із замовленням Покупцеві надаються документи відповідно до законодавства
                            України.
                        </li>
                        <p className="privacy-text">Права та обов'язки сторін:&nbsp;</p>
                        <li>7.1. Продавець має право:</li>
                        <ul>
                            <li>- в односторонньому порядку призупинити надання послуг за цим договором у разі порушення
                                Покупцем умов цього договору.
                            </li>
                        </ul>
                        <li>7.2. Покупець зобов'язаний:</li>
                        <ul>
                            <li>- своєчасно сплатити та отримати замовлення на умовах цього договору.</li>
                        </ul>
                        <li>7.3. Покупець має право:</li>
                        <ul>
                            <li>- оформити замовлення в Інтернет-магазині;</li>
                            <li>- оформити електронний договір;</li>
                            <li>- вимагати від Продавця виконання умов цього Договору.</li>
                        </ul>
                        <p className="privacy-text">Відповідальність сторін&nbsp;</p>
                        <li>8.1. Сторони несуть відповідальність за невиконання або неналежне виконання умов цього
                            договору у порядку, передбаченому цим договором та чинним законодавством України.
                        </li>
                        <li>8.2. Продавець не несе відповідальності за:</li>
                        <ul>
                            <li>- Змінений виробником зовнішній вигляд Товару;</li>
                            <li>- за незначну невідповідність колірної гами товару, що може відрізнятися від оригіналу
                                товару виключно через різну колірну передачу моніторів персональних комп'ютерів окремих
                                моделей;
                            </li>
                            <li>- за зміст та правдивість інформації, що надається Покупцем під час оформлення
                                замовлення;
                            </li>
                            <li>- за затримку та перебої у наданні Послуг (обробки замовлення та доставлення товару), що
                                відбуваються з причин, що знаходяться поза сферою його контролю;
                            </li>
                            <li>- за протиправні незаконні дії, здійснені Покупцем за допомогою цього доступу до мережі
                                Інтернет;
                            </li>
                            <li>- за передачу Покупцем своїх мережевих ідентифікаторів - IP, MAC-адреси, логіну та
                                паролю третім особам;
                            </li>
                        </ul>
                        <li>8.3. Покупець, використовуючи наданий йому доступ до мережі Інтернет, самостійно несе
                            відповідальність за шкоду, заподіяну його діями (особисто, навіть якщо під її логіном
                            перебувала інша особа) особам або їхньому майну, юридичним особам, державі чи моральним
                            принципам моральності.
                        </li>
                        <li>8.4. У разі настання обставин непереборної сили сторони звільняються від виконання умов
                            цього договору. Під обставинами непереборної сили для цілей цього договору розуміються
                            події, що мають надзвичайний, непередбачуваний характер, які виключають або об'єктивно
                            заважають виконанню цього договору, настання яких Сторони не могли передбачити та запобігти
                            розумним способам.
                        </li>
                        <li>8.5. Сторони докладають максимум зусиль для вирішення будь-яких розбіжностей лише шляхом
                            переговорів.
                        </li>
                        <p className="privacy-text">Інші умови&nbsp;</p>
                        <li>9.1. Інтернет-магазин залишає за собою право в односторонньому порядку вносити зміни до
                            цього договору за умови попередньої публікації його на сайті<a
                                href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                                className="privacy-link">«https://newgo.ifurn.pro» </a></li>
                        <li>9.2. Інтернет-магазин створено для організації дистанційного способу продажу товарів через
                            Інтернет.
                        </li>
                        <li>9.3. Покупець відповідає за достовірність зазначеної при оформленні замовлення інформації.
                            При цьому, при здійсненні акцепту (оформлення замовлення та подальшої оплати товару)
                            Покупець надає Продавцю свою беззастережну згоду на збирання, обробку, зберігання,
                            використання своїх персональних даних у розумінні ЗУ «Про захист персональних даних».
                        </li>
                        <li>9.4. Оплата Покупцем оформленого в Інтернет-магазині замовлення означає повну згоду Покупця
                            з умовами договору купівлі-продажу (публічної оферти)
                        </li>
                        <li>9.5. Фактичною датою електронної угоди між сторонами є дата прийняття умов відповідно до ст.
                            11 Закону України "Про електронну комерцію"
                        </li>
                        <li>9.6. Використання ресурсу Інтернет-магазину для перегляду товару, а також для оформлення
                            замовлення для Покупця є безкоштовним.
                        </li>
                        <li>9.7. Інформація, що надається Покупцем, є конфіденційною. Інтернет-магазин використовує
                            інформацію про Покупця виключно з метою обробки замовлення, відправлення повідомлень
                            Покупцю, доставлення товару, здійснення взаєморозрахунків та ін.
                        </li>
                        <p className="privacy-text">Порядок повернення товару належної якості</p>
                        <li>10.1. Повернення товару до Інтернет-магазину здійснюється відповідно до чинного
                            законодавства України.
                        </li>
                        <p className="privacy-text">Термін дії договору</p>
                        <li>11.1. Електронний договір вважається укладеним з моменту отримання особою, яка направила
                            пропозицію укласти такий договір, відповіді про прийняття цієї пропозиції у порядку,
                            визначеному частиною шостою статті 11 Закону України "Про електронну комерцію".
                        </li>
                        <li>11.2. До закінчення терміну дії цей Договір може бути розірваний за взаємною згодою сторін
                            до моменту фактичного доставлення товару шляхом повернення коштів
                        </li>
                        <p className="privacy-text">Звертаємо вашу увагу, що інтернет-магазин «СПД Коток І.М.» на
                            офіційному інтернет-сайті<a href="https://newgo.ifurn.pro" target="_blank"
                                                        title="newgo.ifurn.pro"
                                                        className="privacy-link">«https://newgo.ifurn.pro», </a>має
                            право, відповідно до законодавства України, надавати право користування інтернет платформою
                            ФОП та юридичним особам для реалізації товару.</p></ul>
                </div>
            </div>
    );
};

export default Ua;