import React, {useState} from "react"
import Login from "../../picture/icon/Login";

export const ServiceItem = ({
                                phone = '',
                                email = '',
                                www = '',
                                link,
                                country = '',
                                translate_name = {},
                                adress = [],
                                locale = ''
                            }) => {
    return <tr className={"item"}>
        <td><a href={link} className="">{translate_name.hasOwnProperty(locale) ? translate_name[locale] : ""}</a></td>

        <td className="">{country}</td>
        <td className="">{phone}</td>
        <td className="">{email}</td>
        <td className=""><a href={www} className="">{www}</a></td>

        <td className="address">
            <Adress adress={adress} locale={locale}/>
        </td>
        <td>
            <a href={link} className={'link-register'} target={'_blank'} style={{
                width: '35px', height: '25px', display: "flex", justifyContent: 'center'
            }}>
                <Login/>
            </a>
        </td>
    </tr>
}

const Adress = ({adress, locale}) => {
    const [seeMoreAddress, setSeeMoreAddress] = useState(false);
    return <>
        {

            adress.length > 1 ?
                <>
                    <div onClick={() => setSeeMoreAddress(!seeMoreAddress)}>{adress[0][locale]}
                        <span className={seeMoreAddress ? "show more" : "more"}> </span></div>
                    {seeMoreAddress && <>
                        {adress.slice(1).map(item => <div>{item[locale]}</div>)}
                    </>}
                </>
                : <>
                    <div>{(Array.isArray(adress) && adress.length === 1) && adress[0][locale]}</div>
                </>
        }
    </>
}