import React from 'react';

const He = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>תנאי החזר:</h1>
                <ul>
                    <li>1. החזרים ניתנים תוך 14 יום</li>
                    <li>2. החזרים מבוצעים רק עבור השירותים שהוזמנו לראשונה. לדוגמא: אם הזמנתם שירות מנוי שלאחריו קיבלתם
                        החזר והזמנתם מאיתנו מנוי פעם שנייה אז לא תוכלו לקבל החזר על ההזמנה השנייה.
                    </li>
                    <li>3. ההחזרים מתבצעים בניכוי עמלת מערכות התשלום.</li>
                    <li>4. בתשלום באמצעות VISA ומאסטרקארד, הכסף מוחזר לאותו כרטיס שבו בוצע התשלום. לאחר שהחזרנו את
                        התשלום, הכסף מופיע בכרטיס תוך 3 ימי בנק. שימו לב שהתשלום הזה לא מופיע בדפי הכרטיס, אלא פשוט
                        מגדיל את הסכום בכרטיס. סוג זה של החזר זמין רק בתוך 30 יום לאחר התשלום.
                    </li>
                    <li>5. הכסף מוחזר לאותם הפרטים שמהם בוצע התשלום עבור השירותים.</li>
                    <li>6. החזרים מבוצעים תוך 10 ימים ממועד הגשת הבקשה.</li>
                    <li>7. בהחזרה מנוכים כל הבונוסים וההנחות.</li>
                </ul>
            </div>
        </div>
    );
};

export default He;