import React from 'react';

const Lv = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Maksājuma kartes informācijas pārsūtīšanas drošības politika</h1>
                <p>Veicot pasūtījuma apmaksu ar bankas karti, maksājumu apstrāde (ieskaitot kartes numura ievadi) notiek aizsargātā apstrādes sistēmas lapā. Tas nozīmē, ka Jūsu konfidenciālie dati (kartes dati, reģistrācijas dati utt.) nenonāk pie mums, to apstrāde ir pilnībā aizsargāta, un neviens nevar iegūt klienta personīgos vai bankas datus.</p>
                <p>Strādājot ar kartes datiem, tiek izmantots informācijas aizsardzības standarts, ko izstrādājušas starptautiskās maksājumu sistēmas Visa un MasterCard - Payment Card Industry Data Security Standard (PCI DSS), kas nodrošina drošu Bankas kartes turētāja datu apstrādi. Lietotā datu pārsūtīšanas tehnoloģija garantē drošību darījumos ar Bankas kartēm, izmantojot Secure Sockets Layer (SSL) protokolus.</p>
            </div>
        </div>

    );
};

export default Lv;