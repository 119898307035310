import React from 'react';
import InputFiled from "../../../../ui/InputFiled";
import {useFormikContextProviderLogin} from "../Login";
import {authThunkResetPassword} from "../../../../../redux/auth/auth-thunk";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const MasterPassword = ({isDisplayLabel=true}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {values, handleChange} = useFormikContextProviderLogin();
    const onResetPassword = () => {
        let login = values.isRadioLoginEmail ? values.email : values.phone
        dispatch(authThunkResetPassword({login: login}))
    }
    return (
        <>
            <InputFiled
                className={'filed-border'} id="master_password"
                label={isDisplayLabel ? t('login_page.master-password') : null}
                autoComplete={'off'}
                name="master_password"
                type="password" value={values.master_password}
                onChange={handleChange}/>

            <div className="forgot-password form-group cursor-pointer" onClick={onResetPassword}>
                {t('login_page.forgot')}
            </div>

        </>
    );
};

export default MasterPassword;