import React from 'react';

const En = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list"><h4>Price list for services:</h4><p>The price is formed
                according to the Client's needs.</p><p>Takes into account the number of workplaces, the number of orders
                per month, the amount of equipment used.</p><p>The service is built like a universal constructor, easily
                adaptable to your needs.</p><p>Contact us in a way convenient for you, and we will create a commercial
                proposal.</p></div>
        </div>
    );
};

export default En;