import React from 'react';

const De = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Sicherheitsrichtlinien für die Übermittlung von Zahlungskartendaten</h1>
                <p>Bei der Zahlung mit einer Kreditkarte erfolgt die Zahlungsabwicklung (einschließlich der Eingabe der Kartennummer) auf einer geschützten Seite des Prozessingsystems. Das bedeutet, dass Ihre vertraulichen Daten (Kartendaten, Registrierungsinformationen usw.) nicht zu uns gelangen; ihre Verarbeitung ist vollständig geschützt, und niemand kann persönliche oder Bankdaten des Kunden erhalten.</p>
                <p>Bei der Arbeit mit Kartendaten wird der Sicherheitsstandard angewendet, der von den internationalen Zahlungssystemen Visa und MasterCard entwickelt wurde - Payment Card Industry Data Security Standard (PCI DSS), der eine sichere Verarbeitung der Daten des Karteninhabers gewährleistet. Die verwendete Datenübertragungstechnologie gewährleistet die Sicherheit von Transaktionen mit Bankkarten durch die Verwendung von Secure Sockets Layer (SSL)-Protokollen.</p>
            </div>
        </div>

    );
};

export default De;