import {AccountAPI} from "../api/AccountAPI";
import {toast} from "react-toastify";
import {accountMessageChannelsAC} from "./auth/auth-action";
import {createFormData} from "../utils/common";
import i18next from "i18next";


export const checkConnectMessageChannelsThunk = (message_channels) => async (dispatch, getState) => {

    try {
        await dispatch(messageChannelsThunk());
        const message_channel = getState().auth.message_channel;
        if (message_channel.data && message_channel.data.hasOwnProperty('connected_message_channel_list') && !message_channel.isError) {
            let isAddedBot = Object.keys(message_channel.data.connected_message_channel_list).find(key => key === message_channels);
            if (!isAddedBot) {
                let msg = `${i18next.t('message.not_connect_bot')} ${message_channels}`
                toast.info(msg)
            }
        }

    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}

export const messageChannelsThunk = () => async dispatch => {
    try {
        dispatch(accountMessageChannelsAC({isLoading: true, isError: false, error: null}))
        const response = await AccountAPI.getMessageChannels();
        if (response.hasOwnProperty('error')) {
            dispatch(accountMessageChannelsAC({isLoading: false, isError: true, error: response.error}))
            toast.error(response.error)
            return;
        }
        dispatch(accountMessageChannelsAC({isLoading: false, isError: false, error: null, data: response}));

    } catch (e) {
        dispatch(accountMessageChannelsAC({isLoading: false, isError: true}))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}

export const setDefaultMessageChanelThunk = (api_data) => async dispatch => {
    try {
        const formData = createFormData(api_data)
        dispatch(accountMessageChannelsAC({isLoading: true, isError: false, error: null}))
        const response_def = await AccountAPI.setDefaultMessageChannel(formData);
        if (response_def.hasOwnProperty('error')) {
            toast.error(response_def.error)
            return
        }

        dispatch(messageChannelsThunk());
    } catch (e) {
        dispatch(accountMessageChannelsAC({isLoading: false, isError: true}))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}

export const toggleEnabledMessageChannelThunk = (api_data) => async dispatch => {
    try {
        const formData = createFormData(api_data)
        dispatch(accountMessageChannelsAC({isLoading: true, isError: false, error: null}))
        const response_def = await AccountAPI.toggleEnabledMessageChannel(formData);
        if (response_def.hasOwnProperty('error')) {
            toast.error(response_def.error)
            return
        }

        dispatch(messageChannelsThunk());
    } catch (e) {
        dispatch(accountMessageChannelsAC({isLoading: false, isError: true}))
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        toast.error(msg);
    }
}