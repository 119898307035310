import React, {useEffect, useRef, useState} from 'react';
import "./main.css"
import NavTab from "./component/NavTab";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {authLookupAC, loginUserAC} from "../../redux/auth/auth-action";
import {authThunkUserAuthenticatedAndRedirect, facebookLoginThunk, googleLoginThunk} from "../../redux/auth/auth-thunk";
import {LS_FIRM} from "../../consts/localStorageNaming";
import {TAB_HASH_IDS} from "../../routing_hash";
import {helpArticleGetByIdToggleModalThunk} from "../../redux/helpl-aricle/help-article-thunk";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../redux/helpl-aricle/help-article-constants";
import Login from "./component/form/Login";
import {LoginSocialFacebook, LoginSocialGoogle} from "reactjs-social-login";
import {toast} from "react-toastify";
import {FacebookLoginButton, GoogleLoginButton} from "react-social-login-buttons";
import Registration from "./component/form/Registration";


export const CONST_TAB_SECTION_KEY = {
    sign_in: "sign_in",
    sign_up: 'sign_up'
}

export const TAB_SECTION = [
    {
        key: CONST_TAB_SECTION_KEY.sign_in,
        lable: 'login_page.login'
    },
    {
        key: CONST_TAB_SECTION_KEY.sign_up,
        lable: 'registration_title'
    },

]
const LoginPlatform = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [tab, setTab] = useState(CONST_TAB_SECTION_KEY.sign_in);
    // const ref_auth = useRef();
    // const ref_register = useRef();
    const onSwitchTab = (key) => {
        if (typeof key === 'boolean') {
            let is_convert_key_by_const = key ? CONST_TAB_SECTION_KEY.sign_in : CONST_TAB_SECTION_KEY.sign_up;

            setTab(is_convert_key_by_const);
        } else {
            setTab(key);

        }
        dispatch(authLookupAC({isLoading: false, data: null, error: null, isError: false}))

    }
    useEffect(() => {
        dispatch(authThunkUserAuthenticatedAndRedirect(localStorage.getItem(LS_FIRM), TAB_HASH_IDS.cabinet, null))
    }, []);
    const handlerHelpArticle = () => {
        dispatch(helpArticleGetByIdToggleModalThunk(HELP_ARTICLE_CONSTANTS_IDS.auth))
    }
    return (
        <section className={'section-login-platform'}>
            <div className="tab-root ">
                <div className="container">
                    <div className="row">
                        <NavTab t={t} onSwitchTab={(key) => onSwitchTab(key)} entry={TAB_SECTION} current={tab}
                                handlerHelpArticle={handlerHelpArticle}/>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <div className="row">
                    {tab === CONST_TAB_SECTION_KEY.sign_in &&
                        <Login onSwitchTab={onSwitchTab} >
                            <div className="d-flex justify-center flex-wrap gap-10">
                                <LoginSocialGoogle key={'login-social-google'}
                                                   client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                                   isOnlyGetToken={true}
                                                   isOnlyGetCode={true}
                                                   auto_select={false}
                                                   scope={'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'}
                                                   cookie_policy={false}
                                                   onResolve={async ({provider, data}) => {
                                                       dispatch(googleLoginThunk(data.access_token))
                                                   }}
                                                   onReject={(err) => {
                                                       console.log(err)
                                                       toast.error(err);
                                                   }}>
                                    <GoogleLoginButton text={"Google"} key={'login-social-google-btn'}/>
                                </LoginSocialGoogle>
                                <LoginSocialFacebook
                                    fieldsProfile={"id,name,email"}
                                    fields="first_name,last_name,email,name"
                                    scope="public_profile,email"
                                    returnScopes={true}
                                    appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                                    onResolve={(response) => {
                                        console.log(response);
                                        dispatch(facebookLoginThunk(response.data))
                                    }}
                                    onReject={(error) => {
                                        console.log(error);
                                        toast.error(error);
                                    }}
                                >
                                    <FacebookLoginButton text={"Facebook"}/>
                                </LoginSocialFacebook>
                            </div>
                        </Login>
                    }
                    {tab === CONST_TAB_SECTION_KEY.sign_up &&
                        <Registration onSwitchTab={onSwitchTab} />
                    }
                </div>
            </div>
        </section>

    );
};

export default LoginPlatform;