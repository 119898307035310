import React from 'react';

const IconTel =(props) => {
    let {className = '', ...reset} = props;
    const cName = `icon-tel ${className}`;
    return (
        <svg  className={className} {...reset} width="21" height="22" viewBox="0 0 21 22" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.916629 15.7149V18.5899C0.915546 18.8568 0.970217 19.121 1.07714 19.3656C1.18406 19.6101 1.34088 19.8296 1.53756 20.0101C1.73423 20.1905 1.96641 20.3279 2.21924 20.4134C2.47208 20.4989 2.73998 20.5306 3.0058 20.5066C5.95475 20.1862 8.78742 19.1785 11.2762 17.5645C13.5917 16.0932 15.5548 14.13 17.0262 11.8145C18.6458 9.31442 19.6537 6.46797 19.9683 3.50576C19.9922 3.24075 19.9607 2.97366 19.8758 2.72149C19.7909 2.46932 19.6544 2.23759 19.475 2.04107C19.2956 1.84454 19.0772 1.68753 18.8338 1.58001C18.5904 1.4725 18.3273 1.41685 18.0612 1.4166H15.1862C14.7211 1.41202 14.2702 1.57671 13.9176 1.87998C13.565 2.18325 13.3346 2.6044 13.2695 3.06493C13.1482 3.98499 12.9232 4.88838 12.5987 5.75785C12.4698 6.10086 12.4419 6.47364 12.5183 6.83202C12.5947 7.19041 12.7723 7.51937 13.03 7.77993L14.247 8.99701C12.8828 11.3962 10.8963 13.3828 8.49705 14.747L7.27996 13.5299C7.0194 13.2723 6.69044 13.0947 6.33206 13.0183C5.97367 12.9418 5.60089 12.9697 5.25788 13.0987C4.38841 13.4231 3.48503 13.6482 2.56496 13.7695C2.09944 13.8352 1.67429 14.0697 1.37037 14.4284C1.06645 14.7871 0.904968 15.2449 0.916629 15.7149Z"
                stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default IconTel;