import React, {useMemo} from 'react';
import IconTelegram from "../../../../../../assets/Icons/IconTelegram";
import IconViber from "../../../../../../assets/Icons/IconViber";
import IconWA from "../../../../../../assets/Icons/IconWA";
import QRCode from "react-qr-code";
import BotUrl from "../../../../../../assets/images/bot.jpeg";
import IconInfo from "../../../../../../assets/Icons/IconInfo";
import {filterObjectByKeys, unFilterObjectByKeys} from "../../../../../../utils/common";
import {useTranslation} from "react-i18next";

const ICONS_MESSAGE = {
    telegram: IconTelegram,
    viber: IconViber,
    whatsapp: IconWA,
}
const styleIcon = {
    marginRight: '8px'
}
const styleQr = {height: "auto", maxWidth: "55px", width: "55px"}


const MessageChannels = ({
                             available_message_channel_list,
                             full_message_channel_list,
                             onReqAccountAccess,
                             isButtonDisabled,
                             seconds
                         }) => {
    const {t} = useTranslation()

    if (!available_message_channel_list && !full_message_channel_list) return null
    const connected_channel = useMemo(() => {
        if (Object.keys(full_message_channel_list).length && Object.keys(available_message_channel_list)) {
            let keys = Object.keys(available_message_channel_list);
            return filterObjectByKeys(full_message_channel_list, keys)
        }
        return {}
    }, [available_message_channel_list, full_message_channel_list])
    const un_connected_channel = useMemo(() => {
        if (Object.keys(full_message_channel_list).length && Object.keys(available_message_channel_list)) {
            let keys = Object.keys(available_message_channel_list);
            return unFilterObjectByKeys(full_message_channel_list, keys)
        } else if (Object.keys(full_message_channel_list).length) {
            return full_message_channel_list
        }
        return {}
    }, [available_message_channel_list, full_message_channel_list]);
    return (
        <div>
            {!!Object.keys(connected_channel).length && <div className={'connected-channel-wrap form-group'}>
                <label htmlFor="">{t('send_password_title')}: {isButtonDisabled &&
                    <span className={'seconds'}>{seconds}</span>}</label>
                <div
                    className={`connected-channel d-flex message-channel-entry ${isButtonDisabled ? ' disabled' : ''}`}>
                    {Object.keys(connected_channel).map(key => {
                        const isDefault = connected_channel[key].default;
                        const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                        const name = connected_channel[key].name;
                        const isEnabled = connected_channel[key].enabled;
                        return (
                            <div className={`item   ${isDefault ? 'default' : ' '} ${!isEnabled ? 'disabled' : ' '}`}
                                 onClick={() => onReqAccountAccess({send_password: 1, message_channel: key})}
                            >
                                {Icon ? <Icon/> : name ? name : ''}
                            </div>)

                    })}
                </div>
            </div>}
            {!!Object.keys(un_connected_channel).length && <div className={'un-connected-channel-wrap form-group'}>
                <label htmlFor="" className={'d-flex align-items-center bot-info'}>
                    <span>{t("login_via_title")}:</span>
                    <a href={BotUrl} target={'_blank'} className="info d-flex align-items-center"><IconInfo
                        width={'25px'} height={'25px'}/></a>
                    <img src={BotUrl} alt=""/>
                </label>
                <div className={'un-connected-channel-entry d-flex message-channel-entry'}>
                    {Object.keys(un_connected_channel).map(key => {
                        const Icon = ICONS_MESSAGE.hasOwnProperty(key) ? ICONS_MESSAGE[key] : null
                        const name = un_connected_channel[key].name;
                        const link = un_connected_channel[key].link;
                        return (
                            <div className={`item d-flex flex-column`}>
                                <div className="d-flex align-items-center">
                                    {Icon ? <Icon style={styleIcon}/> : name ? name : ''}
                                    <a href={link} className="d-flex" target={'_blank'}>
                                        <QRCode
                                            size={55}
                                            style={styleQr}
                                            value={link}
                                            viewBox={`0 0 55 55 `}
                                        />
                                    </a>
                                </div>


                                {/*<img src={BotUrl} alt=""/>*/}
                            </div>)

                    })}
                </div>
                <spna className="resend-action cursor-pointer"
                      onClick={() => onReqAccountAccess({send_password: 0})}>{t('check_registration_title')}</spna>
            </div>}

        </div>
    );
};

export default MessageChannels;