import React from "react";
import Close from "../picture/icon/Close";
import {useDispatch, useSelector} from "react-redux";
import {onPayRequestInvoiceModal, onSendPayForm} from "../redux/app-reducer";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {payModalRequestInvoiceSelected} from "../redux/selector/app-selector";

export const RequestInvoicePayModal = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const requestInvoice = useSelector(payModalRequestInvoiceSelected);
    const onClose = () => dispatch(onPayRequestInvoiceModal({
        key: 'requestInvoice',
        dt: {isOpen: false, isSuccessful: false, isProvider: false}
    }));
    const onSubmitForm = (text) => {
        dispatch(onSendPayForm(text))
    }
    return <> {requestInvoice.isOpen ?
        <div className="term-use__container modal-container">

            <div className="container">
                <div className="row">
                    {/*term-use__body*/}
                    <div className=" modal-content modal-content-sm">
                        <div className="modal-head">
                            <div className="h4">
                                {t('payDT.title')}
                            </div>
                            <div className="close" onClick={onClose}>
                                <Close isBlack={false}/>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="content">
                                {requestInvoice.isSuccessful ?
                                    <h3 className={'mb-0 text-center'}>    {t('payDT.isSuccessTxt')}</h3> :
                                    <Forms isProvider={requestInvoice.isProvider} onSubmitForm={onSubmitForm} t={t}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : ''}
    </>
}

const Forms = ({isProvider, onSubmitForm, t}) => {
    const formik = useFormik({
        initialValues: {
            userName: '',
            phone: '',
            email: '',
            dateTo: ''
        },
        onSubmit: (values, {resetForm}) => {
            let text = isProvider ? `ФИО - ${values.userName},  Номер телефона - ${values.phone} E-mail - ${values.email} дата до которой планируется использование  - ${values.dateTo}` : `ФИО - ${values.userName},  Номер телефона - ${values.phone} E-mail - ${values.email} `
            onSubmitForm(text);

        },
    });
    return (
        <form className={"form-request-pay"}
              onSubmit={formik.handleSubmit}>
            <div className="field ">
                <label htmlFor="">{t('payDT.form.userName')}</label>
                <input
                    id="userName"
                    name="userName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.userName}
                    required={true}
                />
            </div>
            <div className={"field "}>
                <label htmlFor="">{t('feedback_form.form.phone')}</label>
                <input
                    id="phone"
                    name="phone"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                /></div>
            <div className={"field "}>
                <label htmlFor="">{t('feedback_form.form.email')}</label>
                <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    required={true}
                />
            </div>

            {isProvider &&
                <div className={"field "}>
                    <label htmlFor="">{t('payDT.form.dateTo')}</label>
                    <input
                        id="dateTo"
                        name="dateTo"
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.dateTo}
                        required={true}
                    />
                </div>
            }
            <button type="submit">{t('feedback_form.form.submit')}</button>
        </form>
    )
}