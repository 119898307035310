//utils
function findErrorResponse(response) {

    let error_entry = Array.isArray(response) ?
        response.filter(item => item.hasOwnProperty('type') && ((item.type === 'api_error') ||
            (item.type === "mysql")))
        : response.hasOwnProperty('error_api') && response.error_api.hasOwnProperty('api_error') ?
            response.error_api.api_error :
            response.hasOwnProperty('error_api') && response.error_api.hasOwnProperty('mysql')
                ? response.error_api.mysql
                : null
    ;

    let errors = '';

    if (Array.isArray(error_entry) && !!error_entry.length) {
        error_entry.forEach(e => {

            if (typeof e.error === 'string') {
                errors += `${e.error},`;
            } else {
                for (const key in e.error) {
                    if (Object.prototype.hasOwnProperty.call(e.error, key)) {
                        errors += `${e.error[key]},`;
                    }
                }
            }
        });
    }

    return errors;
}


function transformAuthData(data) {
    return {
        admin_in: data.hasOwnProperty('admin_in') ? data.admin_in : null,
        client: data.client,
        roles: data.hasOwnProperty('roles') ? data.roles : null,
        name: data.hasOwnProperty('name') ? data.name : '',
        email: data.hasOwnProperty('email') ? data.email : '',
        user_id: data.hasOwnProperty('user_id') ? data.user_id : null,
        phone: data.hasOwnProperty('phone') ? data.phone : null,
        firm: data.firm,
        firms: data.hasOwnProperty('firms') ? data.firms : null,
        email_confirmed: data.hasOwnProperty('email_confirmed') ? !!Number(data.email_confirmed) : false,
        phone_confirmed: data.hasOwnProperty('phone_confirmed') ? !!Number(data.phone_confirmed) : false,
        firm_urls: data.hasOwnProperty('firm_urls') ? data.firm_urls : null

    }
}

function transformAuthDataClient(data) {
    let client_data = data.hasOwnProperty('client_data') ? data.client_data : data.hasOwnProperty('user_data') ? data.user_data : null
    return {
        admin_in: data.hasOwnProperty('admin_in') ? data.admin_in : null,
        client: data.client,
        roles: data.hasOwnProperty('roles') ? data.roles : null,
        name: client_data ? client_data.name : '',
        email: data.hasOwnProperty('email') && data.email ? data.email: client_data ? client_data.email : '',
        user_id: data.hasOwnProperty('user_id') ? data.user_id : null,
        phone: data.hasOwnProperty('phone') && data.phone ? data.phone: client_data ? client_data.phone : null,
        firm: data.firm,
        firms: data.hasOwnProperty('firms') ? data.firms : null,
        email_confirmed: data.hasOwnProperty('email_confirmed') ? !!Number(data.email_confirmed) : false,
        phone_confirmed: data.hasOwnProperty('phone_confirmed') ? !!Number(data.phone_confirmed) : false,

    }
}

function rejectTransform(error) {
    try {
        let response = error;
        let error_values = findErrorResponse(response);
        if (error_values) {
            return {error: error_values}
        }
        return Promise.reject(error)
    } catch (e) {
        return Promise.reject(e)
    }
}

export {findErrorResponse, transformAuthData, rejectTransform, transformAuthDataClient}