import React from "react";
import './logo.css'

const Logo = (props) => {
    const {className = '', ...reset} = props;
        const cName = `icon-logo ${className}`;
    return (

        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 354.33 68.03" style={{enableBackground:"new 0 0 354.33 68.03"}} className={cName} {...reset}>

                <g id="Слой_1">
                        <path className="new-logo-st0" d="M46.34,20.78h5.98v24.95h-5.98V20.78L46.34,20.78z M49.56,11.23c1.92,0,3.48,1.56,3.48,3.48
		s-1.56,3.48-3.48,3.48c-1.92,0-3.48-1.56-3.48-3.48S47.64,11.23,49.56,11.23L49.56,11.23z"/>
                        <path className="new-logo-st0" d="M82.15,10.19l-3.38,6.24h-0.8v0.02h-8.42c-1.62,0-2.77,0.79-3.37,1.72c-0.61,0.93-0.67,1.99-0.67,3.48v0.18
		h9.55v6.24h-9.55v17.67h-5.98V25.67h-0.01c0-1.04,0-1.98,0.01-2.84v-2.05h0.04c0.01-0.31,0.02-0.61,0.04-0.89
		c0.21-3.8,1.64-6.68,4.27-8.28c1.98-1.2,4.02-1.42,6.27-1.42H82.15L82.15,10.19z"/>
                        <path className="new-logo-st0" d="M99.88,20.78v15.84c0,2.86-1.17,5.46-3.05,7.34c-1.88,1.88-4.48,3.05-7.34,3.05c-2.86,0-5.46-1.17-7.34-3.05
		c-1.88-1.88-3.05-4.48-3.05-7.34V20.78H85v15.84c0,1.23,0.51,2.36,1.32,3.17c0.82,0.82,1.94,1.32,3.17,1.32
		c1.23,0,2.36-0.51,3.17-1.32c0.82-0.82,1.32-1.94,1.32-3.17V20.78H99.88L99.88,20.78z"/>
                        <g>
                                <path d="M124.32,20.09v0.1h0.06v6.24h-0.06v0.02h-8.42c-3.45,0-4.13,2.8-4.13,5.65v13.29h0.08v0.36h-5.98V35.67h-0.01
			c0-1.04,0-1.98,0.01-2.84c0-2.67-0.32-5.46,0.82-7.94c0.75-1.63,2.36-3.35,4.34-4.04c1.8-0.63,4.27-0.79,6.06-0.83
			C119.18,19.97,121.58,20.09,124.32,20.09L124.32,20.09z"/>
                        </g>
                        <g>
                                <path d="M128.36,47.01V32.69v-1.52V20.04h5.89v1.77c1.36-0.66,2.89-1.03,4.49-1.03c2.86,0,5.46,1.17,7.34,3.05
			c1.88,1.88,3.05,4.48,3.05,7.34v15.84h-5.89V31.17c0-1.23-0.51-2.36-1.32-3.17c-0.82-0.82-1.94-1.32-3.17-1.32
			c-1.23,0-2.36,0.51-3.17,1.32c-0.82,0.82-1.32,1.94-1.32,3.17v1.52v14.32H128.36L128.36,47.01z"/>
                        </g>
                        <g>
                                <polygon points="164.88,47.03 173.52,51.8 173.52,23.93 164.88,19.02 164.88,47.03 "/>
                        </g>
                        <g>
                                <path d="M174.56,24.52v27.86l17.03,9.41V34.21L174.56,24.52z M184.36,35.36c-0.24,0.32-0.75,0.39-1.14,0.16l-3.98-2.32
			c-0.4-0.23-0.53-0.67-0.29-0.99c0.24-0.32,0.75-0.39,1.14-0.16l3.98,2.32C184.46,34.6,184.59,35.04,184.36,35.36z"/>
                        </g>
                        <g>
                                <path d="M193.27,51.86v9.47l15.81-8.66v-8.5L193.27,51.86z M204.25,51.28l-3.67,1.81c-0.36,0.18-0.83,0.05-1.04-0.29
			c-0.21-0.34-0.08-0.75,0.28-0.93l3.67-1.81c0.36-0.18,0.83-0.05,1.04,0.29C204.74,50.68,204.61,51.1,204.25,51.28z"/>
                        </g>
                        <g>
                                <path d="M193.27,33.76v8.63l15.81-7.69v-8.21L193.27,33.76z M204.25,34.41l-3.67,1.81c-0.36,0.18-0.83,0.05-1.04-0.29
			c-0.21-0.34-0.08-0.76,0.28-0.93l3.67-1.81c0.36-0.18,0.83-0.05,1.04,0.29C204.74,33.81,204.61,34.23,204.25,34.41z"/>
                        </g>
                        <g>
                                <polygon points="210.7,52.33 219.2,47.68 219.2,22.29 210.7,26.2 210.7,52.33 		"/>
                        </g>
                        <g>
                                <polygon points="192.33,5 178.89,10.78 192.31,18.41 205.19,12.49 192.33,5 		"/>
                        </g>
                        <g>
                                <polygon
                                    points="192.53,21.02 176.4,11.84 164.88,16.79 191.89,32.16 218.2,20.07 207.8,14.01 192.53,21.02 		"/>
                        </g>
                        <g>
                                <path d="M193.27,51l15.81-7.69v-7.74l-15.81,7.69V51L193.27,51z M199.55,44.51c-0.21-0.34-0.08-0.75,0.28-0.93l3.67-1.81
			c0.36-0.18,0.83-0.05,1.04,0.29c0.21,0.34,0.08,0.75-0.28,0.93l-3.67,1.81C200.22,44.97,199.75,44.84,199.55,44.51L199.55,44.51z"
                                />
                        </g>
                        <g>
                                <path d="M233.36,46.98V19.87h7.35c2.26,0,4.05,0.15,5.38,0.47c1.32,0.31,2.5,0.84,3.53,1.57c1.06,0.73,1.89,1.65,2.47,2.76
			c0.58,1.1,0.87,2.27,0.87,3.5c0,2.74-1.03,4.91-3.08,6.52c-2.6,2.04-7.75,2.64-10.98,2.37v9.92H233.36L233.36,46.98z
			 M246.27,28.89c0.04-1.82-1.33-3.69-5.11-3.69h-2.4v7.11h2.72C244.96,32.31,246.23,30.63,246.27,28.89L246.27,28.89z"/>
                        </g>
                        <g>
                                <path d="M264.4,25.2h-1.83v7.11h2.14c3.48,0,4.75-1.69,4.79-3.42C269.55,27.07,268.18,25.2,264.4,25.2L264.4,25.2z M256.66,46.98
			V19.87H264c2.35,0,4.17,0.15,5.47,0.44c1.3,0.29,2.44,0.78,3.44,1.47c1.06,0.72,1.89,1.61,2.47,2.68
			c2.49,4.54,0.28,10.38-4.62,12.45l7.36,10.07h-6.26l-9.29-11.93v11.93H256.66L256.66,46.98z"/>
                        </g>
                        <g>
                                <path d="M286.58,33.88c0,2.28,0.7,4.2,2.11,5.74c1.41,1.54,3.14,2.31,5.2,2.31c2.07,0,3.81-0.77,5.21-2.3
			c1.4-1.54,2.1-3.46,2.1-5.75c0-2.31-0.7-4.23-2.1-5.77c-1.4-1.54-3.14-2.31-5.21-2.31c-2.06,0-3.79,0.77-5.2,2.31
			C287.28,29.64,286.58,31.57,286.58,33.88L286.58,33.88z M279.78,33.88c0-1.95,0.35-3.77,1.06-5.46c0.7-1.7,1.74-3.22,3.12-4.56
			c1.3-1.28,2.81-2.26,4.52-2.94c1.71-0.69,3.52-1.03,5.4-1.03c1.88,0,3.68,0.35,5.41,1.03c1.73,0.69,3.26,1.68,4.59,2.97
			c1.35,1.29,2.37,2.78,3.06,4.48c0.7,1.69,1.04,3.53,1.04,5.5c0,1.64-0.24,3.19-0.72,4.64c-0.49,1.45-1.2,2.77-2.16,3.97
			c-1.35,1.7-3,3.02-4.97,3.96c-1.97,0.95-4.05,1.42-6.25,1.42c-1.87,0-3.66-0.35-5.37-1.03c-1.71-0.69-3.23-1.68-4.55-2.97
			c-1.37-1.34-2.42-2.85-3.12-4.54C280.13,37.63,279.78,35.82,279.78,33.88L279.78,33.88z"/>
                        </g>
                </g>
                <g id="Слой_2" className="new-logo-st1">
                </g>
        </svg>
    )
}

export default Logo;