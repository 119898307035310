import React from 'react';

const Lt = () => {
    return (
        <div className="info-pages-main-content">
            <h2 className="privacy-title">Viešojo pasiūlymo sutartis</h2><p className="privacy-text">Bendrosios nuostatos</p>

            <ul>
                <li>1.1. Ši oferta yra oficiali "FOP Kotok I.M." pasiūlymas, toliau - "Pardavėjas", sudaryti nuotolinio prekių pardavimo sutartį, t. y. per internetinę parduotuvę, toliau - "Sutartis", ir skelbia Viešąją pasiūlymą (pasirinkimą) oficialiame Pardavėjo interneto svetainėje <a href="https://ifurn.pro" target="_blank" title="ifurn.pro" className="privacy-link">"https://ifurn.pro"</a>, <a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro" className="privacy-link">"https://newgo.ifurn.pro"</a>, <a href="https://crm.ifurn.pro" target="_blank" title="crm.ifurn.pro" className="privacy-link">"https://crm.ifurn.pro"</a> (toliau - "Internetinė svetainė").
                </li>
                <li>1.2. Pirkėjo pilno ir be sąlygų pasiūlymo Pardavėjui (akcepto) elektroninės prekių pardavimo sutarties sudarymo momentas laikomas Pirkėjo užsakymo apmokėjimo faktas pagal šios Sutarties sąlygas, terminus ir kainas, nurodytas Pardavėjo internetinėje svetainėje.
                </li>
                <li>2.1. Šiame pasiūlyme, jei kontekstas nereikalauja kitaip, žemiau pateikti terminai turi šiuos reikšmes:
                </li>
                <ul>
                    <li>* "prekė" - baldų, pusgaminių, priedų, komponentų ir priedų sudedamosios dalys;</li>
                    <li>* "Internetinė parduotuvė" - pagal Ukrainos elektroninės prekybos įstatymą priemonė prekei, darbui ar paslaugai pateikti ar įgyvendinti elektroninio sandorio būdu;</li>
                    <li>* "Pardavėjas" - įmonė, kuri prekiauja prekėmis, pateiktomis internetinėje svetainėje;</li>
                    <li>* "Pirkėjas" - fizinis asmuo, sudaręs su Pardavėju Sutartį pagal toliau nurodytas sąlygas;</li>
                    <li>* "Užsakymas" - atskirų prekių pasirinkimas iš Pirkėjo užsakymo ir apmokėjimo metu nurodytų prekių sąrašo.</li>
                </ul>
                <p className="privacy-text">Sutarties objektas</p>
                <li>3.1. Pardavėjas įsipareigoja perduoti Pirkėjui prekę, o Pirkėjas įsipareigoja sumokėti ir priimti prekę pagal šios Sutarties sąlygas.
                </li>
                <ul>Ši Sutartis reglamentuoja prekių pirkimą ir pardavimą internetinėje parduotuvėje, įskaitant:
                    <li> - Pirkėjo savanorišką pasirinkimą prekių internetinėje parduotuvėje;</li>
                    <li> - Pirkėjo savarankišką užsakymo pateikimą internetinėje parduotuvėje;</li>
                    <li> - Pirkėjo užsakymo apmokėjimą, pateiktą internetinėje parduotuvėje;</li>
                    <li> - užsakymo apdorojimą ir pristatymą Pirkėjui pagal šios Sutarties sąlygas.
                    </li>
                </ul>
                <p className="privacy-text">Užsakymo pateikimo tvarka</p>
                <li>4.1. Pirkėjas turi teisę užsakyti bet kurią prekę, pateiktą Internetinėje svetainėje ir turinčią sandėlyje.
                </li>
                <li>4.2. Kiekviena pozicija gali būti pateikta užsakyme bet kuriuo kiekiu.</li>
                <li>4.3. Neatvykus prekei į sandėlį, įmonės vadovas privalo pranešti Pirkėjui (telefonu ar el. paštu).
                </li>
                <li>4.4. Prekės nebuvimo atveju Pirkėjas gali pakeisti ją analogiškos modelio preke, atsisakyti šios prekės arba atšaukti užsakymą.
                </li>
                <p className="privacy-text">Apmokėjimo tvarka</p><p className="privacy-text">Banko mokėjimas.</p>
                <li>5.1. Mokėjimas atliekamas į Pardavėjo banko sąskaitą. Prekių pristatymą Pirkėjui atlieka Pardavėjas po sąskaitos apmokėjimo būdu, nurodytu pristatymo sąlygose 6.1, 6.2 skyriuje.
                </li>
                <li>5.2. Neatsiskaitus pinigų, internetinė parduotuvė turi teisę atšaukti užsakymą.
                </li>
                <p className="privacy-text">Užsakymo pristatymo sąlygos</p>
                <li>6.1. Internetinės parduotuvės įsigytos prekės pristatomos pačiu pasirinkimu iš nurodytos sąskaitos adreso, per vežimo bendrovę ar kitu P

                    irkėjo su Pardavėju suderintu būdu.
                </li>
                <li>6.2. Kartu su užsakymu Pirkėjui suteikiami dokumentai, atitinkantys Ukrainos teisės aktus.
                </li>
                <p className="privacy-text">Šalių teisės ir pareigos:</p>
                <li>7.1. Pardavėjas turi teisę:</li>
                <ul>
                    <li>- vienašališkai sustabdyti paslaugų teikimą pagal šią sutartį, jei Pirkėjas pažeidžia šios sutarties sąlygas.
                    </li>
                </ul>
                <li>7.2. Pirkėjas privalo:</li>
                <ul>
                    <li>- laiku apmokėti ir gauti užsakymą pagal šios sutarties sąlygas.
                    </li>
                </ul>
                <li>7.3. Pirkėjas turi teisę:</li>
                <ul>
                    <li>- pateikti užsakymą internetinėje parduotuvėje;</li>
                    <li>- sudaryti elektroninę sutartį;</li>
                    <li>- reikalauti, kad Pardavėjas vykdytų šios sutarties sąlygas.
                    </li>
                </ul>
                <p className="privacy-text">Šalių atsakomybė:</p>
                <li>8.1. Šalys atsako už šios sutarties sąlygų nesilaikymą ar netinkamą laikymąsi pagal šią sutartį ir galiojančius Ukrainos teisės aktus.
                </li>
                <li>8.3. Pirkėjas, naudodamas jam suteiktą prieigą prie interneto, savarankiškai atsako už žalą, padarytą jo veiksmų (netgi jei jo prisijungimo metu buvo kitas asmuo) asmenims, jų turtui, juridinėms asmenims, valstybei ar moralės principams.
                </li>
                <li>8.4. Nepriklausomai nuo nepėdomų aplinkybių, šalys yra atleidžiamos nuo šios sutarties sąlygų vykdymo. Nepėdomų aplinkybių požiūriu šioje sutartyje suprantami išskirtiniai, neprognozuojami įvykiai, kurie neleidžia ar objektyviai trukdo šios sutarties vykdymui, įvykiai, kurių šalys negalėjo numatyti ir išvengti protingais būdais.
                </li>
                <li>8.5. Šalys deda visas pastangas išspręsti bet kokius nesutarimus išskirtinai per derybas.
                </li>
                <p className="privacy-text">Kiti sąlygos</p>
                <li>9.1. Internetinė parduotuvė pasilieka teisę vienašališkai keisti šią sutartį, paskelbdama ją iš anksto savo svetainėje <a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro" className="privacy-link">"https://newgo.ifurn.pro"</a>.
                </li>
                <li>9.2. Internetinė parduotuvė yra skirta organizuoti prekių pardavimą nuotoliniu būdu per internetą.
                </li>
                <li>9.3. Pirkėjas atsako už pateiktos užsakymo informacijos tikslumą. Pateikdamas akceptą (užsakymo pateikimą ir vėlesnį prekės apmokėjimą), Pirkėjas suteikia pardavėjui savo bešališką sutikimą rinkti, tvarkyti, saugoti ir naudoti jo asmens duomenis, suprantant tai kaip "Asmens duomenų apsaugos įstatymo" supratimą.
                </li>
                <li>9.4. Pirkimo užsakymo apmokėjimas internetinėje parduotuvėje reiškia Pirkėjo visišką sutikimą su pirkimo-pardavimo sutarties (viešojo pasiūlymo) sąlygomis.
                </li>
                <li>9.5. Elektroninės sutarties tarp šalių faktine data yra sutarimo sąlygų priėmimo data, pagal Ukrainos elektroninės prekybos įstatymo 11 straipsnį.
                </li>
                <li>9.6. Interneto parduotuvės išteklių naudojimas prekių išankstiniam peržiūrėjimui ir užsakymui Pirkėjui yra nemokamas.
                </li>
                <li>9.7. Pirkėjo pateikta informacija yra konfidenciali. Internetinė parduotuvė naudoja Pirkėjo informaciją tik užsakymo tvarkymui, pranešimų siuntimui Pirkėjui, prekių pristatymui, apskaitai ir kt.
                </li>
                <p className="privacy-text">Grąžinimo tvarka tinkamoms prekėms</p>
                <li>10.1. Prekių grąžinimas internetinėje parduotuvėje atliekamas pagal galiojančius Ukrainos teisės aktus.
                </li>
                <p className="privacy-text">Sutarties galiojimo laikotarpis</p>
                <li>11.1. Elektroninė sutartis laikoma sudaryta nuo momento, kai asmuo, siųstantis pasiūlymą sudaryti tokį susitarimą, gauna atsakymą dėl šio pasiūlymo priėmimo, pagal Ukrainos elektroninės prekybos įstatymo 11 straipsnio šeštąją dalį.
                </li>
                <li>11.2. Iki sutarties galiojimo pabaigos šią sutartį galima nutraukti abipusiškai susitarus šalims iki prekės faktinio pristatymo momento grąžinant sumokėtus pinigus.
                </li>
                <p className="privacy-text">Atkreipiame dėmesį, kad internetinė parduotuvė „SPD Kotok I.M.“ pagal Ukrainos teisę turi teisę, savo oficialioje svetainėje <a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro" className="privacy-link">"https://newgo.ifurn.pro"</a>, teikti internetinės platformos naudojimo teisę fiziniams ir juridiniams asmenims prekių pardavimui.
                </p>

            </ul>
        </div>
    );
};

export default Lt;