
export const PAGES_HASH_IDS = {
    privacy: 'privacy',
    offerta: 'offerta',
    securityPolicy: 'security_policy',
    refundTerms: 'refund_terms',
    priceList: 'price_list',
    deleteUser: 'delete_user'

}

export const TAB_HASH_IDS = {
    home: '',
    furniture: 'furniture',
    provider: 'provider',
    orderService: 'order_service',
    login: 'login',
    cabinet: 'cabinet',
    news: 'news',
    technical_works:'technical-works',
    ...PAGES_HASH_IDS
}

