import React from 'react';
import FieldPhoneMask from "../../../../ui/FieldPhoneMask";
import IconEdit from "../../../../../assets/Icons/IconEdit";
import {useTranslation} from "react-i18next";
import {useFormikContextProviderLogin} from "../Login";
import Button from "../../../../ui/Button";
import useAuthLookup from "../../../../../Hook/useAuthLookup";
import MessageChannels from "./Bots/MessageChannels";
import AuthAccountPassword from "./AuthAccountPassword";
import MasterPassword from "./MasterPassword";
import SubmitPrivacy from "./SubmitPrivacy";
import {toast} from "react-toastify";

const LoginPhone = ({onHandlerEdit, onReqAccountAccess, isButtonDisabled, seconds}) => {
    const {t} = useTranslation();
    const {
        isAuthFind,
        isSendShortPassword, available_message_channel_list, full_message_channel_list, connected_message_channel_list,
    } = useAuthLookup();

    const {values, handleChange, validateForm} = useFormikContextProviderLogin();
    if (values.isRadioLoginEmail) return null
    const onChangeTelHandler = (result) => {
        handleChange({
            target: {name: 'phone', value: result},
        });
    }
    const handleValidate = () => {
        validateForm().then((errors) => {
            console.log(errors)
            // Handle the validation errors
            if (!Object.keys(errors).includes('phone')) {
                onReqAccountAccess({send_password: 0})

            } else if (Object.keys(errors).includes('phone')) {
                let error_msg = `${errors['phone'] || ''}`
                toast.error(`${error_msg}`)
            }
        });
    };

    return (
        <div>
            <div className={'d-flex align-items-center'}>
                <div className={`${!values.isEditEmailOrPhone ? 'disabled' : ''} flex-auto`}>
                    <FieldPhoneMask onChange={onChangeTelHandler} label={t('feedback_form.form.phone')}
                                    value={values.phone} autoComplete={"off"}
                                    disabled={!values.isEditEmailOrPhone}
                    />
                </div>
                {!values.isEditEmailOrPhone &&
                    <IconEdit style={{marginLeft: '8px', cursor: "pointer"}} onClick={onHandlerEdit}/>}
            </div>
            {values.isEditEmailOrPhone &&
                <Button type={'button'} style={{margin: '0 auto'}}
                        onClick={handleValidate}>{t("feedback_form.form.submit")}
                </Button>}

            {!values.isEditEmailOrPhone && isAuthFind && <React.Fragment>
                <MessageChannels onReqAccountAccess={onReqAccountAccess}
                                 seconds={seconds}
                                 isButtonDisabled={isButtonDisabled}
                                 available_message_channel_list={connected_message_channel_list}
                                 full_message_channel_list={full_message_channel_list}/>
            </React.Fragment>}
            {!values.isEditEmailOrPhone && isAuthFind && isSendShortPassword && <AuthAccountPassword/>
            }
            {
                (!values.isEditEmailOrPhone && isAuthFind && !isSendShortPassword) &&
                <>
                    <MasterPassword/>
                    <SubmitPrivacy/>
                </>
            }
        </div>
    );
};

export default LoginPhone;