import React from 'react';

const En = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Refund Terms:</h1>
                <ul>
                    <li>1. Refunds are provided within 14 days</li>
                    <li>2. Refunds are made only for the first ordered services. For example: if you ordered a
                        subscription service, after which you received a refund and ordered a subscription from us a
                        second time, then you will not be able to receive a refund for the second order.
                    </li>
                    <li>3. Refunds are made minus the commission of payment systems.</li>
                    <li>4. When paying with a VISA and MasterCard card, the money is returned to the same card with
                        which the payment was made. After we return the payment, the money appears on the card within 3
                        banking days. Please note that this payment does not appear on card statements, but simply
                        increases the amount on the card. This type of return is available only within 30 days after
                        payment.
                    </li>
                    <li>5. The money is returned to the same details from which the payment for services was made.</li>
                    <li>6. Refunds are made within 10 days from the date of application.</li>
                    <li>7. When returning, all bonuses and discounts are deducted.</li>
                </ul>
            </div>
        </div>
    );
};

export default En;