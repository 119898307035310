import React from 'react';

const Ro = () => {
    return (
            <div className="info-page-inner-content info-pages-main-content">
                <h2 className="privacy-title"> Contract de oferta publica</h2><p
                className="privacy-text">Dispoziții generale</p>
                <ul>
                    <li>1.1. Această ofertă este o ofertă oficială a FOP Kotok I.M., denumit în continuare „Vânzător”,
                        pentru a încheia un Acord de vânzare de bunuri de la distanță, adică printr-un magazin online,
                        denumit în continuare „Acordul”, și locuri o Ofertă publică (o propunere) pe site-ul oficial al
                        Vânzătorului<a href="https://ifurn.pro" target="_blank" title="ifurn.pro"
                                       className="privacy-link">«https://ifurn.pro», </a><a
                            href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                            className="privacy-link">«https://newgo.ifurn.pro», </a><a href="https://crm.ifurn.pro"
                                                                                       target="_blank"
                                                                                       title="crm.ifurn.pro"
                                                                                       className="privacy-link">«https://crm.ifurn.pro»</a>(denumit
                        în continuare „site-ul”).
                    </li>
                    <li> 1.2. Momentul acceptării integrale și necondiționate de către Cumpărător a ofertei (acceptarea)
                        a Vânzătorului de a încheia un contract electronic de vânzare a mărfurilor este faptul că
                        Cumpărătorul achită comanda în condițiile prezentului Contract, la timp și la prețuri. indicat
                        pe site-ul Vânzătorului.
                    </li>
                    <li> 2.1. În această ofertă, dacă contextul nu cere altfel, următorii termeni au următoarele
                        semnificații:
                    </li>
                    <ul>
                        <li>* „bunuri” - componente de mobilier, semifabricate, accesorii, componente și articole
                            însoțitoare;
                        </li>
                        <li>* „Magazin online” - în conformitate cu Legea Ucrainei „cu privire la comerțul electronic”,
                            un mijloc de prezentare sau vânzare a unui produs, lucrare sau serviciu prin efectuarea unei
                            tranzacții electronice.
                        </li>
                        <li>* „Vânzător” - o companie care vinde bunuri prezentate pe site-ul de Internet.</li>
                        <li>* „Cumparator” - o persoana care a incheiat un Acord cu Vanzatorul in conditiile stabilite
                            mai jos.
                        </li>
                        <li>* „Comandă” - selecția articolelor individuale din lista de bunuri specificată de Cumpărător
                            la plasarea unei comenzi și efectuarea plății.
                        </li>
                    </ul>
                    <p className="privacy-text">Obiectul acordului</p>
                    <li>3.1. Vânzătorul se obligă să transfere Bunurile în proprietatea Cumpărătorului, iar Cumpărătorul
                        se obligă să plătească și să accepte Bunurile în conformitate cu termenii acestui Acord.
                    </li>
                    <ul>Acest acord reglementează cumpărarea și vânzarea de bunuri în magazinul online, inclusiv:
                        <li> - alegerea voluntară de către Cumpărător a bunurilor din magazinul online;</li>
                        <li> - înregistrarea independentă de către Cumpărător a comenzii în magazinul online;</li>
                        <li> - plata de catre Cumparator a comenzii plasate in magazinul online;</li>
                        <li> - procesarea și livrarea comenzii către Cumpărător în proprietate în condițiile prezentului
                            Acord.
                        </li>
                    </ul>
                    <p className="privacy-text">Cum se plasează o comandă</p>
                    <li>4.1. Cumpărătorul are dreptul de a plasa o comandă pentru orice produs prezentat pe Site-ul
                        magazinului online și disponibil.
                    </li>
                    <li>4.2. Fiecare articol poate fi prezentat în comandă în orice cantitate.</li>
                    <li>4.3. In lipsa bunurilor in stoc, Managerul societatii este obligat sa anunte Cumparatorul
                        (telefonic sau prin e-mail).
                    </li>
                    <li>4.4. In lipsa unui produs, Cumparatorul are dreptul de a-l inlocui cu un produs de model
                        similar, de a refuza acest produs, de a anula comanda.
                    </li>
                    <p className="privacy-text">Ordin de plata&nbsp;</p><p className="privacy-text">Plata
                    bancara.&nbsp;</p>
                    <li>5.1. Plata se face in contul Vanzatorului. Livrarea mărfurilor de către Vânzător către
                        Cumpărător se efectuează după plata facturii prin metoda specificată în termenii de livrare,
                        clauzele 6.1, 6.2.
                    </li>
                    <li>5.2. În cazul în care fondurile nu sunt primite, magazinul online își rezervă dreptul de a anula
                        comanda.
                    </li>
                    <p className="privacy-text">Termenii de livrare a comenzii&nbsp;</p>
                    <li>6.1. Livrarea mărfurilor achiziționate în magazinul online se efectuează prin autolivrare de la
                        adresa indicată pe factură, de către o firmă de transport sau în alt mod convenit de Cumpărător
                        cu Vânzătorul.
                    </li>
                    <li>6.2. Odata cu comanda, Cumparatorului i se pun la dispozitie documente in conformitate cu
                        legislatia Ucrainei.
                    </li>
                    <p className="privacy-text">Drepturile și obligațiile părților:&nbsp;</p>
                    <li>7.1. Vânzătorul are dreptul:</li>
                    <ul>
                        <li>- suspenda unilateral prestarea serviciilor în temeiul prezentului acord în cazul încălcării
                            de către Cumpărător a termenilor acestui acord.
                        </li>
                    </ul>
                    <li>7.2. Cumpărătorul este obligat:</li>
                    <ul>
                        <li>- plata la timp si primirea comenzii in conditiile prezentului contract.</li>
                    </ul>
                    <li>7.3. Cumpărătorul are dreptul:</li>
                    <ul>
                        <li>- plasați o comandă în magazinul online;</li>
                        <li>- întocmește un contract electronic;</li>
                        <li>- solicitați Vânzătorului să îndeplinească termenii acestui Acord.</li>
                    </ul>
                    <p className="privacy-text">Responsabilitatea părților&nbsp;</p>
                    <li>8.1. Părțile sunt responsabile pentru neîndeplinirea sau îndeplinirea necorespunzătoare a
                        termenilor acestui acord în modul prescris de prezentul acord și de legislația actuală a
                        Ucrainei.
                    </li>
                    <li>8.2. Vânzătorul nu este responsabil pentru:</li>
                    <ul>
                        <li>- aspectul Bunurilor schimbat de producator;</li>
                        <li>- pentru o ușoară discrepanță în gama de culori a produsului, care poate diferi de produsul
                            original numai datorită reproducerii diferite a culorilor monitoarelor computerelor
                            personale ale modelelor individuale;
                        </li>
                        <li>- за содержание и правдивость информации, предоставляемой Покупателем при оформлении
                            заказа;
                        </li>
                        <li>- pentru întârzieri și întreruperi în prestarea Serviciilor (procesarea comenzilor și
                            livrarea mărfurilor) care apar din motive independente de voința sa;
                        </li>
                        <li>- pentru actiuni ilegale ilegale desfasurate de Cumparator folosind acest acces la
                            Internet;
                        </li>
                        <li>- pentru transferul de către Cumpărător a identificatorilor de rețea - IP, adresa MAC, login
                            și parola către terți;
                        </li>
                    </ul>
                    <li>8.3. Cumpărătorul, folosind accesul la Internet care i-a fost pus la dispoziție, este singurul
                        responsabil pentru prejudiciul cauzat prin acțiunile sale (personal, chiar dacă o altă persoană
                        se afla sub autentificarea sa) persoanelor sau proprietății acestora, persoanelor juridice,
                        statului sau principiilor morale ale moralitate.
                    </li>
                    <li>8.4. În cazul unor circumstanțe de forță majoră, părțile sunt eliberate de termenii prezentului
                        contract. În sensul prezentului acord, prin forță majoră se înțelege evenimente de natură
                        extraordinară, neprevăzută, care exclud sau interferează în mod obiectiv cu executarea
                        prezentului acord, a căror apariție Părțile nu au putut prevedea și preveni prin mijloace
                        rezonabile.
                    </li>
                    <li>8.5. Părțile vor depune toate eforturile pentru a rezolva orice diferențe exclusiv prin
                        negocieri.
                    </li>
                    <p className="privacy-text">Alte conditii&nbsp;</p>
                    <li>9.1. Magazinul online își rezervă dreptul de a modifica unilateral acest acord, sub rezerva
                        publicării sale prealabile pe site.<a href="https://newgo.ifurn.pro" target="_blank"
                                                              title="newgo.ifurn.pro"
                                                              className="privacy-link">«https://newgo.ifurn.pro» </a>
                    </li>
                    <li>9.2. Magazinul online a fost creat pentru a organiza o metodă la distanță de vânzare a
                        mărfurilor prin Internet.
                    </li>
                    <li>9.3. Cumpărătorul este responsabil pentru acuratețea informațiilor specificate la plasarea unei
                        comenzi. În același timp, la efectuarea unei acceptări (plasarea unei comenzi și plata
                        ulterioară a mărfurilor), Cumpărătorul oferă Vânzătorului consimțământul său necondiționat
                        pentru colectarea, prelucrarea, stocarea, utilizarea datelor sale cu caracter personal, în
                        înțelegerea Legea Ucrainei „Cu privire la protecția datelor cu caracter personal”.
                    </li>
                    <li>9.4. Plata de către Cumpărător a comenzii plasate în magazinul online înseamnă acordul deplin al
                        Cumpărătorului cu termenii contractului de vânzare (oferta publică)
                    </li>
                    <li>9.5. Data efectivă a acordului electronic între părți este data acceptării condițiilor, în
                        conformitate cu art. 11 din Legea Ucrainei „Cu privire la comerțul electronic”
                    </li>
                    <li>9.6. Utilizarea resursei magazinului online pentru a previzualiza bunurile, precum și pentru a
                        plasa o comandă pentru Cumpărător este gratuită.
                    </li>
                    <li>9.7. Informațiile furnizate de Cumpărător sunt confidențiale. Magazinul online folosește
                        informații despre Cumpărător exclusiv în scopul procesării comenzii, trimiterii de notificări
                        către Cumpărător, livrării de mărfuri, efectuării decontărilor reciproce etc.
                    </li>
                    <p className="privacy-text">Procedura de returnare a mărfurilor de bună calitate</p>
                    <li>10.1. Returnarea mărfurilor către magazinul online se efectuează în conformitate cu legislația
                        în vigoare a Ucrainei.
                    </li>
                    <p className="privacy-text">Durata contractului</p>
                    <li>11.1. Un acord electronic este considerat încheiat din momentul în care persoana care a trimis
                        oferta de a încheia un astfel de acord primește un răspuns cu privire la acceptarea acestei
                        oferte în modul prevăzut de partea a șasea a articolului 11 din Legea Ucrainei „Cu privire la
                        comerțul electronic”.
                    </li>
                    <li>11.2. Înainte de data expirării, prezentul Contract poate fi reziliat de comun acord al părților
                        până la livrarea efectivă a bunurilor, prin returnarea fondurilor.
                    </li>
                    <p className="privacy-text">Vă rugăm să rețineți că magazinul online „SPD Kotok I.M.” pe site-ul
                        oficial<a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro"
                                  className="privacy-link">«https://newgo.ifurn.pro», </a>are dreptul, în conformitate
                        cu legislația Ucrainei, să acorde dreptul de utilizare a platformei de internet FLP și
                        persoanelor juridice pentru vânzarea de bunuri.</p></ul>
            </div>
    );
};

export default Ro;