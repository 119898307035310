import React from "react";
function Close(props) {
    return (
        <svg
            className={"close-modal"}
            width={28}
            height={28}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M21 7L7 21M7 7l14 14"
                stroke={props.isBlack ? '#000' : '#fff'}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Close;