import {instances} from "./api";
import {findErrorResponse, rejectTransform} from "./utils";

const AuthSocialAPI = {
    getNetworkLoginHash() {
        return instances.get('authi/network-login-hash')
            .then(res => {
                let response = res.data;
                let error = findErrorResponse(response);
                return !error ? response : {error: error}
            }).catch(error => rejectTransform(error))
    },
    /**
     * networkLogin авторизируем или регистрируем или подключаем сеть в ЛК пользователя.
     * data - зашифрованные данные в формате json - ниже распишу
     * network - fb/google
     * firms[] - массив фирм
     *
     * Данные в json следующие:
     * {
     *   "id" : "id пользователя в соцсети",
     *   "name": "Имя",
     *   "email" : "email",
     *   "phone":"phone"
     * }
     * @returns  {object}  - state.state = ok - все ок или ошибки
     */
    networkLogin(form_data) {
        return instances.post('authi/network-login', form_data)
            .then(res => {
                let response = res.data;
                let error = findErrorResponse(response);
                return !error ? response : {error: error}
            }).catch(error => rejectTransform(error))
    },
    /** список подключенных соцсетей авторизованного пользователя */
    getNetworkLogin() {
        return instances.get('authi/network-login')
            .then(res => {
                let response = res.data;
                let error = findErrorResponse(response);
                return !error ? response : {error: error}
            }).catch(error => rejectTransform(error))
    },
    /**deleteNetworkLogin удалить привязку соцсети авторизованного пользователя
     * form-data:
     * network = fb/google
     * id = id из GET /api/authi/network-login.
     */
    deleteNetworkLogin(api_data) {
        let uri = `authi/network-login`
        return instances.delete(uri, {
            params: api_data
        })
            .then(res => {
                let response = res.data;
                let error = findErrorResponse(response);
                return !error ? response : {error: error}
            }).catch(error => rejectTransform(error))
    }
}

export default AuthSocialAPI