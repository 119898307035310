import React from 'react';

const Ro = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Politica de securitate a transferului detaliilor cardului de
                plată</h1><p>La plata unei comenzi cu un card bancar, procesarea plății (inclusiv introducerea numărului
                cardului) are loc pe o pagină securizată a sistemului de procesare. Aceasta înseamnă că datele
                dumneavoastră confidențiale (detalii card, date de înregistrare etc.) nu vin la noi, prelucrarea lor
                este complet protejată, iar nimeni nu poate obține datele personale și bancare ale clientului.</p><p>La
                lucrul cu datele cardului se folosește standardul de securitate a informațiilor dezvoltat de sistemele
                internaționale de plată Visa și MasterCard - Payment Card Industry Data Security Standard (PCI DSS),
                care asigură prelucrarea în siguranță a detaliilor Cardului Bancar al Deținătorului. Tehnologia aplicată
                de transfer de date garantează securitatea tranzacțiilor cu carduri bancare prin utilizarea
                protocoalelor Secure Sockets Layer (SSL).</p></div>
        </div>
    );
};

export default Ro;