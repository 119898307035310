import React from 'react';

const De = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content">
                <h2 className="privacy-title">Öffentliches Angebot</h2>
                <p className="privacy-text">Allgemeine Bestimmungen</p>
                <ul>
                    <li>1.1. Dieses Angebot ist ein offizielles Angebot der "FOP Kotok I.M.", im Folgenden als "Verkäufer" bezeichnet, einen Fernabsatzvertrag über den Kauf von Waren abzuschließen, d. h. über den Online-Shop, im Folgenden als "Vertrag", und es wird ein öffentliches Angebot (Angebot) auf der offiziellen Website des Verkäufers platziert <a href="https://ifurn.pro" target="_blank" title="ifurn.pro" className="privacy-link">"https://ifurn.pro", </a><a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro" className="privacy-link">"https://newgo.ifurn.pro", </a><a href="https://crm.ifurn.pro" target="_blank" title="crm.ifurn.pro" className="privacy-link">"https://crm.ifurn.pro"</a> (nachfolgend "Website").
                    </li>
                    <li>1.2. Der Zeitpunkt des vollständigen und bedingungslosen Akzeptierens des Angebots durch den Käufer (Akzeptanz) zum Abschluss eines elektronischen Kaufvertrags gilt als die Tatsache der Bezahlung der Bestellung durch den Käufer zu den Bedingungen dieses Vertrags, innerhalb der auf der Website des Verkäufers angegebenen Fristen und Preise.
                    </li>
                    <li>2.1. In diesem Angebot haben die unten aufgeführten Begriffe, wenn der Kontext nichts anderes erfordert, die folgenden Bedeutungen:
                    </li>
                    <ul>
                        <li>* "Ware" - Bestandteile von Möbeln, Halbfabrikate, Zubehör, Komponenten und Begleitartikel;</li>
                        <li>* "Online-Shop" - gemäß dem Gesetz der Ukraine über den elektronischen Handel ein Mittel zur Präsentation oder Verwirklichung von Waren, Arbeiten oder Dienstleistungen durch elektronische Transaktionen.
                        </li>
                        <li>* "Verkäufer" - das Unternehmen, das die auf der Website präsentierten Waren verkauft.
                        </li>
                        <li>* "Käufer" - eine natürliche Person, die mit dem Verkäufer einen Vertrag zu den unten aufgeführten Bedingungen abgeschlossen hat.
                        </li>
                        <li>* "Bestellung" - Auswahl einzelner Positionen aus der Liste der vom Käufer bei der Bestellung angegebenen Waren und Zahlungstransaktionen.
                        </li>
                    </ul>
                    <p className="privacy-text">Vertragsgegenstand</p>
                    <li>3.1. Der Verkäufer verpflichtet sich, dem Käufer das Eigentum an der Ware zu übertragen, und der Käufer verpflichtet sich, die Ware zu den Bedingungen dieses Vertrags zu bezahlen und zu übernehmen.
                    </li>
                    <ul>Der vorliegende Vertrag regelt den Kauf und Verkauf von Waren im Online-Shop, einschließlich:
                        <li> - die freiwillige Auswahl von Waren durch den Käufer im Online-Shop;</li>
                        <li> - die eigenständige Auftragserteilung durch den Käufer im Online-Shop;
                        </li>
                        <li> - die Bezahlung der Bestellung durch den Käufer im Online-Shop;</li>
                        <li> - Bearbeitung und Lieferung der Bestellung an den Käufer im Rahmen dieses Vertrags.
                        </li>
                    </ul>
                    <p className="privacy-text">Bestellvorgang</p>
                    <li>4.1. Der Käufer hat das Recht, eine Bestellung für jede im Online-Shop präsentierte und verfügbare Ware aufzugeben.
                    </li>
                    <li>4.2. Jede Position kann in beliebiger Menge in der Bestellung enthalten sein.</li>
                    <li>4.3. Bei Nichtverfügbarkeit des Produkts im Lager ist der Manager des Unternehmens verpflichtet, den Käufer zu informieren (per Telefon oder per E-Mail).
                    </li>
                    <li>4.4. Bei Nichtverfügbarkeit des Produkts kann der Käufer es durch ein Produkt derselben Modellreihe ersetzen, sich weigern, dieses Produkt zu kaufen oder die Bestellung stornieren.
                    </li>
                    <p className="privacy-text">Zahlungsablauf</p>
                    <p className="privacy-text">Banküberweisung.</p>
                    <li>5.1. Die Zahlung erfolgt auf das Bankkonto des Verkäufers. Die Lieferung der Ware durch den Verkäufer an den Käufer erfolgt nach Bezahlung der Rechnung auf die im Lieferabschnitt angegebenen Weise 6.1, 6.2.
                    </li>
                    <li>5.2. Im Falle des Nichteingangs der Geldmittel behält sich der Online-Shop das Recht vor, die Bestellung zu stornieren.
                    </li>
                    <p className="privacy-text">Lieferbedingungen</p>
                    <li>6.1. Die Lieferung der im Online-Shop erworbenen Waren erfolgt durch Selbstabholung von der im Rechnung angegebenen Adresse, durch einen Spediteur oder auf andere Weise, die zwischen dem Käufer und dem Verkäufer vereinbart ist.
                    </li>
                    <li>6.2. Zusammen mit der Bestellung erhält der Käufer die gemäß den Gesetzen der Ukraine erforderlichen Dokumente.
                    </li>
                    <p className="privacy-text">Rechte und Pflichten der Parteien</p>
                    <li>7.1. Der Verkäufer hat das Recht:
                    </li>
                    <ul>
                        <li>- einseitig die Erbringung von Dienstleistungen gemäß diesem Vertrag einzustellen, wenn der Käufer die Bedingungen dieses Vertrags verletzt.
                        </li>
                    </ul>
                    <li>7.2. Der Käufer ist verpflichtet:
                    </li>
                    <ul>
                        <li>- rechtzeitig zu bezahlen und die Bestellung gemäß den Bedingungen dieses Vertrags zu erhalten.
                        </li>
                    </ul>
                    <li>7.3. Der Käufer hat das Recht:
                    </li>
                    <ul>
                        <li>- eine Bestellung im Online-Shop aufzugeben;</li>
                        <li>- einen elektronischen Vertrag abzuschließen;</li>
                        <li>- vom Verkäufer die Erfüllung der Bedingungen dieses Vertrags zu verlangen.
                        </li>
                    </ul>
                    <p className="privacy-text">Verantwortlichkeiten der Parteien</p>
                    <li>8.1. Die Parteien haften für die Nichterfüllung oder die unsachgemäße Erfüllung der Bedingungen dieses Vertrags gemäß diesem Vertrag und dem geltenden Gesetz der Ukraine.
                    </li>
                    <li>8.2. Der Verkäufer haftet nicht für:
                    </li>
                    <ul>
                        <li>- Änderungen am äußeren Erscheinungsbild des Produkts durch den Hersteller;</li>
                        <li>- geringfügige Farbabweichungen des Produkts, die sich möglicherweise vom Originalprodukt unterscheiden, ausschließlich aufgrund unterschiedlicher Farbwiedergabe von Computermonitoren bestimmter Modelle;
                        </li>
                        <li>- den Inhalt und die Wahrhaftigkeit der Informationen, die der Käufer bei der Bestellung bereitstellt;
                        </li>
                        <li>- Verzögerungen und Ausfälle bei der Bereitstellung von Dienstleistungen (Auftragsabwicklung und Warenlieferung), die auf Gründen beruhen, die außerhalb seiner Kontrolle liegen;
                        </li>
                        <li>- rechtswidrige Handlungen, die der Käufer mit Hilfe dieses Internetzugangs durchführt;
                        </li>
                        <li>- die Weitergabe der Netzidentifikatoren des Käufers - IP, MAC-Adresse, Benutzername und Passwort an Dritte;
                        </li>
                    </ul>
                    <li>8.3. Der Käufer trägt bei Nutzung des ihm gewährten Internetzugangs selbst die Verantwortung für Schäden, die durch seine Handlungen (persönlich, auch wenn sich eine andere Person unter seinem Login befand) an Personen oder deren Eigentum, juristischen Personen, dem Staat oder moralischen Prinzipien entstehen.
                    </li>
                    <li>8.4. Im Falle von Umständen höherer Gewalt sind die Parteien von der Erfüllung der Bedingungen dieses Vertrags befreit. Für die Zwecke dieses Vertrags gelten als Umstände höherer Gewalt außergewöhnliche, unvorhersehbare Ereignisse, die die Erfüllung dieses Vertrags ausschließen oder objektiv behindern, deren Eintritt die Parteien nicht vorhersehen und vernünftigerweise verhindern konnten.
                    </li>
                    <li>8.5. Die Parteien setzen alles daran, Meinungsverschiedenheiten ausschließlich durch Verhandlungen zu lösen.
                    </li>
                    <p className="privacy-text">Weitere Bedingungen</p>
                    <li>9.1. Der Online-Shop behält sich das Recht vor, einseitig Änderungen an diesem Vertrag vorzunehmen, vorausgesetzt, sie werden zuvor auf der Website <a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro" className="privacy-link">"https://newgo.ifurn.pro" </a> veröffentlicht.
                    </li>
                    <li>9.2. Der Online-Shop wurde gegründet, um den Fernabsatz von Waren über das Internet zu organisieren.
                    </li>
                    <li>9.3. Der Käufer ist für die Richtigkeit der bei der Bestellung angegebenen Informationen verantwortlich. Bei Abschluss des Akzeptanzes (Bestellung und anschließende Zahlung des Produkts) erteilt der Käufer dem Verkäufer seine bedingungslose Zustimmung zur Sammlung, Verarbeitung, Speicherung und Nutzung seiner persönlichen Daten im Sinne des Gesetzes der Ukraine "Über den Schutz personenbezogener Daten".
                    </li>
                    <li>9.4. Die Zahlung durch den Käufer für die im Online-Shop aufgegebene Bestellung bedeutet die uneingeschränkte Zustimmung des Käufers zu den Bedingungen des Kaufvertrags (öffentliche Angebote).
                    </li>
                    <li>9.5. Das tatsächliche Datum der elektronischen Vereinbarung zwischen den Parteien ist das Datum der Annahme der Bedingungen gemäß Artikel 11 des ukrainischen Gesetzes "Über den elektronischen Handel".
                    </li>
                    <li>9.6. Die Nutzung der Ressourcen des Online-Shops zum Vorab-Anzeigen von Waren sowie zum Aufgeben einer Bestellung ist für den Käufer kostenlos.
                    </li>
                    <li>9.7. Die vom Käufer bereitgestellten Informationen sind vertraulich. Der Online-Shop verwendet die Informationen über den Käufer ausschließlich für die Abwicklung der Bestellung, das Senden von Benachrichtigungen an den Käufer, die Lieferung der Ware, die Abwicklung von Abrechnungen usw.
                    </li>
                    <p className="privacy-text">Verfahren zur Rückgabe von Waren mit angemessener Qualität</p>
                    <li>10.1. Die Rückgabe von Waren an den Online-Shop erfolgt gemäß den geltenden Gesetzen der Ukraine.
                    </li>
                    <p className="privacy-text">Laufzeit des Vertrags</p>
                    <li>11.1. Der elektronische Vertrag gilt als abgeschlossen, sobald die Person, die das Angebot zur Unterzeichnung eines solchen Vertrags unterbreitet hat, eine Antwort auf die Annahme dieses Angebots in der in Artikel 11 Absatz 6 des Gesetzes der Ukraine "Über den elektronischen Handel" festgelegten Reihenfolge erhalten hat.
                    </li>
                    <li>11.2. Vor Ablauf der Vertragslaufzeit kann dieser Vertrag im gegenseitigen Einvernehmen der Parteien bis zum Zeitpunkt der tatsächlichen Lieferung der Ware durch Rückgabe der Geldmittel gekündigt werden.
                    </li>
                    <p className="privacy-text">Bitte beachten Sie, dass der Online-Shop "SPD Kotok I.M." auf der offiziellen Website <a href="https://newgo.ifurn.pro" target="_blank" title="newgo.ifurn.pro" className="privacy-link">"https://newgo.ifurn.pro"</a> gemäß den Gesetzen der Ukraine das Recht hat, das Nutzungsrecht der Internetplattform für Einzelunternehmer und juristische Personen zur Verfügung zu stellen, um Waren zu verkaufen.</p>
                </ul>
            </div>
        </div>

    );
};

export default De;