import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {File} from "../../picture/icon/File";
import React from "react";
import {VideoWrap} from "../VideoWrap";
import Visa from '../../picture/image/visa-logo.png'
import MasterCard from '../../picture/image/mastercard-logo.png'
import {onPayRequestInvoiceModal} from "../../redux/app-reducer";

export const FurnitureProviders = () => {
    const dispatch = useDispatch();
    const locale = useSelector(state => state.appPage.locale);
    const {t} = useTranslation();
    return <>
        <div className="container">
            <div className="row">
                <div className={"info-block mb-2"}>
                    <VideoWrap videoType={'furniture'}/>
                    <p><a className={'don-pdf'}
                          href={window.location.origin + `/pdf/OffertaMeb_${locale}-converted.pdf`}
                          download={true}> <File/> <span>{t('serviceProviderFurniture.title')}pdf</span> </a></p>
                    <p style={{marginTop:'0px'}}><a
                        style={{marginTop:'0px'}}
                        href="https://newgo.ifurn.pro/registration/NTAyMTQx/" target={'_blank'}
                          className=" btn-register">
                        {t('callForRegistration')}</a></p>
                    {locale === 'ru' || locale === 'ua' &&
                            <div className="btn-pay" onClick={() => {
                                dispatch(onPayRequestInvoiceModal({
                                    key: 'requestInvoice',
                                    dt: {isOpen: true, isProvider: true}
                                }))
                            }
                            }>
                                <span>{t('payDT.title')}</span>
                                <img src={Visa} alt="" width={35}/>
                                <img src={MasterCard} alt="" width={35}/>
                            </div>
                      }

                    <h4 className={' mt-2'}>{t('serviceProviderFurniture.title')}</h4>
                    <div className="font-italic mb-2">{t('serviceProviderFurniture.subtitle')}</div>
                    <strong>{t('serviceProviderFurniture.section1.title')}</strong>
                    <ul>
                        <li>
                            <span>{t('serviceProviderFurniture.section1.list.li1.title')}</span>
                            <ul>
                                <li>{t('serviceProviderFurniture.section1.list.li1.items.0')}</li>

                            </ul>
                        </li>
                    </ul>
                    <VideoWrap videoType={'box'} isBox={true}/>
                    <ul>
                        <li>
                            <span>{t('serviceProviderFurniture.section2.title')}</span>
                            <ul>
                                <li>
                                    {t('serviceProviderFurniture.section2.list.li1')}
                                </li>
                                <li>{t('serviceProviderFurniture.section2.list.li2')}</li>
                                <li>{t('serviceProviderFurniture.section2.list.li3')}</li>
                                <li>{t('serviceProviderFurniture.section2.list.li4')}</li>
                                <li>{t('serviceProviderFurniture.section2.list.li5')}</li>
                            </ul>
                        </li>
                    </ul>

                    <br/>


                    <br/>
                    <strong>{t('serviceProviderFurniture.section3.title')}</strong>
                    <ul>
                        <li>
                            {t('serviceProviderFurniture.section3.list.li1')}
                        </li>
                        <li>{t('serviceProviderFurniture.section3.list.li2')}</li>
                        <li>{t('serviceProviderFurniture.section3.list.li3')}</li>
                        <li>{t('serviceProviderFurniture.section3.list.li4')}</li>
                    </ul>

                    <br/>
                    <strong>{t('serviceProviderFurniture.section4.title')}</strong>
                    <ul>
                        <li>
                            {t('serviceProviderFurniture.section4.list.li1')}
                        </li>
                        <li>{t('serviceProviderFurniture.section4.list.li2')}</li>
                        {t('serviceProviderFurniture.section4.list.li3') !== '' &&
                            <li> {t('serviceProviderFurniture.section4.list.li3')}</li>}
                        <li>{t('serviceProviderFurniture.section4.list.li4')}</li>
                        <li>
                            <span>{t('serviceProviderFurniture.section4.list.li5.title')}</span>
                            <ul>
                                <li>{t('serviceProviderFurniture.section4.list.li5.items.0')}</li>
                                <li>{t('serviceProviderFurniture.section4.list.li5.items.1')}</li>
                                <li>{t('serviceProviderFurniture.section4.list.li5.items.2')}</li>
                                <li>{t('serviceProviderFurniture.section4.list.li5.items.3')}</li>
                                <li>{t('serviceProviderFurniture.section4.list.li5.items.4')}</li>
                                <li>{t('serviceProviderFurniture.section4.list.li5.items.5')}</li>
                            </ul>
                        </li>
                        <li>
                            <span>{t('serviceProviderFurniture.section4.list.li6.title')}</span>
                            <ul>
                                <li>{t('serviceProviderFurniture.section4.list.li6.items.0')}</li>
                                <li>{t('serviceProviderFurniture.section4.list.li6.items.1')}</li>
                                <li>{t('serviceProviderFurniture.section4.list.li6.items.2')}</li>
                                <li>{t('serviceProviderFurniture.section4.list.li6.items.3')}</li>
                                <li>{t('serviceProviderFurniture.section4.list.li6.items.4')}</li>
                                <li>{t('serviceProviderFurniture.section4.list.li6.items.5')}</li>
                                <li><span>{t('serviceProviderFurniture.section4.list.li6.items.6.title')}</span>
                                    <ul>
                                        <li>{t('serviceProviderFurniture.section4.list.li6.items.6.items.0')}</li>
                                        <li>{t('serviceProviderFurniture.section4.list.li6.items.6.items.1')}</li>
                                        <li>{t('serviceProviderFurniture.section4.list.li6.items.6.items.2')}</li>
                                        <li>{t('serviceProviderFurniture.section4.list.li6.items.6.items.3')}</li>
                                    </ul>
                                </li>

                            </ul>

                        </li>
                        <li>{t('serviceProviderFurniture.section4.list.li7')}</li>
                        <li>
                            <span>{t('serviceProviderFurniture.section4.list.li8.title')}</span>
                            <ul>
                                <li>{t('serviceProviderFurniture.section4.list.li8.items.0')}</li>
                            </ul>
                        </li>
                        <li>{t('serviceProviderFurniture.section4.list.li9')}</li>
                    </ul>

                    <br/>
                    <p className={'font-italic'}><strong>{t('serviceProviderFurniture.desc')}</strong></p>





                </div>
            </div>
        </div>

    </>
}