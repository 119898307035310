import Ua from "./Ua";
import Ru from "./Ru";
import React from 'react';
import {useSelector} from "react-redux";
import Ro from "./Ro";
import Pl from "./Pl";
import He from "./He";
import En from "./En";
import De from "./De";
import Et from "./Et";
import Lt from "./Lt";
import Lv from "./Lv";
import Sk from "./Sk";

const RENDER_PAGES = {
    "ua": Ua,
    "ru": Ru,
    "ro": Ro,
    "pl": Pl,
    "he": He,
    "en": En,
    "de": De,
    "sk": Sk,
    "et": Et,
    "lt": Lt,
    "lv": Lv


}

const DeleteUser = () => {
    const locale = useSelector(state => state.appPage.locale)
    if(!locale || !RENDER_PAGES.hasOwnProperty(locale)) {
        let Page_en= RENDER_PAGES['en'];
        return <Page_en/>
    }
    const Page = RENDER_PAGES[locale]
    return <Page/>
};

export default DeleteUser;