export const languages = [

    {
        code: 'ua',
        name: 'Український',
        country_code: 'ua'
    },
    {
        code: 'ru',
        name: 'Русский',
        country_code: 'ru'
    },
    {
        code: 'ro',
        name: 'Румынский',
        country_code: 'ro'
    },{
        code: 'pl',
        name: 'Польский',
        country_code: 'pl'
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'gb'
    },
    {
        code: 'he',
        name: 'Иврит',
        country_code: 'he'
    } ,
    {
        code: 'de',
        name: 'Немецкий',
        country_code: 'de'
    },
    {
        code: 'sk',
        name: 'Словацкий ',
        country_code: 'sk'
    } ,
    {
        code: 'lv',
        name: 'Латишский',
        country_code: 'lv'
    } ,
    {
        code: 'lt',
        name: 'Литовский',
        country_code: 'lt'
    },
    {
        code: 'et',
        name: 'Эстонский',
        country_code: 'et'
    }
];



export const langCode = ['ua', 'en', 'ru', 'ro', 'pl', 'he', 'de', 'sk', 'et', 'lt', 'lv'];