import CryptoJS from 'crypto-js';

export const utf8ToB64 = (str) => {
    return btoa(unescape(encodeURIComponent(str)));
}

export function isEmail(value) {
    // Check if the value is a string
    if (typeof value !== 'string') {
        return false;
    }

    // Basic email pattern check (you can enhance this pattern based on your needs)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value);
}

export const createFormData = (data) => {
    let formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
        if (value || value === '' || value === 0) {
            formData.append(key, value)
        }
    }
    return (Object.keys(formData).length) ? false : formData
}

export const createFormDataArrayObj = (data) => {
    let formData = new FormData();
    for (let item = 0; item < data.length; item++) {
        for (const [key, value] of Object.entries(data[item])) {
            if (value || value === '') {
                formData.append(key, value)
            }
        }
    }
    return (Object.keys(formData).length) ? false : formData
}


export function filterObjectByKeys(obj, keysToKeep) {
    return Object.keys(obj).reduce((acc, key) => {
        if (keysToKeep.includes(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
}

export function unFilterObjectByKeys(obj, keysToKeep) {
    return Object.keys(obj).reduce((acc, key) => {
        if (!keysToKeep.includes(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
}

export const encryptData = ({hash, plainText}) => {
    // const key = CryptoJS.enc.Utf8.parse(hash);
    // const iv = 'abcdef9876541720';
    // const key = CryptoJS.enc.Base64.parse(hash); // length = 17 bytes
//key is now e8b7b40e031300000000da247441226a5d, length=34 (hex encoded)
    const iv = CryptoJS.enc.Base64.parse('abcdef9876541720'); // length = 16 bytes

    const encrypted = CryptoJS.AES.encrypt(plainText, hash, {
        iv: CryptoJS.enc.Hex.parse('abcdef9876541720'),
        mode: CryptoJS.mode.CTR,
    });

    return encrypted.toString()
};
