import React from 'react';

const Et = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content"><h1>Raha tagastamise tingimused:</h1>
                <ul>
                    <li>1. Raha tagastamine toimub 14 päeva jooksul</li>
                    <li>2. Raha tagastatakse ainult esmakordselt tellitud teenuste eest. Näiteks, kui tellisite tellimuse,
                        saite raha tagasi ja tellisite meilt teise tellimuse, siis te ei saa teise tellimuse eest raha tagasi.
                    </li>
                    <li>3. Rahast tagastatakse maksesüsteemi teenustasu maha arvestamisega.</li>
                    <li>4. VISA ja MasterCard kaardimaksete korral kantakse raha tagasi samale kaardile, millelt makse tehti.
                        Pärast makse tagastamist ilmub raha kaardile 3 pangapäeva jooksul. Pange tähele, et seda makset ei
                        kuvata kaardiväljavõtetes, vaid kaardile ilmub lihtsalt suurem summa. Sellist tagasimakse tüüpi saab
                        teha ainult 30 päeva jooksul pärast makset.
                    </li>
                    <li>5. Raha tagastatakse samadele andmetele, mille alusel teenuse eest makse tehti.</li>
                    <li>6. Makse tagastamine toimub 10 päeva jooksul pärast taotluse esitamist.</li>
                    <li>7. Tagasimaksete korral arvatakse maha kõik boonused ja allahindlused.</li>
                </ul>
            </div>
        </div>

    );
};

export default Et;