import React, {useState} from 'react';
import Button from "../../../../ui/Button";
import {useTranslation} from "react-i18next";
import {useFormikContextProviderLogin} from "../Login";

const SubmitPrivacy = () => {
    const [isPrivacy, setIsPrivacy] = useState(true);
    const {t} = useTranslation();
    const {submitForm} = useFormikContextProviderLogin();

    return (
        <>
            <div className="d-flex justify-center form-group">
                <Button type={'button'} className={!isPrivacy ? 'disabled' : ''} onClick={submitForm}>
                    <> {t('login_page.login')}</>
                </Button>

            </div>
            <div className="privacy-checkbox filed-checkbox">
                <input type="checkbox" id={'privacy'}
                       checked={isPrivacy}
                       onChange={(event) => setIsPrivacy(event.target.checked)}/>
                <label htmlFor="privacy">
                    {t('login_page.privacy')}
                </label>
            </div>
        </>
    );
};

export default SubmitPrivacy;