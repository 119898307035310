import React from 'react';

const IconYoutube = (props) => {
    let {className = '', ...reset} = props;
    const cName = `icon-tg ${className}`;
    return (
        <svg className={cName} {...reset} width="37" height="26" viewBox="0 0 37 26" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M36.2377 4.06834C35.8111 2.47755 34.5615 1.22362 32.9767 0.795253C30.0813 0 18.4996 0 18.4996 0C18.4996 0 6.91828 0 4.02286 0.764939C2.46859 1.19302 1.18843 2.47784 0.761891 4.06834C0 6.97398 0 13 0 13C0 13 0 19.0563 0.761891 21.9317C1.18871 23.5222 2.43811 24.7764 4.02315 25.2047C6.94876 26 18.4999 26 18.4999 26C18.4999 26 30.0813 26 32.9767 25.2351C34.5617 24.807 35.8111 23.5528 36.238 21.9623C36.9998 19.0563 36.9998 13.0306 36.9998 13.0306C36.9998 13.0306 37.0303 6.97398 36.2377 4.06834Z"
                fill="white"/>
            <path d="M15 19L25 13.5L15 8V19Z" fill="#050038"/>
        </svg>

    );
};

export default IconYoutube;