import React from 'react';

const Ro = () => {
    return (
        <div className="  info-pages-main-content"><h4>Lista de preturi pentru servici:</h4><p>Prețul este
            format în funcție de nevoile clientului.</p><p>Se iau în considerare numărul de locuri de muncă, numărul
            de
            comenzi pe lună, numărul de echipamente utilizate.</p><p>Serviciul este construit ca un constructor
            universal, ușor adaptabil la nevoile tale.</p><p>Contactați-ne în modul cel mai convenabil pentru dvs.
            și
            vom formula o ofertă comercială.</p></div>

    );
};

export default Ro;