import React from 'react';

const Sk = () => {
    return (
        <div className="info-pages-main-content">
            <div className="info-page-inner-content price-list"><h4>Cenník služieb:</h4><p>Cena sa formuje podľa potrieb klienta.</p><p> Zohľadňuje počet pracovných miest, počet objednávok za mesiac, množstvo používaného vybavenia.</p><p> Služba je navrhnutá ako univerzálna stavebnica, ľahko prispôsobiteľná vašim potrebám.</p><p> Kontaktujte nás spôsobom, ktorý vám vyhovuje, a vytvoríme obchodnú ponuku.</p>
            </div>
        </div>

    );
};

export default Sk;