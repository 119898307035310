import React from 'react';
import Card from "../../ui/Card";
import IconOpen from "../../../assets/Icons/IconOpen";

const AccessSite = ({entry=null, t, firm_urls}) => {
    if(!entry || !Array.isArray(entry)) return  null
    return (
        <Card label={t("access_site_title")}>
            <ul className={'list-none'}>
                {entry.filter(site => firm_urls.hasOwnProperty(site.key)).map(site=>{

                    return <li><a className={'d-flex align-items-center'} href={firm_urls[site.key]} target={'_blank'} >{site.label}
                        <IconOpen width={"20px"} height={"20px"} style={{marginLeft: '8px'}}/></a></li>
                })}
            </ul>
        </Card>
    );
};

export default AccessSite;